import React from 'react';
import styled from 'styled-components';

import Icon from '../../../components/atoms/Icon';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import { useSyncContext } from '../../connections/sync-context/SyncContext';

const HoldingScreenContainer = styled(Column)`
  padding-right: 138px;
  padding-top: 40px;
`;

const FeatureList = styled.ul<{ bgImage: string }>`
  list-style: none;
  padding-left: 0;
  padding: 30px 24px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 608px;
  height: 380px;

  gap: 16px;
  background: url(${({ bgImage }) => bgImage}) no-repeat;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
`;

const ProductHelpContainer = styled(Column)`
  background: ${({ theme }) => theme.palette.backgroundGrey};
  max-width: 512px;
  padding: 32px;
  padding-bottom: 0;
  position: relative;
  & ul {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  & ul a {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary.main};
  }
  & > img {
    position: absolute;
    top: 50%;
    right: 0;
  }
`;

const OnboardingHoldingScreen = () => {
  const { jobs } = useSyncContext();
  const isSync = jobs.some(job => job.status !== 'Completed');
  return (
    <HoldingScreenContainer width="100%">
      <Row hAlign="space-between" spacing="xxlarge" width="100%">
        <Column spacing="xxlarge">
          <Column spacing="large">
            <Typography size="h3" weight="regular">
              {isSync ? 'Your data is importing...' : 'Your account is ready!'}
            </Typography>
            <Row width="450px">
              {isSync ? (
                <Typography size="medium" weight="regular">
                  Your onboarding experience will be tailored to your sector and the benefits that you have selected.
                  Stay tuned...
                </Typography>
              ) : (
                <Typography size="medium" weight="regular">
                  Your onboarding experience will be tailored to the benefits that you have selected. <b>Let’s Go!</b>
                </Typography>
              )}
            </Row>
          </Column>

          <FeatureList bgImage={'/images/onboarding/scalexp_features_big.png'}>
            <Typography size="h6" weight="regular">
              ScaleXP Features{' '}
            </Typography>
            <Column spacing="small">
              <li>
                <Row spacing="medium" vAlign="center">
                  <Icon name="check_circle" size={5} color={'#8C7BF2'} />
                  <Typography size="medium" weight="regular">
                    Automate Revenue Recognition (Deferred Revenue)
                  </Typography>
                </Row>
              </li>
              <li>
                <Row spacing="medium" vAlign="center">
                  <Icon name="check_circle" size={5} color={'#8C7BF2'} />
                  <Typography size="medium" weight="regular">
                    Automate Prepayments and Expenses
                  </Typography>
                </Row>
              </li>
              <li>
                <Row spacing="medium" vAlign="center">
                  <Icon name="check_circle" size={5} color={'#8C7BF2'} />
                  <Typography size="medium" weight="regular">
                    Calculate SaaS or subscription metrics
                  </Typography>
                </Row>
              </li>
              <li>
                <Row spacing="medium" vAlign="center">
                  <Icon name="check_circle" size={5} color={'#8C7BF2'} />
                  <Typography size="medium" weight="regular">
                    Consolidate across companies or currencies
                  </Typography>
                </Row>
              </li>
              <li>
                <Row spacing="medium" vAlign="center">
                  <Icon name="check_circle" size={5} color={'#8C7BF2'} />
                  <Typography size="medium" weight="regular">
                    Track Budgets and Forecasts
                  </Typography>
                </Row>
              </li>
            </Column>
          </FeatureList>
        </Column>
        <ProductHelpContainer>
          <Typography size="h6" weight="regular">
            Additional Product Help
          </Typography>
          <ul>
            <li>
              <Typography size="medium" weight="regular">
                If you get stuck or just have a quick question, drop us an email - Support@ScaleXP.com - or book a quick
                zoom call using this{' '}
                <a href="https://meetings.hubspot.com/marjorie-jackson" rel="noreferrer" target="_blank">
                  link.
                </a>
              </Typography>
            </li>
            <li>
              <Typography size="medium" weight="regular">
                Access our resource centre for help articles, guided workflows and videos. Click the ‘help’ button in
                the top navigation bar.
              </Typography>
            </li>
          </ul>
          <Row width="100%" hAlign="center" vAlign="center" spacing="large">
            <img src="/images/onboarding/help.png" alt="onboarding product help" width={102} />
            <img src="/images/onboarding/product_help_2.svg" alt="onboarding product help" />
          </Row>
        </ProductHelpContainer>
      </Row>
      <Row width="100%" hAlign="flex-end">
        <TextButton
          disabled={isSync}
          width="188px"
          variant="primary"
          size="large"
          onClick={() => window.location.replace('/')}
        >
          Let’s Go
        </TextButton>
      </Row>
    </HoldingScreenContainer>
  );
};

export default OnboardingHoldingScreen;
