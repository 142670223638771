import { Store } from 'scalexp/store/state/types';

import { DeferredSeriesRemainingByCustomerVS } from './types';

export const selectDeferredSeriesRemainingByCustomer = (
  state: Store,
  organisationId: number,
): DeferredSeriesRemainingByCustomerVS | undefined => {
  return state.deferredSeriesRemainingByCustomer[organisationId];
};
