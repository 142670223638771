import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectColumnsPresets } from './selectors';
import { DynamicColumnsPreset, FixedColumnsPreset } from './types';

export const ORGANISATIONS_COLUMNS_PRESETS_LOAD = '[columnsPresets] LOAD';
export const ORGANISATIONS_COLUMNS_PRESETS_CREATE = '[columnsPresets] CREATE';
export const ORGANISATIONS_COLUMNS_PRESETS_UPDATE = '[columnsPresets] UPDATE';
export const ORGANISATIONS_COLUMNS_PRESETS_DELETE = '[columnsPresets] DELETE';

type ColumnsPresetsResponse = {
  column_collections: DynamicColumnsPreset[];
};
type CreateColumnsPresetResponse = DynamicColumnsPreset;
type UpdateColumnsPresetResponse = DynamicColumnsPreset;
type DeleteColumnsPresetResponse = DynamicColumnsPreset;

type ColumnsPresetsActionLoad = PromiseAction<
  typeof ORGANISATIONS_COLUMNS_PRESETS_LOAD,
  DynamicColumnsPreset[],
  { organisationId: number }
>;
type ColumnsPresetsActionCreate = PromiseAction<
  typeof ORGANISATIONS_COLUMNS_PRESETS_CREATE,
  CreateColumnsPresetResponse,
  { organisationId: number; columnPreset: DynamicColumnsPreset }
>;
type ColumnsPresetsActionUpdate = PromiseAction<
  typeof ORGANISATIONS_COLUMNS_PRESETS_UPDATE,
  UpdateColumnsPresetResponse,
  { organisationId: number; columnPreset: DynamicColumnsPreset }
>;
type ColumnsPresetsActionDelete = PromiseAction<
  typeof ORGANISATIONS_COLUMNS_PRESETS_DELETE,
  DeleteColumnsPresetResponse,
  { organisationId: number; columnsPreset: DynamicColumnsPreset }
>;
export type ColumnsPresetsActions =
  | ColumnsPresetsActionLoad
  | ColumnsPresetsActionCreate
  | ColumnsPresetsActionUpdate
  | ColumnsPresetsActionDelete;

export const loadColumnsPrestsAction = (organisationId: number): ThunkAction<ColumnsPresetsActions> => async (
  dispatch: ThunkDispatch<ColumnsPresetsActions>,
  getState,
) => {
  const state = getState();
  const columnsPresets = selectColumnsPresets(state, organisationId);

  if (columnsPresets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_COLUMNS_PRESETS_LOAD,
        () =>
          http<DynamicColumnsPreset[], ColumnsPresetsResponse>(
            `/api/v1/organisations/${organisationId}/columns_presets`,
            {},
            response => response.column_collections,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export type CreateColumnsPresetActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      columns_preset: DynamicColumnsPreset | FixedColumnsPreset;
    };

export type UpdateColumnsPresetActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      columns_preset: DynamicColumnsPreset;
    };

export type DeleteColumnsPresetActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      columns_preset: DynamicColumnsPreset;
    };

export const createColumnsPresetAction = (
  organisationId: number,
  columnsPreset: DynamicColumnsPreset | FixedColumnsPreset,
): ThunkAction<ColumnsPresetsActionCreate, Promise<CreateColumnsPresetActionResult>> => async (
  dispatch: ThunkDispatch<ColumnsPresetsActions>,
) => {
  try {
    const fetchedColumnsPreset = await dispatch(
      promiseAction(
        ORGANISATIONS_COLUMNS_PRESETS_CREATE,
        async () =>
          http<DynamicColumnsPreset, CreateColumnsPresetResponse>(
            `/api/v1/organisations/${organisationId}/columns_presets/`,
            {
              method: 'POST',
              body: {
                ...columnsPreset,
                id: undefined,
              },
            },
          ),
        {
          organisationId,
          columnsPreset,
        },
      ),
    );
    return {
      success: true,
      columns_preset: fetchedColumnsPreset,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const updateColumnsPresetAction = (
  organisationId: number,
  columnsPreset: DynamicColumnsPreset,
): ThunkAction<ColumnsPresetsActionUpdate, Promise<UpdateColumnsPresetActionResult>> => async (
  dispatch: ThunkDispatch<ColumnsPresetsActions>,
  getState,
) => {
  const state = getState();
  const columnsPresets = selectColumnsPresets(state, organisationId);

  if (columnsPresets?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const fetchedColumnsPreset = await dispatch(
      promiseAction(
        ORGANISATIONS_COLUMNS_PRESETS_UPDATE,
        async () =>
          http<DynamicColumnsPreset, CreateColumnsPresetResponse>(
            `/api/v1/organisations/${organisationId}/columns_presets/${columnsPreset.id}`,
            {
              method: 'PUT',
              body: columnsPreset,
            },
          ),
        {
          organisationId,
          columnsPreset,
        },
      ),
    );
    return {
      success: true,
      columns_preset: fetchedColumnsPreset,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const deleteColumnsPresetAction = (
  organisationId: number,
  columnsPreset: DynamicColumnsPreset,
): ThunkAction<ColumnsPresetsActionDelete, Promise<DeleteColumnsPresetActionResult>> => async (
  dispatch: ThunkDispatch<ColumnsPresetsActions>,
  getState,
) => {
  const state = getState();
  const columnsPresets = selectColumnsPresets(state, organisationId);

  if (columnsPresets?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const deletedColumnsPreset = await dispatch(
      promiseAction(
        ORGANISATIONS_COLUMNS_PRESETS_DELETE,
        async () =>
          http<DynamicColumnsPreset, DeleteColumnsPresetResponse>(
            `/api/v1/organisations/${organisationId}/columns_presets/${columnsPreset.id}`,
            {
              method: 'DELETE',
              body: {
                name: columnsPreset.name,
              },
            },
          ),
        {
          organisationId,
          columnsPreset,
        },
      ),
    );
    return {
      success: true,
      columns_preset: deletedColumnsPreset,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
