import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { NEW_DATE_FORMAT } from 'scalexp/components/molecules/DateSelect/dateSupport';
import getFromAndToDatesFromFocal from 'scalexp/utils/getFromAndToDatesFromFocal';
import { StringParam, useQueryParam } from 'use-query-params';

const useDateSelection = (queryParamKey: string = 'date') => {
  return useQueryParam(queryParamKey, StringParam);
};

export const useDateSelectionDate = (queryParamKey: string = 'date'): dayjs.Dayjs => {
  const [value] = useQueryParam<string | null | undefined>(queryParamKey, StringParam);
  return useMemo(() => dayjs(value ?? undefined), [value]);
};

type DateRange = {
  isLoading: boolean;
  fromDate: string;
  toDate: string;
  setFromDate: (date: string) => void;
  setToDate: (date: string) => void;
};

export const useDateRange = (diffMonths: number = 3, fromParam: string = 'from', toParam: string = 'to'): DateRange => {
  const [fromDate, setFromDate] = useDateSelection(fromParam);
  const [toDate, setToDate] = useDateSelection(toParam);
  const [focusedMonth] = useDateSelection('focusedMonth');

  useEffect(() => {
    if (!fromDate || !toDate) {
      if (focusedMonth) {
        const [newFromDate, newToDate] = getFromAndToDatesFromFocal(
          focusedMonth,
          dayjs().subtract(diffMonths, 'months').format(NEW_DATE_FORMAT),
          dayjs().format(NEW_DATE_FORMAT),
        );
        setFromDate(newFromDate);
        setToDate(newToDate);
      } else {
        setFromDate(dayjs().subtract(diffMonths, 'months').format(NEW_DATE_FORMAT));
        setToDate(dayjs().format(NEW_DATE_FORMAT));
      }
    }
  }, [fromDate, toDate, focusedMonth]);

  return useMemo(
    () => ({
      isLoading: !fromDate || !toDate,
      fromDate: fromDate || '',
      toDate: toDate || '',
      setFromDate,
      setToDate,
    }),
    [fromDate, toDate],
  );
};

export default useDateSelection;
