import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'scalexp/store/state/types';

import {
  createChartAction,
  createDashboardAction,
  deleteChartAction,
  deleteDashboardAction,
  loadDashboardsAction,
  updateChartAction,
  updateDashboardAction,
  updateDashboardDetailsAction,
} from './actions';
import { selectDashboards } from './selectors';
import {
  CreateChartActionResult,
  CreateDashboardActionResult,
  Dashboard,
  DashboardChartCard,
  DashboardsVS,
  DashboardVS,
  DeleteChartActionResult,
  DeleteDashboardActionResult,
  UpdateDashboardActionResult,
} from './types';

export const useDashboards = (organisationId: number): DashboardsVS => {
  const dashboards: DashboardsVS | undefined = useSelector<Store, ReturnType<typeof selectDashboards>>(state =>
    selectDashboards(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadDashboardsAction(organisationId));
  }, [organisationId]);

  if (dashboards === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...dashboards,
    reload,
  };
};

export const useDashboard = (organisationId: number, dashboardId: number): DashboardVS => {
  const dashboards: DashboardsVS = useDashboards(organisationId);

  if (dashboards.status === 'success') {
    const dashboard = dashboards.value[dashboardId];
    if (!dashboard) {
      return {
        reload: dashboards.reload,
        status: 'error',
        error: new Error(`No dashboard found with id "${dashboardId}"`),
        value: undefined,
      };
    }
    return {
      ...dashboards,
      value: dashboard,
    };
  }

  return dashboards as DashboardVS;
};

export const useCreateDashboard = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (dashboard: Dashboard): Promise<CreateDashboardActionResult> => {
      const res: unknown = await dispatch(createDashboardAction(organisationId, dashboard));
      return res as CreateDashboardActionResult;
    },
    [organisationId],
  );
};

export const useDeleteDashboard = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (dashboardId: number): Promise<DeleteDashboardActionResult> => {
      const res = await dispatch(deleteDashboardAction(organisationId, dashboardId));
      return (res as unknown) as DeleteDashboardActionResult;
    },
    [organisationId],
  );
};

export const useUpdateDashboard = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (dashboard: Dashboard): Promise<UpdateDashboardActionResult> => {
      const res = await dispatch(updateDashboardAction(organisationId, dashboard));
      return (res as unknown) as UpdateDashboardActionResult;
    },
    [organisationId],
  );
};

export const useUpdateDashboardDetails = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback(
    async (dashboard: Dashboard): Promise<UpdateDashboardActionResult> => {
      const res = await dispatch(updateDashboardDetailsAction(organisationId, dashboard));
      return (res as unknown) as UpdateDashboardActionResult;
    },
    [organisationId],
  );
};

export const useCreateChart = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    async (dashboardId: number, card: DashboardChartCard): Promise<CreateChartActionResult> => {
      const res: unknown = await dispatch(createChartAction(organisationId, dashboardId, card));
      return res as CreateChartActionResult;
    },
    [organisationId],
  );
};

export const useUpdateChart = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    async (dashboardId: number, card: DashboardChartCard): Promise<CreateChartActionResult> => {
      const res: unknown = await dispatch(updateChartAction(organisationId, dashboardId, card.id, card));
      return res as CreateChartActionResult;
    },
    [organisationId],
  );
};

export const useDeleteChart = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    async (dashboardId: number, chartId: string): Promise<CreateChartActionResult> => {
      const res: unknown = await dispatch(deleteChartAction(organisationId, dashboardId, chartId));
      return res as DeleteChartActionResult;
    },
    [organisationId],
  );
};
