import { Store } from 'scalexp/store/state/types';

import { DeferredBillsSummationVS } from './types';

export const selectDeferredBillsSummation = (
  state: Store,
  organisationId: number,
): DeferredBillsSummationVS | undefined => {
  return state.deferredBillsSummation[organisationId];
};
