import React, { useMemo } from 'react';
import { reportEditorcalculationRequest } from 'scalexp/features/report-editor/rows/ReportEditorCustomRow/utils';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';
import { getSimpleMetricSchemaFromNode } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';

import Typography from '../../../../components/atoms/Typography';
import useActiveOrganisation from '../../../../components/contexts/OrganisationContext/useActiveOrganisation';
import useCurrencySelection from '../../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../../components/molecules/DateSelect/useDateSelection';
import { PIPELINE_METRIC_DATA_TYPE } from '../../../../service/types/metricSchema';
import { Pipeline, PipelineMetricsTypes } from '../../../../store/state/pipelines/types';
import useMetricSchemaSeries from '../../../../utils/metrics/useMetricSchemaSeries';
import { DISPLAY_COLUMNS, formatValue } from '../../ReportEditor/helpers';

interface ReportEditorDisplayPipelineRowProps {
  pipeline: Pipeline;
  metric: PipelineMetricsTypes;
  decimals: MetricSchema2Decimals;
}

const StyledGroupContainer = styled.div`
  width: 100%;
  height: 30px;
  display: grid;
  grid-template-columns: 20px 1fr 160px 160px;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

  span:nth-last-child(-n + 2) {
    text-align: end;
  }
`;

const ReportEditorDisplayPipelineRow: React.FC<React.PropsWithChildren<ReportEditorDisplayPipelineRowProps>> = ({
  pipeline,
  metric,
  decimals,
}) => {
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const organisation = useActiveOrganisation();
  const { default_currency_code, financial_year_start } = organisation;
  const seriesVS = useMetricSchemaSeries(
    getSimpleMetricSchemaFromNode({
      operator: 'add',
      data: {
        operator: 'entity',
        entity: 'pipeline',
        pipelineMetricId: metric,
        pipelineId: pipeline.id,
      },
    }),
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  return (
    <StyledGroupContainer>
      <div />
      <Typography color="secondary">{pipeline.name}</Typography>
      <Typography color="secondary">
        {formatValue(values[0], currencyCode || default_currency_code, PIPELINE_METRIC_DATA_TYPE[metric], decimals)}
      </Typography>
      <Typography color="secondary">
        {formatValue(values[1], currencyCode || default_currency_code, PIPELINE_METRIC_DATA_TYPE[metric], decimals)}
      </Typography>
    </StyledGroupContainer>
  );
};

export default ReportEditorDisplayPipelineRow;
