import { createSelector } from 'reselect';
import { Store } from 'scalexp/store/state/types';

import { ValueState } from '../../values';
import { AccountGroup } from '../accountGroups/types';
import { AccountsVS, Account } from './types';

export const selectAccounts = (state: Store, organisationId: number): AccountsVS | undefined => {
  return state.account[organisationId];
};

export type AccountsByGroup = { [groupId: AccountGroup['id']]: Account[] };
export const selectAccountsByGroup = createSelector([selectAccounts], accountsState => {
  if (accountsState === undefined || accountsState?.status !== 'success') {
    return accountsState as ValueState<AccountsByGroup>;
  }
  // Group the accounts by group
  const accounts = Object.values(accountsState.value);
  const accountsByGroup = accounts.reduce((acc, account) => {
    if (account.group_id) {
      acc[account.group_id] = [...(acc[account.group_id] || []), account];
    }
    return acc;
  }, {} as AccountsByGroup);

  return {
    ...accountsState,
    value: accountsByGroup,
  } as ValueState<AccountsByGroup>;
});

export type AccountsByTag = { [tagName: string]: Account[] };
export const selectAccountsByTags = createSelector([selectAccounts], accountsState => {
  if (accountsState === undefined || accountsState?.status !== 'success') {
    return accountsState as ValueState<AccountsByTag>;
  }

  // Group the accounts by tags
  const accounts = Object.values(accountsState.value);
  const accountsByTag = accounts.reduce((acc, account) => {
    if (account.tag) {
      acc[account.tag] = [...(acc[account.tag] || []), account];
    }
    return acc;
  }, {} as AccountsByTag);

  return {
    ...accountsState,
    value: accountsByTag,
  } as ValueState<AccountsByTag>;
});
