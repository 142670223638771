import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'scalexp/store/state/types';

import {
  loadOrganisationAction,
  ORGANISATIONS_ORGANISATION_UPDATE,
  PartialUpdatableOrganisation,
  updateOrganisationAction,
  updateOrganisationLandingChartAction,
  updateOrganisationLandingKpiCardAction,
  updateOrganisationLandingShortcutAction,
} from './actions';
import { selectOrganisation } from './selectors';
import { OrganisationVS } from './types';

export const useOrganisation = (organisationId: number): OrganisationVS => {
  const organisation: OrganisationVS | undefined = useSelector<Store, ReturnType<typeof selectOrganisation>>(state =>
    selectOrganisation(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadOrganisationAction(organisationId));
  }, [organisationId]);

  if (organisation === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...organisation,
    reload,
  };
};

export const useUpdateOrganisation = () => {
  const dispatch = useDispatch();

  return useCallback(async (organisationId: number, organisation: PartialUpdatableOrganisation): Promise<boolean> => {
    try {
      await dispatch(updateOrganisationAction(organisationId, organisation));
      return true;
    } catch (e) {
      return false;
    }
  }, []);
};

export const useUpdateOrganisationLogo = (organisationId: number) => {
  const dispatch = useDispatch();

  return useCallback((logoUrl: string | null) => {
    try {
      dispatch({
        type: ORGANISATIONS_ORGANISATION_UPDATE,
        payload: {
          logo_url: logoUrl,
        },
        status: 'success',
        params: {
          organisationId,
          organisation: {
            logo_url: logoUrl,
          },
        },
      });
      return true;
    } catch (e) {
      return false;
    }
  }, []);
};

export const useUpdateOrganisationLandingShortcut = () => {
  const dispatch = useDispatch();

  return useCallback(async (organisationId: number, organisation: PartialUpdatableOrganisation): Promise<boolean> => {
    try {
      await dispatch(updateOrganisationLandingShortcutAction(organisationId, organisation));
      return true;
    } catch (e) {
      return false;
    }
  }, []);
};

export const useUpdateOrganisationLandingKpiCard = () => {
  const dispatch = useDispatch();

  return useCallback(async (organisationId: number, organisation: PartialUpdatableOrganisation): Promise<boolean> => {
    try {
      await dispatch(updateOrganisationLandingKpiCardAction(organisationId, organisation));
      return true;
    } catch (e) {
      return false;
    }
  }, []);
};

export const useUpdateOrganisationLandingChart = () => {
  const dispatch = useDispatch();

  return useCallback(async (organisationId: number, organisation: PartialUpdatableOrganisation): Promise<boolean> => {
    try {
      await dispatch(updateOrganisationLandingChartAction(organisationId, organisation));
      return true;
    } catch (e) {
      return false;
    }
  }, []);
};
