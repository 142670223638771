import { SharingPages } from 'scalexp/features/sharing/types';
import useSWR from 'swr';

import { OrganisationId } from '.';
import Notification from '../components/atoms/Notification';
import fetcher from '../utils/fetcher';

export const ROLE = {
  FULL_ACCESS: 'FULL_ACCESS',
  VIEW_ONLY: 'VIEW_ONLY',
  GUEST: 'GUEST',
  UNKNOWN: 'UNKNOWN',
} as const;
export type Role = typeof ROLE[keyof typeof ROLE];
export const MEMBER_ROLES = [ROLE.FULL_ACCESS, ROLE.VIEW_ONLY] as const;
export type MemberRole = typeof MEMBER_ROLES[number];

export const sendInvitation = async (
  organisationId: OrganisationId,
  email: string,
  first_name: string | null,
  last_name: string | null,
  role: Role,
  from_url: string | null,
) => {
  return await fetcher(`/api/v1/organisations/${organisationId}/invitations/`, {
    method: 'POST',
    body: JSON.stringify({ email, first_name, last_name, role, from_url }),
  });
};

export const acceptInvitation = async (invitationId: string) => {
  return await fetcher(`/api/v1/invitations/${invitationId}/accept`, {
    method: 'POST',
  });
};

export type AcceptSuccessResponse = {
  status: 'success';
  target_url: string;
};

export type AcceptFailureResponse = {
  status: 'failure';
  reason: string;
};

export type Invitation = {
  email: string;
  from_url: string | null;
  registration_status: 'NOT_REGISTERED' | 'REGISTERED';
  invitation_status: 'ACCEPTED' | 'PENDING';
};

export type AcceptResponse = AcceptFailureResponse | AcceptSuccessResponse;

export const useInvitation = (invitationId: string) => useSWR<Invitation>(`/api/v1/invitations/${invitationId}`);

export type ListInvitationsResponse = {
  invitations: {
    email: string;
    first_name: string;
    last_name: string;
    role: Role;
    invitation_id: string;
  }[];
};

export const useInvitations = (organisationId: OrganisationId) =>
  useSWR<ListInvitationsResponse>(`/api/v1/organisations/${organisationId}/invitations/`);

export const cancelInvitation = async (organisationId: OrganisationId, invitationId: string) => {
  const resp = await fetcher(`/api/v1/organisations/${organisationId}/invitations/${invitationId}/cancel`, {
    method: 'POST',
  });

  return resp;
};

export const signup = async (invitationId: string, password: string) => {
  return await fetcher(`/api/v1/invitations/${invitationId}/signup`, {
    method: 'POST',
    body: JSON.stringify({ password }),
  });
};

export const login = async (invitationId: string, password: string) => {
  return await fetcher(`/api/v1/invitations/${invitationId}/login`, {
    method: 'POST',
    body: JSON.stringify({ password }),
  });
};

export type Member = {
  name: string;
  email: string;
  role: Role;
  user_id: number | null;
  invitation_id: string | null;
  resources: SharingPages | null;
};

type MembersResponse = {
  members: Member[];
};

export const useTeamMembers = (organisationId: OrganisationId) =>
  useSWR<MembersResponse>(`/api/v1/organisations/${organisationId}/members`);

export const updateMembership = async (organisationId: OrganisationId, membershipId: number, role: Member['role']) => {
  Notification.info({
    title: 'Updating member',
    placement: 'topEnd',
  });
  try {
    await fetcher(`/api/v1/organisations/${organisationId}/members/${membershipId}`, {
      body: JSON.stringify({ role: role }),
      method: 'PUT',
    });
  } catch (e) {
    Notification.closeAll();
    Notification.warning({
      title: 'Unable to update member',
      placement: 'topEnd',
    });
  }
};

export const removeMembership = async (organisationId: OrganisationId, membershipId: number) => {
  Notification.info({
    title: 'Removing member',
    placement: 'topEnd',
  });
  try {
    await fetcher(`/api/v1/organisations/${organisationId}/members/${membershipId}`, {
      method: 'DELETE',
    });
  } catch (e) {
    Notification.closeAll();
    Notification.warning({
      title: 'Unable to remove member',
      placement: 'topEnd',
    });
  }
};
