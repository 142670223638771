import React from 'react';
import Icon from 'scalexp/components/atoms/Icon';
import Row from 'scalexp/components/layout/Row';
import { theme } from 'scalexp/theme';
import styled from 'styled-components';

import Typography from '../../atoms/Typography';

interface SidebarVideoGuideProps {
  videoLink: string;
  videoImage: string;
  helpLink: string;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(4.5)}`};
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  cursor: pointer;
`;

const StyledVideoGuideLink = styled.a`
  display: flex;
  flex-direction: column;
  gap: 8px;

  &,
  &:hover {
    text-decoration: none;
  }
`;

const StyledImage = styled.img`
  width: 174px;
  height: 100px;
  border-radius: 4px;
`;

const StyledLink = styled.a`
  &,
  &:hover {
    text-decoration: none;
  }
`;

let SidebarVideoGuide: React.FC<React.PropsWithChildren<SidebarVideoGuideProps>> = ({
  videoLink,
  videoImage,
  helpLink,
}) => {
  return (
    <StyledContainer>
      <StyledVideoGuideLink href={videoLink} target="_blank" rel="noreferrer">
        <Typography color="secondary">Video Guide</Typography>
        <StyledImage width={174} src={videoImage} alt="video guide" />
      </StyledVideoGuideLink>
      <StyledLink href={helpLink} target="_blank" rel="noreferrer">
        <Row width="100%" vAlign="center" spacing="tiny">
          <Icon name="info" color={theme.palette.primary.main} marginRight={0} />
          <Typography display="block" color="primary" size="small">
            Learn more here
          </Typography>
        </Row>
      </StyledLink>
    </StyledContainer>
  );
};

export default SidebarVideoGuide;
