import posthog from 'posthog-js';
import React, { useCallback } from 'react';
import { usePosthogContext } from 'scalexp/components/contexts/PosthogContext';

export const FEATURE_FLAGS = {
  ACCRUALS: 'accruals',
  MONTH_END: 'month_end',
  DEALS_IN_PROGRESS: 'deals-in-progress',
  INVOICE_MATCHING: 'invoice-matching',
  POST_JOURNAL_WITH_PRIOR_ADJUSTMENTS: 'post_journal_with_prior_adjustments',
  TRACKING_CODES_DEFERRED_JOURNAL: 'tracking_codes_deferred_journal',
} as const;

export type FeatureFlag = typeof FEATURE_FLAGS[keyof typeof FEATURE_FLAGS];

export const posthogContext = React.createContext<Partial<typeof posthog> | undefined>(undefined);

type FeatureFlagChecker = (featureId: FeatureFlag) => boolean;

export const useFeatureFlagChecker = (): FeatureFlagChecker => {
  const posthog = usePosthogContext();

  return useCallback(
    (featureId: string): boolean => {
      // err on the side of openness. Someone not being able to do their job is
      // going to be more annoying than accidentally seeing an unbaked feature
      if (!posthog || !posthog.isFeatureEnabled) {
        return true;
      }

      return !!posthog.isFeatureEnabled(featureId);
    },
    [posthog],
  );
};

export const useFeatureFlag = (featureId: FeatureFlag): boolean => useFeatureFlagChecker()(featureId);
