import { WaterfallChart } from 'scalexp/features/chart/ChartCard/types';
import { CalculationRequestEntry } from 'scalexp/utils/metrics/types';

import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../components/molecules/DateSelect/useDateSelection';
import { getFiscalQuarter } from '../../../utils/dates';
import { DateKeys, dateToRequest } from '../../../utils/metrics/calculations';
import { useMultipleMetricSchemaSeries } from '../../../utils/metrics/useMetricSchemaSeries';
import { getWaterfallChartDateBounds, getXAxis, getYAxis } from './helpers';

const CURSOR_TEMPLATE = {
  lineY: {
    stroke: '#ff7f00',
    disabled: true,
  },
  lineX: {
    stroke: '#ff7f00',
    disabled: true,
  },
  type: 'XYCursor',
};

const LEGEND_TEMPLATE = {
  fontSize: 10,
  marginBottom: 20,
  position: 'bottom',
};

const XY_SERIES_TEMPLATE = {
  z_index: 1,
  fill: undefined,
  type: 'ColumnSeries',
  tooltip: {
    fontSize: 12,
  },
  tooltipText: '{valueY}',
  strokeWidth: 0,
  prefer_axis: 'Y',
  name: '',
  invert_values: true,
  show_tooltip: true,
  zIndex: 2,
  dataFields: {
    valueY: '',
    dateX: 'date',
    categoryX: 'category',
  },
  stroke: '#84c77e',
  fill_to_use_when_negative: undefined,
} as const;

const useWaterfallChartConfig = (config: WaterfallChart, chartDataType: string) => {
  const {
    default_budget_set_id,
    default_consolidated_budget_set_id,
    financial_year_start: financialYearStart,
    default_currency_code,
    consolidation_type,
  } = useOrganisationContext();
  const defaultBudgetId = consolidation_type === 'PARENT' ? default_consolidated_budget_set_id : default_budget_set_id;
  const focalDate = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();
  const currency = currencyCode || default_currency_code;
  const { fiscalYear, fiscalQuarter } = getFiscalQuarter(focalDate, 0, financialYearStart);
  const chartData = config.chart_data;
  const { offset, period, series, number_of_periods } = chartData;
  const dateKeys = getWaterfallChartDateBounds(number_of_periods, offset, period, focalDate, financialYearStart);
  const xAxis = getXAxis(chartData, focalDate, fiscalYear, fiscalQuarter, currency);
  const yAxis = getYAxis(chartData, focalDate, fiscalYear, fiscalQuarter, currency, chartDataType);
  const yAxes = [yAxis];
  const xAxes = [xAxis];
  const sortedSeries = series.sort((a, b) => a.order - b.order).map(series => series.metric_schema);

  const budgetIds = series
    .map(series => {
      if (series.value_basis === 'BUDGET') {
        return (consolidation_type === 'PARENT' ? series.consolidated_budget : series.budget) || defaultBudgetId;
      }

      return false;
    })
    .filter(Boolean) as number[];

  const calculationRequest = dateToRequest(
    dateKeys as DateKeys,
    budgetIds.length ? [...budgetIds] : defaultBudgetId ? [defaultBudgetId] : undefined,
    currency,
  );
  const { status: seriesStatus, value: expandedMetricSchemaSeries } = useMultipleMetricSchemaSeries(
    sortedSeries,
    calculationRequest,
  );

  if (seriesStatus !== 'success') {
    return {
      status: 'pending',
      value: null,
    };
  }

  const chartSeries = series.map((series, index) => {
    const valueBasis = series.value_basis;
    const valueY = `series-${series.id}-${series.name}-value-${valueBasis}-${index}`;
    return {
      ...XY_SERIES_TEMPLATE,
      name: series.name,
      zIndex: series.order || index,
      fill_to_use_when_negative: series.color_negative,
      fill: series.color,
      stroke: series.color,
      dataFields: {
        ...XY_SERIES_TEMPLATE.dataFields,
        valueY,
      },
    };
  });

  const chartConfigData = dateKeys.map((dateKey, dateIndex) => {
    const firstNode = dateIndex === 0;
    const lastNode = dateIndex === dateKeys.length - 1;
    let valueDict = {
      category: dateKey.split('-').reverse().join(' '),
      date: dateKey,
    };
    expandedMetricSchemaSeries!.forEach((metricSeries, index) => {
      const waterfallSeries = series[index];
      const isLast = index === expandedMetricSchemaSeries!.length - 1;
      const isFirst = index === 0;
      const chartSeriesData = chartSeries[index];
      const valueKey = chartSeriesData.dataFields.valueY;
      let isPercentage = false;
      let valueAtDate = 0;

      valueAtDate = Number(metricSeries[dateIndex]?.value || 0);
      isPercentage = metricSeries[dateIndex]?.data_type?.toLowerCase() === 'percentage';
      const budgetId =
        (consolidation_type === 'PARENT' ? waterfallSeries.consolidated_budget : waterfallSeries.budget) ||
        defaultBudgetId;

      let offset = 0;
      if (waterfallSeries.value_basis === 'BUDGET') {
        offset = calculationRequest.findIndex(
          (v: CalculationRequestEntry) => v.source === 'budget' && v.budget_id === budgetId,
        );
      }

      if (offset === -1) {
        valueAtDate = 0;
        isPercentage = metricSeries[dateIndex]?.data_type?.toLowerCase() === 'percentage';
      } else {
        valueAtDate = Number(metricSeries[dateIndex + offset]?.value || 0);
        isPercentage = metricSeries[dateIndex + offset]?.data_type?.toLowerCase() === 'percentage';
      }
      const value =
        (isLast || isFirst) && period !== 'YEAR_TO_DATE'
          ? isFirst && firstNode
            ? waterfallSeries.invert_axis
              ? valueAtDate * -1
              : valueAtDate
            : isLast && lastNode
            ? waterfallSeries.invert_axis
              ? valueAtDate * -1
              : valueAtDate
            : 0
          : waterfallSeries.invert_axis
          ? valueAtDate * -1
          : valueAtDate;
      const formattedValue = isPercentage ? Number(value * 100).toFixed(2) : Math.round(value);
      valueDict = {
        ...valueDict,
        [valueKey]: formattedValue,
      };
    });
    const { date, category, ...values } = valueDict;
    return { ...valueDict, isNull: Object.values(values).every(value => !value) };
  });
  return {
    status: 'success',
    value: {
      type: 'XYChart',
      legend: LEGEND_TEMPLATE,
      titles: [],
      series: chartSeries,
      data: chartConfigData,
      yAxes: yAxes,
      xAxes: xAxes,
      cursor: CURSOR_TEMPLATE,
      isEmpty: chartConfigData.every(data => data.isNull),
      scrollbarX: {
        marginTop: 0,
        paddingTop: 0,
      },
    },
  } as any;
};

export default useWaterfallChartConfig;
