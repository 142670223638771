import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectActualsExchangeRateOverrides } from './selectors';
import { ActualsExchangeRateOverridePayload, ActualsExchangeRateOverrides } from './types';

export const ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_LOAD = '[actualsExchangeRateOverride] LOAD';
export const ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_UPDATE = '[actualsExchangeRateOverride] UPDATE';

export type LoadBudgetCurrencyAction = PromiseAction<
  typeof ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_LOAD,
  ActualsExchangeRateOverrides,
  {
    organisationId: number;
  }
>;
export type UpdateBudgetCurrencyAction = PromiseAction<
  typeof ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_UPDATE,
  ActualsExchangeRateOverridePayload,
  {
    organisationId: number;
    rate_type: 'AVERAGE' | 'LAST_DAY';
    from_currency: string;
    to_currency: string;
    date_for: string;
    value: number;
  }
>;

export type ExchangeRatesActions = LoadBudgetCurrencyAction | UpdateBudgetCurrencyAction;

export const loadActualsExchangeRateOverridesAction = (
  organisationId: number,
): ThunkAction<ExchangeRatesActions> => async (dispatch: ThunkDispatch<ExchangeRatesActions>, getState) => {
  const state = getState();
  const exchangeRates = selectActualsExchangeRateOverrides(state, organisationId);

  if (exchangeRates?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_LOAD,
        () =>
          http<{ override_rates: ActualsExchangeRateOverrides }, ActualsExchangeRateOverrides>(
            `/api/v1/organisations/${organisationId}/exchange_rates_overrides`,
            {},
            // @ts-ignore
            response => response.override_rates,
          ),
        { organisationId },
      ),
    );
  } catch (e) {
    console.warn('Error dispatching action', e);
  }
};

export const setActualsExchangeRateOverride = (
  organisationId: number,
  rateType: 'AVERAGE' | 'LAST_DAY',
  fromCurrency: string,
  toCurrency: string,
  dateFor: string,
  value: number,
): ThunkAction<ExchangeRatesActions> => async (dispatch: ThunkDispatch<ExchangeRatesActions>, getState) => {
  const state = getState();
  const exchangeRates = selectActualsExchangeRateOverrides(state, organisationId);

  if (exchangeRates?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_ACTUALS_EXCHANGE_RATE_OVERRIDES_UPDATE,
        () =>
          http(`/api/v1/organisations/${organisationId}/exchange_rates_overrides/`, {
            method: 'POST',
            body: {
              rate_type: rateType,
              from_currency: fromCurrency,
              to_currency: toCurrency,
              date_for: dateFor + '-01',
              value: value,
            },
          }),
        {
          organisationId,
          rate_type: rateType,
          from_currency: fromCurrency,
          to_currency: toCurrency,
          date_for: dateFor,
          value: value,
        },
      ),
    );
  } catch (e) {
    console.warn('Error dispatching action', e);
  }
};
