import isEqual from 'lodash/isEqual';
import React from 'react';
import { Whisper } from 'rsuite';
import { WhisperInstance } from 'rsuite/lib/Whisper';
import styled from 'styled-components';

import IconButton, { StyledIconButton } from '../../../components/atoms/IconButton';
import OptionsContainer from '../../../components/atoms/OptionsContainer';
import Typography from '../../../components/atoms/Typography';
import Aligner from '../../../components/layout/Aligner';
import Spacer from '../../../components/layout/Spacer';
import { COLOR_OPTIONS } from '../../../components/molecules/ColorPicker';
import { HSL } from '../../report/rows/ReportRowCell';

interface SelectColorProps {
  title?: string;
  width?: string;
  value?: HSL;
  onSelect: (option: string) => void;
}

const StyledSelectColor = styled.div`
  ${({ color = '#ffffff' }) =>
    `
    ${StyledIconButton} {
      background: ${color};
    }
  `}
`;

const ColorBox = styled.div<{ color?: string; isActive?: boolean }>`
  ${({ theme, color = '#ffffff', isActive }) =>
    `
      cursor: pointer;
      position: static;
      width: 20px;
      height: 20px;
      left: 48px;
      top: 8px;
      background: ${color};
      border: 2px solid ${isActive ? theme.palette.primary.main : theme.palette.backgroundGrey};
      border-radius: 4px;
      flex: none;
      order: 1;
  `}
`;

const colors = Object.entries(COLOR_OPTIONS);

const SelectColor: React.FC<React.PropsWithChildren<SelectColorProps>> = ({
  onSelect,
  title,
  children,
  value,
  width = '150px',
  ...other
}) => {
  const whisperRef = React.useRef<WhisperInstance | null>(null);
  const [selected, setSelected] = React.useState<string | undefined>(
    colors?.find(([option, hsl]) => isEqual(hsl, value))?.[0],
  );
  const handleItemClick = (item: string) => {
    setSelected(item);
    onSelect(item);
    whisperRef.current?.close();
  };
  return (
    <StyledSelectColor {...other} color={selected} onMouseDown={e => e.stopPropagation()}>
      <Whisper
        ref={whisperRef}
        trigger="click"
        placement="bottomStart"
        speaker={
          <OptionsContainer width={width}>
            <Spacer padding={{ top: 2, bottom: 4, left: 4, right: 4 }}>
              <Spacer padding={{ bottom: 2 }}>
                <Typography color="secondary"> Select row color</Typography>
              </Spacer>

              <Aligner hAlign="flex-start" wrap>
                {colors.map(([option], index) => {
                  const isActive = selected ? option === selected : index === 0;
                  return (
                    <Spacer margin={{ right: 1, bottom: 2 }}>
                      <ColorBox onClick={() => handleItemClick(option)} isActive={isActive} color={option} />{' '}
                    </Spacer>
                  );
                })}
              </Aligner>
            </Spacer>
          </OptionsContainer>
        }
      >
        <IconButton size="medium" name="color_lens" />
      </Whisper>
    </StyledSelectColor>
  );
};
export default SelectColor;
