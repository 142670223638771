import get from 'lodash/get';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { ValidationContextProvider } from '../../contexts/ValidationContext';
import Select, { SelectPlacement } from '../Select';

interface FormFieldSelectProps {
  rules?: object;
  name: string;
  defaultValue?: any;
  placement?: SelectPlacement;
  data: any;
  placeholder?: string;
  selectWidth?: number | 'maxOptionWidth';
  selectHeight?: number;
  width?: string;
  groupBy?: string;
  orderGroups?: 'ASCENDING' | 'DESCENDING';
  searchable?: boolean;
  customSize?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  disabled?: boolean;
  onChange?: Function;
}

function FormFieldSelect<Value = string>({
  name,
  placeholder,
  defaultValue,
  rules = {},
  width,
  searchable = false,
  data,
  selectHeight = 386,
  customSize = 'small',
  selectWidth,
  label,
  tooltip,
  onClick,
  disabled = false,
  orderGroups = 'DESCENDING',
  groupBy,
  onChange,
  placement,
}: React.PropsWithChildren<FormFieldSelectProps>) {
  const { control, formState } = useFormContext();
  const { errors } = formState;
  let hasError = !!(errors[name] || get(errors, name));

  return (
    <ValidationContextProvider hasError={hasError}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{ required: true, ...rules }}
        render={({ field }) => (
          <Select<Value>
            disabled={disabled}
            onClick={onClick}
            width={width}
            label={label}
            tooltip={tooltip}
            data={data}
            groupBy={groupBy}
            orderGroups={orderGroups}
            selectHeight={selectHeight}
            size={customSize}
            value={field.value}
            placement={placement}
            selectWidth={selectWidth}
            onChange={value => {
              field.onChange(value);
              onChange?.(value);
            }}
            placeholder={placeholder}
            searchable={searchable}
          />
        )}
      />
    </ValidationContextProvider>
  );
}

export default FormFieldSelect;
