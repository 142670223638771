import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const HeaderNavBar = styled.header`
  height: 60px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing(5)}`};
  background: linear-gradient(89.94deg, #181818 0.04%, #051133 0.05%, #030b23 100%, #030b23 100%);

  hr {
    opacity: 0.72;
  }
`;

export const LogoContainer = styled.div`
  margin-right: 16px;

  img.logo {
    content: url('/images/logos/scalexp.svg');
  }

  @media (min-width: 1300px) {
    img.logo {
      content: url('/images/logos/scalexp-banner.svg');
    }
  }
`;

export const NavItem = styled(Link)<{
  $isActive?: boolean;
}>`
  height: 60px;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `0 ${theme.spacing(1)}`};
  ${({ $isActive, theme }) => ($isActive ? `border-bottom: 3px solid ${theme.palette.white}` : '')};
  background-color: ${({ $isActive }) => ($isActive ? `rgba(255, 255, 255, 0.1)` : 'unset')};
  cursor: pointer;

  span {
    opacity: ${({ $isActive }) => ($isActive ? 1 : 0.72)};
    white-space: nowrap;
  }

  &:hover {
    border-bottom: ${({ theme }) => `3px solid ${theme.palette.white}`};
    background-color: rgba(255, 255, 255, 0.1);
    span {
      opacity: 1;
    }
  }

  @media (min-width: 960px) {
    padding: ${({ theme }) => `0 ${theme.spacing(2)}`};

    span {
      font-size: ${({ theme }) => theme.font.size.small};
      &.material-icons {
        display: none;
      }
    }
  }

  @media (min-width: 1300px) {
    padding: ${({ theme }) => `0 ${theme.spacing(4)}`};

    span {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }
`;

export const SwitchOrgContainer = styled.div`
  width: 140px;

  & > div > div:first-child {
    background-color: transparent;
    span {
      color: white;
      font-weight: ${({ theme }) => theme.font.weight.regular};
    }
  }

  @media (min-width: 1300px) {
    & {
      width: 250px;
    }
  }
`;

export const HelpContainer = styled.a`
  height: 30px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
  cursor: pointer;
  padding: 0px ${({ theme }) => theme.spacing(3)};
  background: rgba(255, 255, 255, 0.2);

  &:hover {
    text-decoration: none !important;
    background: ${({ theme }) => theme.palette.midnight};
  }
  border-radius: ${({ theme }) => theme.spacing(1.5)};
`;

export const ProfileDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(1)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`};
`;

export const ProfilePicture = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
`;

export const StyledSettingsLink = styled(Link)`
  ${({ theme }) => css`
    background: rgba(255, 255, 255, 0.2);
    padding: 6px 8px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: ${theme.spacing(2)};

    &:hover {
      text-decoration: none;
    }
  `}
`;
