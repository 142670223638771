import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectCharts } from './selectors';
import { Chart } from './types';

export const ORGANISATIONS_CHARTS_LOAD = '[charts] LOAD';

export type ChartsActions = PromiseAction<typeof ORGANISATIONS_CHARTS_LOAD, Chart[], { organisationId: number }>;

type ChartResponse = Chart[];

export const loadChartsAction = (organisationId: number): ThunkAction<ChartsActions> => async (
  dispatch: ThunkDispatch<ChartsActions>,
  getState,
) => {
  const state = getState();
  const charts = selectCharts(state, organisationId);

  if (charts?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CHARTS_LOAD,
        () => http<Chart[], ChartResponse>(`/api/v1/organisations/${organisationId}/charts/`),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
