import {
  DispatchPromiseAction,
  PromiseActionComplete,
  PromiseActionError,
  PromiseActionPending,
  ThunkPromiseAction,
} from 'scalexp/store/types';

const setPending = <Type extends string, Params extends object>(
  type: Type,
  params: Params = {} as Params,
): PromiseActionPending<Type, Params> => ({
  type,
  params,
  status: 'pending',
});

const setComplete = <Type extends string, R extends unknown, Params extends object>(
  type: Type,
  payload: R,
  params: Params = {} as Params,
): PromiseActionComplete<Type, R, Params> => ({
  type,
  params,
  payload,
  status: 'success',
});

const setError = <Type extends string, Params extends object>(
  type: Type,
  error: Error,
  params: Params = {} as Params,
): PromiseActionError<Type, Params> => ({
  type,
  params,
  error,
  status: 'error',
});

export function promiseAction<Response = void>(
  type: string,
  asyncFn: () => Promise<Response>,
  params: object = {},
): ThunkPromiseAction<typeof type, Response, typeof params> {
  return async (dispatch: DispatchPromiseAction<typeof type, Response, typeof params>) => {
    dispatch(setPending(type, params));
    try {
      const response = await asyncFn();
      dispatch(setComplete(type, response, params));
      return response;
    } catch (error) {
      dispatch(setError(type, error as Error, params));
      throw error;
    }
  };
}
