import React, { useEffect, useState } from 'react';
import FormulaEditorModal from 'scalexp/components/organisms/FormulaEditorModal';
import { PERIODS_OPTIONS } from 'scalexp/features/chart-editor/NewChartsSidebar/constants';
import { MetricSchema2Complex } from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Divider from '../../../components/atoms/Divider';
import Typography from '../../../components/atoms/Typography';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Select from '../../../components/molecules/Select';
import { Period, WaterfallChart } from '../../chart/ChartCard/types';
import WaterFallChartSeries from '../WaterfallChartSerie';

const NUMBER_OF_MONTHS = 24;

const WaterfallOptionWrapper = styled(Column)`
  padding: 19px 16px;
`;

const WATERFALL_MONTHS_OPTIONS = [...new Array(NUMBER_OF_MONTHS)].map((item, index) => ({
  label: `${index + 1} period${index > 0 ? 's' : ''}`,
  value: index + 1,
}));

export interface WaterfallChartOptionsProps {
  chart: WaterfallChart;
  budgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
  consolidatedBudgetOptions: ({ label: string; value: number } | { label: string; value: null })[];
}
const WaterfallChartOptions: React.FC<WaterfallChartOptionsProps> = ({
  chart,
  budgetOptions,
  consolidatedBudgetOptions,
}) => {
  const { setConfig, setActiveSeries } = useEditChartContext();
  const [configureDetails, setConfigureDetails] = useState<null | {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => void;
  }>(null);
  const [showModal, setShowModal] = useState(false);

  const number_of_periods = chart.chart_data.number_of_periods;
  const period = chart.chart_data.period;

  const handleWaterfallTimeRange = (number_of_periods: number) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        number_of_periods,
      },
    });
  };
  const handlePeriodChange = (period: Period) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        period,
      },
    });
  };

  useEffect(() => {
    if (chart.chart_data.series.length) {
      setActiveSeries(chart.chart_data.series[0].id);
    }
  }, []);

  const handleConfigure = (metricSchema: MetricSchema2Complex, index: number) => {
    setConfigureDetails({
      metricSchema,
      updateMetricSchema: updatedMetricSchema => {
        setConfig({
          ...chart,
          chart_data: {
            ...chart.chart_data,
            series: chart.chart_data.series.map((series, seriesIndex) => {
              if (seriesIndex === index) {
                return {
                  ...series,
                  metric_schema: updatedMetricSchema,
                };
              }

              return series;
            }),
          },
        });
      },
    });
    setShowModal(true);
  };

  return (
    <WaterfallOptionWrapper spacing="medium" width="100%" hAlign="center">
      <Row hAlign="space-around" width="100%">
        <Column>
          <Typography display="block">Select period to include:</Typography>
          <Select
            size="medium"
            width="100%"
            value={period}
            onChange={value => handlePeriodChange(value as Period)}
            data={PERIODS_OPTIONS}
          />
        </Column>
        <Column>
          <Typography display="block">Number of periods:</Typography>
          <Row width="100%" spacing="small">
            <Select
              size="medium"
              disabled={period === 'YEAR_TO_DATE'}
              width="100%"
              placeholder="Number of periods"
              data={WATERFALL_MONTHS_OPTIONS}
              onChange={value => handleWaterfallTimeRange(value)}
              value={number_of_periods}
            />
          </Row>
        </Column>
      </Row>
      <Divider />
      {chart.chart_data.series.map((serie, index) => {
        return (
          <WaterFallChartSeries
            key={uuidv4()}
            index={index}
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            // @ts-ignore
            handleConfigure={handleConfigure}
          />
        );
      })}
      {showModal && configureDetails != null && (
        <FormulaEditorModal configureDetails={configureDetails} onHide={() => setShowModal(false)} />
      )}
    </WaterfallOptionWrapper>
  );
};
export default WaterfallChartOptions;
