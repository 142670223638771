import { Store } from 'scalexp/store/state/types';

import { DeferredBillsRemainingVS } from './types';

export const selectDeferredBillsRemaining = (
  state: Store,
  organisationId: number,
): DeferredBillsRemainingVS | undefined => {
  return state.deferredBillsRemaining[organisationId];
};
