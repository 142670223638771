export interface FormulaPickerOption {
  label: string;
  value: string;
  group?: string;
  name?: string;
  id?: string;
  role?: string;
  source_id?: string;
}

export const filterByCategory = (data: FormulaPickerOption[], category: string | null): FormulaPickerOption[] => {
  if (!category) {
    return data;
  }

  if (category === 'crm') {
    return data.filter(
      option => option.source_id && ['crm', 'hubspot', 'salesforce', 'pipedrive'].includes(option.source_id),
    );
  }

  if (category === 'financial') {
    return data.filter(
      option => option.source_id && !['crm', 'hubspot', 'salesforce', 'manual', 'scalexp'].includes(option.source_id),
    );
  }

  if (category === 'row') {
    return data.filter(option => option.value.startsWith('row:'));
  }

  return data.filter(item => item.source_id === category);
};
