import React, { ButtonHTMLAttributes } from 'react';
import { Icon as RIcon } from 'rsuite';
import styled from 'styled-components';

import Icon from '../Icon';
import Typography from '../Typography';

export interface TextButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'secondary-filled'
    | 'outlined'
    | 'warning'
    | 'danger'
    | 'danger-outlined'
    | 'transparent';
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
  width?: string;
  loading?: boolean;
  children: string | React.ReactNode;
  iconLeft?: string;
  iconRight?: string;
  loadingText?: string;
}

// TODO: this TextButton style can be greatly simplified when we remove global RSuite styles
export const StyledTextButton = styled.button<TextButtonProps>`
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
  background-color: ${({ theme }) => theme.palette.white};

  &:focus {
    outline: ${({ theme }) => theme.palette.primary.light} solid 2px;
    outline-offset: 2px;
  }

  ${({ theme, variant }) => {
    if (variant === 'primary') {
      return `
        background-color: ${theme.palette.primary.main};
        color: ${theme.palette.white};

        &:hover,
        &:focus {
          background-color: ${theme.palette.midnight};
          color: ${theme.palette.white};
        }
      `;
    }
    if (variant === 'secondary') {
      return `
        color: ${theme.palette.midnight};
        border: 1.2px solid ${theme.palette.midnight};

        &:hover,
        &:focus {
          border: 1.2px solid ${theme.palette.primary.main};
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.white};

          span {
            color: ${theme.palette.white};
          }
        }
      `;
    }
    if (variant === 'secondary-filled') {
      return `
        background-color: ${theme.palette.midnight};
        color: ${theme.palette.white};

        &:hover,
        &:focus {
          background-color: ${theme.palette.primary.dark};
        }
      `;
    }
    // Old variants without a new equivalent.
    if (variant === 'outlined') {
      return `
        background-color: ${theme.palette.white};
        color: ${theme.palette.primary.main};
        border: 1.2px solid${theme.palette.primary.main};

        &:hover,
        &:focus {
          filter: brightness(85%);
        }
      `;
    }
    if (variant === 'warning') {
      return `
        background-color: ${theme.palette.white};
        border:1.2px solid #BD5B00;
        color: #BD5B00;

        &:hover,
        &:focus {
          filter: brightness(85%);
        }
      `;
    }
    if (variant === 'danger') {
      return `
        background-color: ${theme.palette.danger.dark};
        border-color: ${theme.palette.danger.dark};
        color: ${theme.palette.white};

        &:hover,
        &:focus {
          filter: brightness(85%);
        }
      `;
    }
    if (variant === 'danger-outlined') {
      return `
        background-color: ${theme.palette.white};
        border: 1.2px solid ${theme.palette.danger.dark};
        color: ${theme.palette.danger.dark};

        &:hover,
        &:focus {
          filter: brightness(85%);
        }
      `;
    }
    if (variant === 'transparent') {
      return `
        background-color: transparent;
        color: ${theme.palette.granite};
        border: none;

        &:hover {
          background-color: ${theme.palette.backgroundGrey};
          color: ${theme.palette.primary.main};
        }
        &:focus {
          color: ${theme.palette.primary.main};
          outline: none;
        }
      `;
    }
    return '';
  }}

  ${({ theme, size }) => {
    if (size === 'tiny') {
      return `
        height: ${theme.sizing(5)};
        border-radius: ${theme.sizing(1)};
      `;
    }
    if (size === 'small') {
      return `
        height: ${theme.sizing(7)};
        border-radius: ${theme.sizing(1)};
      `;
    }
    if (size === 'medium') {
      return `
        height: ${theme.sizing(9)};
        padding: 0 ${theme.sizing(3)};
        border-radius: ${theme.sizing(2)};
      `;
    }
    if (size === 'large') {
      return `
        height: ${theme.sizing(10)};
        padding: 0 ${theme.sizing(3)};
        border-radius: ${theme.sizing(2)};
      `;
    }
    if (size === 'xlarge') {
      return `
        height: ${theme.sizing(12)};
        padding: 0 ${theme.sizing(3)};
        border-radius: ${theme.sizing(2)};
      `;
    }

    return '';
  }}

  &:disabled {
    opacity: 0.4;
  }
`;

const TextButton: React.FC<React.PropsWithChildren<TextButtonProps>> = ({
  variant = 'primary',
  size = 'small',
  width = 'unset',
  children,
  iconLeft,
  iconRight,
  loadingText = 'Loading...',
  loading = false,
  ...other
}) => {
  const typographySize =
    size === 'small' || size === 'medium' ? 'small' : size === 'large' || size === 'xlarge' ? 'medium' : size;
  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : size === 'xlarge' ? 6 : 4;

  return (
    <StyledTextButton variant={variant} size={size} width={width} {...other}>
      {iconLeft && (
        <Icon
          size={iconSize}
          color="inherit"
          name={iconLeft}
          marginRight={0}
          outlined={iconLeft.endsWith('outlined')}
          rounded={iconLeft.endsWith('round')}
        />
      )}
      <Typography color="inherit" size={typographySize}>
        {loading ? loadingText : children}
      </Typography>
      {loading ? (
        <RIcon icon="spinner" spin />
      ) : iconRight ? (
        <Icon
          size={iconSize}
          color="inherit"
          name={iconRight}
          marginRight={0}
          outlined={iconRight.endsWith('outlined')}
          rounded={iconRight.endsWith('round')}
        />
      ) : null}
    </StyledTextButton>
  );
};

export default TextButton;
