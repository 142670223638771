import React from 'react';
import { User } from 'scalexp/components/contexts/DeprecatedUserContext/types';

const AnonymousUser: User = {
  user_id: -1,
  username: 'anonymous',
  current_organisation_id: -1,
  is_staff: false,
};

interface UserContext {
  user: User | null;
}

const userContext = React.createContext<UserContext>({ user: null }); // Create a context object

export { userContext, AnonymousUser };
