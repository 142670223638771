import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';

export type DecodedAccessToken = {
  'https://scalexp.com/membership': string;
  permissions: string[];
} & {
  [key: string]: string[];
};

const ACCESS_TOKEN_KEY = 'jwt_token';

export const setAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
};

export const getAccessToken = (): string => {
  return localStorage.getItem(ACCESS_TOKEN_KEY) || '';
};

export const addAccessTokenToCookies = () => {
  const accessToken = getAccessToken();
  // retrieve the domain name
  const locationParts = window.location.hostname.split('.');
  locationParts.shift();
  const domain = locationParts.join('.');
  document.cookie = `jwt_token=${accessToken};path=/;domain=${domain};secure;`;
};

export const getDecodedAccessToken = (): DecodedAccessToken | null => {
  const accessToken = getAccessToken();
  if (!accessToken) {
    return null;
  }
  try {
    return jwt_decode<DecodedAccessToken>(accessToken);
  } catch (e) {
    console.error('Error decoding access token', e);
    return null;
  }
};

const handleAccessTokenChange = (e: StorageEvent): void => {
  if (e.storageArea !== localStorage || e.key !== ACCESS_TOKEN_KEY) {
    return;
  }
  window.location.reload();
};

export const useAccessTokenListener = (): void => {
  useEffect(() => {
    window.addEventListener('storage', handleAccessTokenChange);
    return () => {
      window.removeEventListener('storage', handleAccessTokenChange);
    };
  }, []);
};
