import { Store } from 'scalexp/store/state/types';

import { DeferredInvoicesSummationVS } from './types';

export const selectDeferredInvoicesSummation = (
  state: Store,
  organisationId: number,
): DeferredInvoicesSummationVS | undefined => {
  return state.deferredInvoicesSummation[organisationId];
};
