import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'scalexp/store/state/types';

import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import { NativeMetricId } from '../../../service/types/metricSchema';
import { ValueState } from '../../values';
import { loadAccountsAction } from '../accounts/actions';
import { useAccounts } from '../accounts/hooks';
import { Account, AccountsVS } from '../accounts/types';
import {
  cascadeAccountGroupsAction,
  createGroupAction,
  createSubGroupAction,
  deleteGroupAction,
  deleteSubGroupAction,
  loadAccountGroupsAction,
  moveAccountsToGroupAction,
  moveAccountsToNativeMetricAction,
  moveAccountsToSubGroupAction,
  renameGroupAction,
  renameNativeMetricAction,
  renameSubGroupAction,
  reorderGroupAction,
  reorderSubGroupAction,
} from './actions';
import { selectAccountGroups, selectAccountGroupsByTags } from './selectors';
import { AccountGroup, AccountGroupsByTag, AccountGroupsVS } from './types';

export const useAccountGroups = (): AccountGroupsVS => {
  const organisationId = useActiveOrganisationId();
  const accountGroups: AccountGroupsVS | undefined = useSelector<Store, ReturnType<typeof selectAccountGroups>>(state =>
    selectAccountGroups(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadAccountGroupsAction(organisationId));
  }, [organisationId]);

  if (accountGroups === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...accountGroups,
    reload,
  };
};

export const useAccountGroupById = (groupId?: AccountGroup['id']): ValueState<AccountGroup | undefined> => {
  const groups = useAccountGroups();

  if (groups.status !== 'success') {
    return groups as ValueState<AccountGroup>;
  }

  return {
    ...groups,
    value: groupId ? groups.value[groupId] : undefined,
  };
};

export const useAccountGroupsByTag = (): ValueState<AccountGroupsByTag> => {
  const organisationId = useActiveOrganisationId();
  const accountGroupsByTag: ValueState<AccountGroupsByTag> = useSelector<
    Store,
    ReturnType<typeof selectAccountGroupsByTags>
  >(state => selectAccountGroupsByTags(state, organisationId));
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadAccountGroupsAction(organisationId));
  }, [organisationId]);

  if (accountGroupsByTag === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...accountGroupsByTag,
    reload,
  };
};

export const useAccountGroupsInTag = (tag?: string): ValueState<AccountGroup[]> => {
  const accountGroupsByTag = useAccountGroupsByTag();

  if (accountGroupsByTag.status !== 'success') {
    return accountGroupsByTag as ValueState<AccountGroup[]>;
  }

  return {
    ...accountGroupsByTag,
    value: !tag
      ? []
      : (accountGroupsByTag.value[tag] || []).filter(group => !group.parent_group_id).sort((a, b) => a.order - b.order),
  };
};

export const useRenameNativeMetric = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (tag: string, name: string) => {
      dispatch(renameNativeMetricAction(organisationId, tag, name));
    },
    [organisationId],
  );
};

export const useMoveAccountsToNativeMetric = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (tag: string, accountIds: Account['account_id'][]) => {
      dispatch(moveAccountsToNativeMetricAction(organisationId, tag, accountIds));
    },
    [organisationId],
  );
};

export const useCreateGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (tag: string, name: string, accountIds: Account['account_id'][]) => {
      dispatch(createGroupAction(organisationId, tag, name, accountIds));
    },
    [organisationId],
  );
};

export const useRenameGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (groupId: AccountGroup['id'], name: string) => {
      dispatch(renameGroupAction(organisationId, groupId, name));
    },
    [organisationId],
  );
};

export const useReorderGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (tag: NativeMetricId, orderedGroupIds: AccountGroup['id'][]) => {
      dispatch(reorderGroupAction(organisationId, tag, orderedGroupIds));
    },
    [organisationId],
  );
};

export const useDeleteGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (groupId: AccountGroup['id']) => {
      await dispatch(deleteGroupAction(organisationId, groupId));
      dispatch(loadAccountsAction(organisationId));
    },
    [organisationId],
  );
};

export const useMoveAccountsToGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (groupId: AccountGroup['id'], accountIds: Account['account_id'][]) => {
      dispatch(moveAccountsToGroupAction(organisationId, groupId, accountIds));
    },
    [organisationId],
  );
};

export const useCreateSubGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (groupId: AccountGroup['id'], name: string, accountIds: Account['account_id'][]) => {
      dispatch(createSubGroupAction(organisationId, groupId, name, accountIds));
    },
    [organisationId],
  );
};

export const useRenameSubGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (subGroupId: AccountGroup['id'], name: string) => {
      dispatch(renameSubGroupAction(organisationId, subGroupId, name));
    },
    [organisationId],
  );
};

export const useReorderSubGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (groupId: AccountGroup['id'], orderedSubGroupIds: AccountGroup['id'][]) => {
      dispatch(reorderSubGroupAction(organisationId, groupId, orderedSubGroupIds));
    },
    [organisationId],
  );
};

export const useDeleteSubGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (subGroupId: AccountGroup['id']) => {
      dispatch(deleteSubGroupAction(organisationId, subGroupId));
    },
    [organisationId],
  );
};

export const useMoveAccountsToSubGroup = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(
    async (subGroupId: AccountGroup['id'], accountIds: Account['account_id'][]) => {
      dispatch(moveAccountsToSubGroupAction(organisationId, subGroupId, accountIds));
    },
    [organisationId],
  );
};

export const useCascadeAccountGroups = () => {
  const organisationId = useActiveOrganisationId();
  const dispatch = useDispatch();

  return useCallback(async () => {
    return dispatch(cascadeAccountGroupsAction(organisationId));
  }, [organisationId]);
};

export const useUngroupAccount = () => {
  const organisationId = useActiveOrganisationId();
  const moveAccountsToNativeMetric = useMoveAccountsToNativeMetric();
  const moveAccountsToGroup = useMoveAccountsToGroup();
  const accountsVS: AccountsVS = useAccounts(organisationId);
  const groupsVS: AccountGroupsVS = useAccountGroups();

  return useCallback(
    async (accountId: Account['account_id']) => {
      if (accountsVS.status !== 'success' || groupsVS.status !== 'success') {
        return;
      }
      const account: Account | undefined = accountsVS.value[accountId];
      if (!account) {
        return;
      }
      // If the account is not in a group, there is nothing to be done
      if (!account.group_id) {
        return;
      }
      const group: AccountGroup | undefined = groupsVS.value[account.group_id];
      if (!group) {
        return;
      }
      if (group.parent_group_id) {
        // If the group is a subgroup, move the account to the parent group
        return moveAccountsToGroup(group.parent_group_id, [accountId]);
      } else {
        // If the group is a group, move the account to the native metric
        return moveAccountsToNativeMetric(group.tag, [accountId]);
      }
    },
    [accountsVS.status],
  );
};
