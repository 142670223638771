import React from 'react';
import { User } from 'scalexp/components/contexts/DeprecatedUserContext/types';

import Message from '../../atoms/Message';
import { userContext } from '../DeprecatedUserContext';

export interface UserContextProps {}

export const UserContext = React.createContext<User>(undefined!);

export const UserProvider: React.FC<React.PropsWithChildren<UserContextProps>> = ({ children }) => {
  const user = React.useContext(userContext).user;

  if (!user) {
    return <Message type="error" description="Failed to load User" />;
  }

  // @ts-ignore
  window.initializeUserGuiding();
  // @ts-ignore
  window.userGuiding.identify(user?.user_id, {
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    job_title: user.job_title,
  });

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUserContext = () => {
  const value = React.useContext(UserContext);

  if (!value) {
    throw new Error('UserProvider not found!');
  }

  return value;
};
