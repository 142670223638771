import hash from 'object-hash';
import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';
import { CalculationRequestEntries, CalculationRequestEntry } from 'scalexp/utils/metrics/types';

import { TrackingCategoryOption } from '../../store/state/trackingCategories/types';
import fetcher from '../fetcher';

export type Request = {
  entries: CalculationRequestEntries;
  organisationId: number;
  date: string;
  trackingCategoryIds: TrackingCategoryOption['id'][] | null;
};

export type CalculationResultImpact = 'positive' | 'negative' | 'neutral';
export type CalculationResultDataType = 'monetary' | 'numerical' | 'percentage';
export type CalculationResultEntry = {
  value: string;
  data_type: CalculationResultDataType;
  impact: CalculationResultImpact;
} | null;
export type CalculationResult = CalculationResultEntry[];

export class CalculationRequestManager {
  public static readonly MAX_REQUESTS = 10;
  public static readonly FLUSH_AFTER_MS = 250;

  private static readonly _instance = new CalculationRequestManager();
  private static _flushTimeoutByEntriesHash: { [entriesHash: string]: NodeJS.Timeout } = {};

  // an object of the requests done and are on the useMetricSchemaSeries hook
  private static storedRequests: { [requestHash: string]: boolean } = {};

  private _queue: {
    [entriesHash: string]: {
      metricSchema: MetricSchema2;
      resolve: (value: CalculationResult) => void;
      reject: (reason?: any) => void;
    }[];
  } = {};
  private _requestsByEntriesHash: { [entriesHash: string]: Request } = {};

  public static makeRequest(metricSchema: MetricSchema2, request: Request): Promise<CalculationResult> {
    if (!Array.isArray(request.entries)) {
      throw new Error('request must be an array, this commonly happens when passing the old requests format');
    }

    const entriesHash = hash.sha1(request);

    if (!CalculationRequestManager._instance._queue[entriesHash]) {
      CalculationRequestManager._instance._queue[entriesHash] = [];
      CalculationRequestManager._instance._requestsByEntriesHash[entriesHash] = request;
    }

    const requestPromise: Promise<CalculationResult> = new Promise(function (resolve, reject) {
      CalculationRequestManager._instance._queue[entriesHash].push({
        metricSchema: metricSchema,
        resolve: resolve,
        reject: reject,
      });
    });
    CalculationRequestManager._instance._checkQueue(entriesHash);
    return requestPromise;
  }

  public static clearStoredRequests() {
    this.storedRequests = {};
  }

  private _checkQueue(entriesHash: string) {
    // Make request if queue is full
    if (this._queue[entriesHash].length >= CalculationRequestManager.MAX_REQUESTS) {
      this._sendRequest(entriesHash);
      if (CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash]) {
        clearTimeout(CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash]);
      }
      delete CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash];
      return;
    }

    // Otherwise recreate the flush timeout
    if (CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash]) {
      clearTimeout(CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash]!);
    }
    CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash] = setTimeout(() => {
      this._sendRequest(entriesHash);
      clearTimeout(CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash]);
      delete CalculationRequestManager._flushTimeoutByEntriesHash[entriesHash];
    }, CalculationRequestManager.FLUSH_AFTER_MS);
  }

  private async _sendRequest(entriesHash: string) {
    const request: Request = this._requestsByEntriesHash[entriesHash];
    const queuedMetricSchemas = this._queue[entriesHash];
    this._queue[entriesHash] = [];

    // If set, add the tracking_category_options to al the entries
    const entriesWithTrackingCategoryOptions = request.entries.map((entry: CalculationRequestEntry) => {
      if (request.trackingCategoryIds) {
        if (entry.source === 'variance') {
          return {
            ...entry,
            left: {
              ...entry.left,
              tracking_category_options: entry.left.source === 'actual' ? request.trackingCategoryIds : undefined,
            },
            right: {
              ...entry.right,
              tracking_category_options: entry.right.source === 'actual' ? request.trackingCategoryIds : undefined,
            },
          };
        } else {
          return {
            ...entry,
            tracking_category_options: entry.source === 'actual' ? request.trackingCategoryIds : undefined,
          };
        }
      }
      return entry;
    });

    // send requests
    try {
      const res: CalculationResult[] = await fetcher(`/api/v1/organisations/${request.organisationId}/calculation/`, {
        method: 'POST',
        body: JSON.stringify({
          focal_date: request.date,
          metric_schema: queuedMetricSchemas.map(request => request.metricSchema),
          entries: entriesWithTrackingCategoryOptions,
        }),
      });

      // Mark the requests as resolved
      queuedMetricSchemas.forEach((request, i) => request.resolve(res[i]));
    } catch (e) {
      // Mark the requests as rejected
      queuedMetricSchemas.forEach(request => request.reject(e));
      return;
    }
  }

  // sets the flag that a request is stored on the useMetricSchema hook
  public static setIsRequestStored(fullRequest: { metricSchema: MetricSchema2; request: Request }) {
    const requestHash = hash.sha1(fullRequest);
    CalculationRequestManager.storedRequests[requestHash] = true;
  }

  // the request is stored on the useMetricSchemaSeries
  public static isRequestStored(fullRequest: { metricSchema: MetricSchema2; request: Request }) {
    const requestHash = hash.sha1(fullRequest);
    return CalculationRequestManager.storedRequests[requestHash];
  }
}
