import { PayloadAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { ReportGrouping } from './types';

export const ACTION_REPORT_GROUPING_SET_SELECTION = '[reportGrouping] SET_SELECTION';
export const ACTION_REPORT_GROUPING_SET_CREATING_NEW_GROUP = '[reportGrouping] SET_CREATING_NEW_GROUP';
export const ACTION_REPORT_GROUPING_SET_SELECTED_LAYOUT = '[reportGrouping] SET_SELECTED_LAYOUT';
export const ACTION_REPORT_GROUPING_CLEAR_SELECTION = '[reportGrouping] CLEAR_SELECTION';

export type ReportGroupingActionSetSelection = PayloadAction<
  typeof ACTION_REPORT_GROUPING_SET_SELECTION,
  { selection: ReportGrouping['selection'] }
>;
export type ReportGroupingActionSetCreatingNewGroup = PayloadAction<
  typeof ACTION_REPORT_GROUPING_SET_CREATING_NEW_GROUP,
  { creatingNewGroup: ReportGrouping['creatingNewGroup'] }
>;
export type ReportGroupingActionSetSelectedLayout = PayloadAction<
  typeof ACTION_REPORT_GROUPING_SET_SELECTED_LAYOUT,
  { selectedLayout: ReportGrouping['selectedLayout'] }
>;
export type ReportGroupingActionClearSelection = PayloadAction<typeof ACTION_REPORT_GROUPING_CLEAR_SELECTION, {}>;

export type ReportGroupingActions =
  | ReportGroupingActionSetSelection
  | ReportGroupingActionSetCreatingNewGroup
  | ReportGroupingActionSetSelectedLayout
  | ReportGroupingActionClearSelection;

export const setReportGroupingSelectionAction = (
  selection: ReportGrouping['selection'],
): ThunkAction<ReportGroupingActionSetSelection> => async (dispatch: ThunkDispatch<ReportGroupingActions>) => {
  dispatch({ type: ACTION_REPORT_GROUPING_SET_SELECTION, params: { selection } });
};

export const setReportGroupingCreatingNewGroup = (
  creatingNewGroup: ReportGrouping['creatingNewGroup'],
): ThunkAction<ReportGroupingActionSetCreatingNewGroup> => async (dispatch: ThunkDispatch<ReportGroupingActions>) => {
  dispatch({ type: ACTION_REPORT_GROUPING_SET_CREATING_NEW_GROUP, params: { creatingNewGroup } });
};

export const setReportGroupingSelectedLayout = (
  selectedLayout: ReportGrouping['selectedLayout'],
): ThunkAction<ReportGroupingActionSetSelectedLayout> => async (dispatch: ThunkDispatch<ReportGroupingActions>) => {
  dispatch({ type: ACTION_REPORT_GROUPING_SET_SELECTED_LAYOUT, params: { selectedLayout } });
};

export const clearReportGroupingSelectionAction = (): ThunkAction<ReportGroupingActionClearSelection> => async (
  dispatch: ThunkDispatch<ReportGroupingActions>,
) => {
  dispatch({ type: ACTION_REPORT_GROUPING_CLEAR_SELECTION, params: {} });
};
