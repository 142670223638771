import { DefaultTheme } from 'styled-components';

export type TypographyColor =
  | 'black'
  | 'white'
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'warning'
  | 'grey'
  | 'inherit';

export const mapTypographyColor = (theme: DefaultTheme, color?: TypographyColor) => {
  if (color === 'black') {
    return `color: ${theme.palette.midnight};`;
  }
  if (color === 'white') {
    return `color: ${theme.palette.white};`;
  }
  if (color === 'grey') {
    return `color: ${theme.palette.fieldGrey};`;
  }
  if (color === 'primary') {
    return `color: ${theme.palette.primary.main};`;
  }
  if (color === 'secondary') {
    return `color: ${theme.palette.granite};`;
  }
  if (color === 'success') {
    return `color: ${theme.palette.success.main};`;
  }
  if (color === 'warning') {
    return `color: #BD5B00;`;
  }
  if (color === 'danger') {
    return `color: ${theme.palette.danger.main};`;
  }
  if (color === 'inherit') {
    return `color: inherit;`;
  }
};
