import React, { HTMLAttributes } from 'react';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import styled from 'styled-components';
import { css } from 'styled-components';

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  name?: string;
  size?: number;
  marginLeft?: number;
  marginRight?: number;
  outlined?: boolean;
  rounded?: boolean;
  clickable?: boolean;
  tooltipText?: string;
}

export const StyledIcon = styled.span<IconProps>`
  ${({ theme, size = 4, marginRight = 1, marginLeft = 0, color = '#000000', clickable }) => {
    return css`
      font-size: ${theme.sizing(size)};
      user-select: none;
      line-height: ${theme.sizing(size - 1)};
      vertical-align: sub;
      color: ${color};
      margin-right: ${marginRight ? theme.spacing(marginRight) : '0px'};
      margin-left: ${marginLeft ? theme.spacing(marginLeft) : '0px'};
      margin-top: auto;
      margin-bottom: auto;
      max-width: ${theme.sizing(size)};
      text-align: center;
      ${clickable
        ? css`
            cursor: pointer;
            &:hover {
              color: ${color === theme.palette.granite ? theme.palette.primary.light : theme.palette.granite};
            }
          `
        : ''}
    `;
  }};
`;

const Icon: React.FC<React.PropsWithChildren<IconProps>> = ({
  name,
  clickable,
  outlined,
  rounded,
  className,
  tooltipText = '',
  ...other
}) => {
  if (!name) {
    return null;
  }
  const trimmedIconName = name?.replaceAll?.(/(_round|_outlined)$/gi, '');
  return (
    <Tooltip content={tooltipText}>
      <StyledIcon
        clickable={clickable}
        className={`${className ? className : ''} ${
          outlined ? 'material-icons-outlined' : rounded ? 'material-icons-round' : 'material-icons'
        }`}
        name={name}
        {...other}
      >
        {trimmedIconName}
      </StyledIcon>
    </Tooltip>
  );
};

export default Icon;
