import { useCallback } from 'react';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { useTrialInfoContext } from 'scalexp/components/contexts/TrialContext';
import useGetAccountingAndSalesSystem from 'scalexp/features/connections/ConnectionsContext/useGetAccountingAndSalesSystem';
import { FEATURE_FLAGS, useFeatureFlagChecker } from 'scalexp/service/feature-flags';
import { getDecodedAccessToken } from 'scalexp/utils/accessToken';

const NAMESPACE = 'https://scalexp.com' as const;
const GUEST_MEMBERSHIP = 'Guest' as const;

const RESOURCES = {
  REPORTS: 'reports',
  DASHBOARDS: 'dashboards',
  PRESENTATIONS: 'presentations',
  FEATURE_RESOURCES: 'featureresources',
} as const;

export type Resource = typeof RESOURCES[keyof typeof RESOURCES];

export const PERMISSIONS = {
  READ_ALL: 'read:all',
  WRITE_ALL: 'write:all',
  READ_DATA: 'read:data',
  ...RESOURCES,
  READ_CUSTOMER_PAGES: 'read:customer_pages',
  WRITE_CUSTOMER_PAGES: 'write:customer_pages',
  COST: 'cost',
  ACCRUALS: 'accruals',
  JOURNALS: 'journals',
} as const;

export type Permission = typeof PERMISSIONS[keyof typeof PERMISSIONS];

type PermissionChecker = (permission: Permission) => boolean;

const getAccessTokenPermissions = (): string[] => getDecodedAccessToken()?.permissions || [];
const getAccessTokenResources = (resource: Resource): string[] =>
  getDecodedAccessToken()?.[`${NAMESPACE}/${resource}`] || [];
const getMembership = (): string => getDecodedAccessToken()?.[`${NAMESPACE}/membership`] || '';

export const isGuest = (): boolean => getMembership() === GUEST_MEMBERSHIP;

/**
 * Check if the user has the given permission.
 * Returns true if the user has the permission, false if the user does not have the permission,
 * and undefined if additional checks required.
 * @param permission
 */
export const hasPermission = (permission: Permission): boolean | undefined => {
  const guest = isGuest();
  switch (permission) {
    case PERMISSIONS.READ_ALL:
    case PERMISSIONS.WRITE_ALL:
      return getAccessTokenPermissions().includes(permission);

    case PERMISSIONS.REPORTS:
    case PERMISSIONS.DASHBOARDS:
    case PERMISSIONS.PRESENTATIONS:
    case PERMISSIONS.FEATURE_RESOURCES:
      return !guest || getAccessTokenResources(permission).length > 0;

    case PERMISSIONS.READ_CUSTOMER_PAGES:
    case PERMISSIONS.WRITE_CUSTOMER_PAGES:
      return guest ? getAccessTokenResources(PERMISSIONS.FEATURE_RESOURCES).includes(permission) : undefined;

    default:
      throw new Error(`Unsupported permission: ${permission}. Maybe you need to use useHasPermissions.`);
  }
};

export const useHasWriteAllPermission = (): boolean => hasPermission(PERMISSIONS.WRITE_ALL) || false;

export const useUserHasResourcePermission = (): [string, string] | [null, null] => {
  const hasWriteAllPermission = useHasWriteAllPermission();
  if (hasWriteAllPermission) {
    return [null, null];
  }

  for (const resource of Object.values(RESOURCES)) {
    const resources = getAccessTokenResources(resource);
    if (!resources.length) {
      continue;
    }
    switch (resource) {
      case RESOURCES.REPORTS:
        return ['/reports', `/reports/${resources[0]}`];
      case RESOURCES.DASHBOARDS:
        return ['/dashboards', `/dashboards/${resources[0]}`];
      case RESOURCES.PRESENTATIONS:
        return ['/presentations', resources.length === 1 ? `/presentations/${resources[0]}/edit` : '/presentations'];
      case RESOURCES.FEATURE_RESOURCES:
        if (resources.includes(PERMISSIONS.READ_CUSTOMER_PAGES)) {
          return ['/analysis', '/analysis'];
        } else {
          throw new Error(`Unsupported general resource: ${resources}`);
        }
      default:
        throw new Error(`Unsupported resource: ${resource}`);
    }
  }

  return [null, null];
};

export const useHasPermissionChecker = (): PermissionChecker => {
  const featureFlagChecker = useFeatureFlagChecker();
  const activeOrganisation = useActiveOrganisation();
  const { trialInfo } = useTrialInfoContext();
  const { salesSystem } = useGetAccountingAndSalesSystem();

  return useCallback(
    (permission: Permission): boolean => {
      const hasReadAllPermission = hasPermission(PERMISSIONS.READ_ALL) || false;
      const hasWriteAllPermission = hasPermission(PERMISSIONS.WRITE_ALL) || false;
      const hasCrmConnection = !!salesSystem;
      const guest = isGuest();
      const isParentOrganisation = activeOrganisation.consolidation_type === 'PARENT';
      const isConsolidatedCustomerPageEnabled = !!activeOrganisation.enable_consolidated_customers_page;

      switch (permission) {
        case PERMISSIONS.READ_CUSTOMER_PAGES:
        case PERMISSIONS.WRITE_CUSTOMER_PAGES:
          if (hasPermission(permission)) {
            return true;
          }
          const consolidatedHasAccessToCustomer =
            isParentOrganisation && trialInfo?.status === 'active' && isConsolidatedCustomerPageEnabled;

          const basePermission =
            permission === PERMISSIONS.READ_CUSTOMER_PAGES ? hasReadAllPermission : hasWriteAllPermission;

          return basePermission && (!isParentOrganisation || consolidatedHasAccessToCustomer);
        case PERMISSIONS.COST:
          return hasWriteAllPermission && !isParentOrganisation && !!activeOrganisation.enable_prepayments;
        case PERMISSIONS.ACCRUALS:
          return (
            hasReadAllPermission &&
            (isConsolidatedCustomerPageEnabled || !isParentOrganisation || hasCrmConnection) &&
            featureFlagChecker(FEATURE_FLAGS.ACCRUALS)
          );
        case PERMISSIONS.JOURNALS:
          return !guest && !isParentOrganisation;
        default:
          return hasPermission(permission) || false;
      }
    },
    [featureFlagChecker, activeOrganisation, trialInfo, salesSystem],
  );
};

export const useHasPermission = (permission: Permission): boolean => useHasPermissionChecker()(permission);
