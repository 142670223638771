import React, { useState } from 'react';
import Typography from 'scalexp/components/atoms/Typography';
import Column from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';
import FormulaEditorModal from 'scalexp/components/organisms/FormulaEditorModal';
import ChartEditorAddRowSelect from 'scalexp/features/chart-editor/ChartEditorAddRowSelect';
import { pipelineMetricOptions, stageMetricOptions } from 'scalexp/features/chart-editor/TimeChartSeries/options';
import { useChartDeferredRevenueConfigOptions, useChartMetricsOptions } from 'scalexp/features/chart/ChartCard/hooks';
import { Chart, ChartCommonProperties, KPIChart } from 'scalexp/features/chart/ChartCard/types';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import { DashboardChartCard } from 'scalexp/store/state/newDashboards/types';
import { PipelineMetricsTypes, PipelineStageMetricsTypes } from 'scalexp/store/state/pipelines/types';
import {
  MetricSchema2Complex,
  MetricSchema2Data,
  MetricSchema2DataSourceEntityPipeline,
  MetricSchema2DataSourceEntityPipelineStage,
  MetricSchema2DataSourceInvoicedRevenue,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import { v4 } from 'uuid';

import Select from '../Select';

interface KpiChartPlaceholderPropsType {
  card: DashboardChartCard;
  handleUpdateChartData: (chart: Chart) => Promise<void>;
}

const KpiChartPlaceholder: React.FC<KpiChartPlaceholderPropsType> = ({ card, handleUpdateChartData }) => {
  const [optionSelected, setOptionSelected] = useState('');
  const [chartWithSecondarySelect, setChartWithSecondarySelect] = useState<KPIChart | undefined>();

  const [secondarySelectType, setSecondarySelectType] = useState<
    'pipeline' | 'stage' | 'sales' | 'invoiced-revenue' | undefined
  >(undefined);
  const [showFormulaEditorModal, setShowFormulaEditorModal] = React.useState(false);

  const [configureDetails, setConfigureDetails] = React.useState<null | {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => void;
  }>(null);

  const normalizedPickerOptions = useChartMetricsOptions(false);

  const configOptions = useChartDeferredRevenueConfigOptions();

  const updateKpiChartData = async (chart: KPIChart) => {
    const metric = ((chart as KPIChart).chart_data.metric_schema.nodes[0] as MetricSchema2Data).data;

    if (chart.chart_data.metric_schema.schemaType !== 'complex') {
      switch (metric.operator) {
        case 'invoiced-revenue':
          if (metric.configId === undefined) {
            setChartWithSecondarySelect(chart);
            setSecondarySelectType('invoiced-revenue');

            return;
          }
          if (metric.configId === null) {
            delete (((chart as KPIChart).chart_data.metric_schema.nodes[0] as MetricSchema2Data)
              .data as MetricSchema2DataSourceInvoicedRevenue).configId;
          }
          setSecondarySelectType(undefined);
          await handleUpdateChartData(chart);
          break;
        case 'entity':
          if (metric.entity === 'pipeline' && !metric.pipelineMetricId) {
            setSecondarySelectType('pipeline');
            setChartWithSecondarySelect(chart);
            return;
          }
          if (metric.entity === 'stage' && !metric.stageMetricId) {
            setSecondarySelectType('stage');
            setChartWithSecondarySelect(chart);
            return;
          }
          setSecondarySelectType(undefined);
          await handleUpdateChartData(chart);

          break;

        default:
          setSecondarySelectType(undefined);
          await handleUpdateChartData(chart);

          break;
      }
    }
  };

  const handleCustomFormulaClicked = () => {
    setConfigureDetails({
      metricSchema: (((chartWithSecondarySelect as KPIChart)?.chart_data.metric_schema ?? {
        nodes: [],
        schemaType: 'complex',
        decimals: 0,
        dataType: 'monetary',
        impact: 'neutral',
        aggregation: 'sum',
      }) as any) as MetricSchema2Complex,
      updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => {
        const newChart: KPIChart = {
          ...(card as DashboardChartCard & ChartCommonProperties),
          chart_type: 'kpi_chart',
          chart_data: {
            id: v4(),
            period: 'MONTH',
            versus: 'LAST',
            versus_type: 'PERCENTAGE',
            value_basis: 'ACTUAL',
            metric_schema: updatedMetricSchema,
          },
          header_config: null,
          name: 'Custom KPI',
        };

        updateKpiChartData(newChart);
      },
    });
    setShowFormulaEditorModal(true);
  };

  const handleSelectMetric = (newSimpleMetricSchema: MetricSchema2Simple, valueSelected: string | null) => {
    setOptionSelected(valueSelected!);

    // avoid default stageMetricId and pipelineMetricId values
    if ('pipelineMetricId' in newSimpleMetricSchema.nodes[0].data) {
      newSimpleMetricSchema.nodes[0].data.pipelineMetricId = '' as PipelineMetricsTypes;
    }
    if ('stageMetricId' in newSimpleMetricSchema.nodes[0].data) {
      newSimpleMetricSchema.nodes[0].data.stageMetricId = '' as PipelineStageMetricsTypes;
    }

    updateKpiChartData({
      chart_type: 'kpi_chart',
      chart_data: {
        id: v4(),
        period: 'MONTH',
        versus: 'LAST',
        versus_type: 'PERCENTAGE',
        value_basis: 'ACTUAL',
        metric_schema: newSimpleMetricSchema,
      },
      header_config: null,
      name: normalizedPickerOptions.find(option => option.value === valueSelected)?.label,
    } as KPIChart);
  };

  const handleUpdatePipelineMetric = (value: string) => {
    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: {
            ...(chartWithSecondarySelect!.chart_data.metric_schema.nodes[0] as MetricSchema2Data).data,
            entity: 'pipeline',
            pipelineMetricId: value,
          } as MetricSchema2DataSourceEntityPipeline,
        },
      ],
    };
    updateKpiChartData?.({
      ...chartWithSecondarySelect,
      chart_data: {
        ...chartWithSecondarySelect!.chart_data,
        metric_schema: metric,
      },
    } as KPIChart);
  };

  const handleUpdateStageMetric = (value: string | null) => {
    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: {
            ...(chartWithSecondarySelect!.chart_data.metric_schema
              .nodes[0] as MetricSchema2Data<MetricSchema2DataSourceEntityPipelineStage>).data,
            stageMetricId: value as PipelineStageMetricsTypes,
          },
        },
      ],
    };
    updateKpiChartData?.({
      ...chartWithSecondarySelect,
      chart_data: {
        ...chartWithSecondarySelect!.chart_data,
        metric_schema: metric,
      },
    } as KPIChart);
  };

  const handleUpdateInvoicedRevenueConfig = (value: DeferredRevenueConfig['id'] | null) => {
    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: {
            operator: 'invoiced-revenue',
            metricId: (chartWithSecondarySelect!.chart_data.metric_schema
              .nodes[0] as MetricSchema2Data<MetricSchema2DataSourceInvoicedRevenue>).data.metricId,
            configId: value!,
          },
        },
      ],
    };
    updateKpiChartData?.({
      ...chartWithSecondarySelect,
      chart_data: {
        ...chartWithSecondarySelect!.chart_data,
        metric_schema: metric,
      },
    } as KPIChart);
  };

  return (
    <Column spacing="tiny" height="100%" width="100%" hAlign="flex-start" vAlign="flex-start">
      <Column hAlign="end" vAlign="flex-start" height="38px" width="100%">
        <img src="/images/illustrations/kpi-placeholder.svg" alt="KPI placeholder" />
      </Column>
      <Column width="100%" hAlign="flex-start" spacing="tiny">
        <Typography size="medium" weight="regular">
          Please select a KPI
        </Typography>
        <Row width="100%">
          <ChartEditorAddRowSelect
            width={'100%'}
            selectWidth={655}
            includeFixedNumber={false}
            includeBrackets={false}
            placeholder="Select a KPI"
            onChange={handleSelectMetric}
            handleFormulaCategorySelected={handleCustomFormulaClicked}
            value={optionSelected}
          />
        </Row>
        {secondarySelectType === 'invoiced-revenue' && (
          <Row hAlign="space-between" vAlign="center" width="100%">
            <Select
              width="100%"
              size="small"
              selectWidth={800}
              data={configOptions}
              value={''}
              onChange={handleUpdateInvoicedRevenueConfig}
              placeholder={'Select deferred configuration'}
              onClick={e => e.stopPropagation()}
            />
          </Row>
        )}
        {secondarySelectType === 'pipeline' && (
          <Row width="100%" hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a pipeline metric'}
              selectHeight={300}
              selectWidth={338}
              data={pipelineMetricOptions}
              onChange={handleUpdatePipelineMetric}
            />
          </Row>
        )}
        {secondarySelectType === 'stage' && (
          <Row width="100%" hAlign="space-between" vAlign="center">
            <Select
              size="medium"
              width="100%"
              placeholder={'Select a stage metric'}
              selectHeight={300}
              selectWidth={338}
              data={stageMetricOptions}
              onChange={handleUpdateStageMetric}
            />
          </Row>
        )}
      </Column>
      {showFormulaEditorModal && configureDetails != null && (
        <FormulaEditorModal configureDetails={configureDetails} onHide={() => setShowFormulaEditorModal(false)} />
      )}
    </Column>
  );
};

export default KpiChartPlaceholder;
