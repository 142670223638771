import { Store } from 'scalexp/store/state/types';

import { DeferredAccountMappingsVS } from './types';

export const selectDeferredAccountMappings = (
  state: Store,
  organisationId: number,
): DeferredAccountMappingsVS | undefined => {
  return state.deferredAccountMapping[organisationId];
};
