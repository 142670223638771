import { ValueBasis } from 'scalexp/components/organisms/MetricSchemaChart/types';
import { HSL } from 'scalexp/features/report/rows/ReportRowCell';
import { TrackingCategoryOption } from 'scalexp/store/state/trackingCategories/types';
import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';

import { ValueState } from '../../../values';
import { OrganisationData } from '../../types';

export type ReportId = string;

export type Column = SeparatorColumn | AggregateColumn | ActualsPlusBudgetColumn | VarianceColumn;

export type ActualsPlusBudgetColumn = {
  id: string;
  name: string;
  postfix?: string;
  highlight?: boolean;
  type: 'ACTUALS_PLUS_BUDGET';
  budget_set_id?: number | null;
  aggregate_over: AggregationParams;
};

export type VarianceColumn = {
  id: string;
  name: string;
  postfix?: string;
  highlight?: boolean;
  type: 'VARIANCE' | 'VARIANCE_PERCENTAGE';
  variance_of: { left_column_id: string; right_column_id: string };
};

export type SeparatorColumn = { id: string; name: string; type: 'SEPARATOR' };

export type AggregateColumn = {
  id: string;
  name: string;
  postfix?: string;
  type: 'AGGREGATION';
  highlight?: boolean;
  aggregate_over: AggregationParams;
};

export type AggregationParams = {
  period: AggregationPeriod;
  relative_month_offset: number;
  budget_set_id?: number | null;
  value_basis: ValueBasis;
  entity?: string | string[];
  tracking_category_option_ids?: string[];
};

export type AggregationPeriod =
  | 'MONTH'
  | 'YEAR'
  | 'YTD'
  | 'QTD'
  | 'QUARTER'
  | 'QUARTER_REMAINING'
  | 'YEAR_REMAINING'
  | 'CALENDAR_YEAR';

export type Columns = Array<Column>;

export type Row = EmptyRow | MetricRow;
export type EmptyRow = CommonRowProperties & {
  type: 'EMPTY';
};
export type MetricRow = CommonRowProperties & {
  type: 'metric';
  metric: MetricSchema2;
};
export type CommonRowProperties = {
  id: string;
  name?: string;
  show_groups?: boolean;
} & StyleRowProperties;
export type StyleRowProperties = {
  bold: boolean;
  color?: HSL;
  border_top?: BorderStyle;
  border_bottom?: BorderStyle;
};
export type BorderStyle = 'SOLID' | 'DOUBLE' | 'NONE';

export enum BorderStyleEnum {
  NONE = 'none',
  SOLID = 'solid',
  DOUBLE = 'double',
  SUBTLE = 'subtle',
}

export enum RowHeight {
  SMALL = 'small',
}

export type Report = {
  id: ReportId;
  name: string;
  pinned: boolean;
  currency?: string;
  rows: Array<Row>;
  column_collection_id: number;
  implicitly_accessible: boolean;
  columns: Columns;
  tracking_enabled?: boolean;
  tracking_category_options: TrackingCategoryOption['id'][];
  category: Category;
  updated_at: string;
  created_at: string;
};

export type Category = 'BALANCE_SHEET' | 'CASH_FLOW' | 'PROFIT_AND_LOSS' | 'SAAS_METRICS' | 'CUSTOM';

export type Reports = { [reportId: number]: Report };

export type ReportVS = ValueState<Report>;

export type ReportsVS = ValueState<{ [reportId: number]: Report }>;

export type OrganisationReports = OrganisationData<ReportsVS>;
