import dayjs, { Dayjs } from 'dayjs';
import { MetricSchema2DataType, MetricSchema2Simple } from 'scalexp/utils/metrics/metricSchema2';

import { AggregateColumn, Report } from '../../../store/state/reports/types';

export function generateTableConfig(
  date: string,
  focalDate: Dayjs,
  metricSchema: MetricSchema2Simple,
  period: string = 'MONTH',
  financialYearStart: number,
  valueBasis: string,
  budgetId?: number,
): Omit<Report, 'id'> {
  const data = metricSchema.nodes[0];

  let multiplier = 1;
  let monthOffset = dayjs(date).diff(focalDate, 'M');

  if (period === 'QUARTER') {
    multiplier = 3;
    let [year, quarterStr] = date.split('-');
    let [, quarter] = quarterStr.split('Q');
    let quarterDate = `${year}-${Number(quarter) * 3}`;
    monthOffset = dayjs(quarterDate).diff(focalDate, 'M') / multiplier;
  }
  if (period === 'YEAR' || period === 'YEAR_TO_DATE') {
    multiplier = 12;
    monthOffset = (monthOffset + financialYearStart) / multiplier;
  }
  let windowColumns: AggregateColumn[] = [];
  const windowData = data.window;
  const isMonthly = !period || period === null || period === 'NONE' || period === 'MONTH';

  if (windowData && isMonthly) {
    let before = windowData.before;
    let after = windowData.after;
    let monthsBack;
    if (after < 0 && before >= 0) {
      monthsBack = Math.abs(before - Math.abs(after)) + 1;
      monthOffset = monthOffset - Math.abs(after);
    } else {
      monthsBack = Math.abs(before - after) + 1;
    }

    windowColumns = Array(monthsBack)
      .fill(null)
      .map((_, index) => {
        return {
          id: String(index),
          name: '',
          type: 'AGGREGATION',
          aggregate_over: {
            relative_month_offset: monthOffset - index,
            value_basis: valueBasis,
            period: period === 'YEAR_TO_DATE' ? 'YTD' : period,
            budget_set_id: budgetId,
          },
        } as AggregateColumn;
      })
      .reverse();

    // if we are getting a value from months ago
    if (before + after === 0) {
      windowColumns = [
        {
          id: '0',
          name: '',
          type: 'AGGREGATION',
          aggregate_over: {
            relative_month_offset: after,
            value_basis: valueBasis,
            budget_set_id: budgetId,
            period: period === 'YEAR_TO_DATE' ? 'YTD' : period,
          },
        } as AggregateColumn,
      ];
    }
    const { window, ...dataWithoutWindow } = data;
    return {
      ...EMPTY_REPORT_CONFIG,
      columns: windowColumns,
      rows: [
        {
          id: 'metric',
          show_groups: true,
          name: '',
          type: 'metric',
          metric: {
            schemaType: 'simple',
            decimals: 0,
            nodes: [dataWithoutWindow],
          },
          bold: true,
        },
      ],
    };
  }
  return {
    ...EMPTY_REPORT_CONFIG,
    columns: [
      {
        id: '0',
        name: '',
        type: 'AGGREGATION',
        aggregate_over: {
          relative_month_offset: monthOffset * multiplier,
          value_basis: valueBasis,
          budget_set_id: budgetId,
          period: period === 'YEAR_TO_DATE' ? 'YTD' : period,
        },
      } as AggregateColumn,
    ],
    rows: [
      {
        id: 'metric',
        show_groups: true,
        name: '',
        type: 'metric',
        metric: {
          schemaType: 'simple',
          decimals: 0,
          nodes: [data],
        },
        bold: true,
      },
    ],
  };
}

export const EMPTY_REPORT_CONFIG: Omit<Report, 'id'> = ({
  name: '',
  column_collection_id: null,
  columns: [],
  rows: [],
  tracking_enabled: false,
  tracking_category_options: [],
} as unknown) as Omit<Report, 'id'>;

export const formatValue = (
  value: number | null,
  type: MetricSchema2DataType,
  currency: string | null,
  decimalPlaces?: number,
): string => {
  let formattedValue: string;
  currency = currency ?? 'GBP';
  if (!Number.isFinite(value) || value === null) {
    value = 0;
  }
  if (type?.toLowerCase() === 'monetary') {
    formattedValue = `${new Intl.NumberFormat('en-GB', {
      style: 'currency',
      minimumFractionDigits: decimalPlaces ?? 0,
      maximumFractionDigits: decimalPlaces ?? 0,
      currencyDisplay: currency === 'USD' ? 'narrowSymbol' : 'symbol',
      currency: currency,
    }).format(value)}`;
    if (currency === 'SGD') {
      formattedValue = formattedValue.replace('SGD', 'SG$');
    } else if (currency === 'ZAR') {
      formattedValue = formattedValue.replace('ZAR', 'R');
    }
  } else if (type?.toLowerCase() === 'percentage') {
    formattedValue = `${
      decimalPlaces !== 0 && decimalPlaces ? (value * 100).toFixed(decimalPlaces) : Math.round(value * 100)
    }%`;
  } else if (['numerical', 'integer'].includes(type?.toLowerCase()) && !decimalPlaces) {
    formattedValue = `${Math.round(value)}`;
  } else {
    formattedValue = value.toLocaleString('en-GB', {
      minimumFractionDigits: decimalPlaces ?? 2,
      maximumFractionDigits: decimalPlaces ?? 2,
    });
  }
  return formattedValue;
};
