import { AccrualStatus } from 'scalexp/components/organisms/CustomerInvoiceLine/useCustomerInvoices';
import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { PrepaymentStatus } from '../../../components/organisms/CustomerInvoiceLine/useCustomerBills';
import { promiseAction } from '../../actions';
import http from '../../http';
import { Account } from '../accounts/types';
import { selectCustomers } from './selectors';
import { Customer } from './types';

export const ORGANISATIONS_CUSTOMERS_LOAD = '[customers] LOAD_CUSTOMERS';
export const ORGANISATIONS_CUSTOMERS_MERGE = '[customers] MERGE_CUSTOMERS';
export const ORGANISATIONS_CUSTOMERS_UNMERGE = '[customers] UNMERGE_CUSTOMERS';
export const ORGANISATIONS_CUSTOMERS_UPDATE_CUSTOMER = '[customers] UPDATE_CUSTOMER';
export const ORGANISATIONS_CUSTOMERS_UPDATE_INVOICE = '[customers] UPDATE_INVOICE';
export const ORGANISATIONS_CUSTOMERS_UPDATE_BILL = '[customers] UPDATE_BILL';
export const ORGANISATIONS_CUSTOMERS_REVIEW_BILL = '[customers] REVIEW_BILL';
export const ORGANISATIONS_CUSTOMERS_REVIEW_BILLS = '[customers] REVIEW_BILLS';
export const ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_CUSTOMER = '[customers] CREATE_MANUAL_CUSTOMER';
export const ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_INVOICE = '[customers] CREATE_MANUAL_INVOICE';
export const ORGANISATIONS_CUSTOMERS_UPDATE_MANUAL_INVOICE = '[customers] UPDATE_MANUAL_INVOICE';
export const ORGANISATIONS_CUSTOMERS_DELETE_MANUAL_INVOICE = '[customers] DELETE_MANUAL_INVOICE';
export const ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_BILL = '[customers] CREATE_MANUAL_BILL';
export const ORGANISATIONS_CUSTOMERS_UPDATE_MANUAL_BILL = '[customers] UPDATE_MANUAL_BILL';
export const ORGANISATIONS_CUSTOMERS_DELETE_MANUAL_BILL = '[customers] DELETE_MANUAL_BILL';

export type CustomersLoadAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_LOAD,
  Customer[],
  { organisationId: number }
>;

export type CustomersMergeAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_MERGE,
  Customer,
  {
    organisationId: number;
    customer: string;
    onto: string;
  }
>;

export type CustomersUnmergeAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_UNMERGE,
  Customer,
  {
    organisationId: number;
    customer: string;
    SXPCustomer: string;
    source: 'xero' | 'quickbooks' | 'codat' | 'manual';
  }
>;

export type CustomersCreateManualCustomerAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_CUSTOMER,
  CreateManualCustomerActionResult,
  {
    organisationId: number;
    customer: CreateManualCustomerPayload;
  }
>;

export type CustomersUpdateCustomerAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_UPDATE_CUSTOMER,
  Customer,
  {
    organisationId: number;
    customerId: string;
    name?: string;
    is_excluded?: boolean;
  }
>;

export type CustomersCreateManualInvoiceAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_INVOICE,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersUpdateManualInvoiceAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_UPDATE_MANUAL_INVOICE,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersDeleteManualInvoiceAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_DELETE_MANUAL_INVOICE,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersUpdateBillAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_UPDATE_BILL,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersCreateManualBillAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_BILL,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersUpdateManualBillAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_UPDATE_MANUAL_BILL,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersDeleteManualBillAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_DELETE_MANUAL_BILL,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type MarkBillReviewedAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_REVIEW_BILL,
  void,
  {
    organisationId: number;
    billType: 'bill' | 'credit_note';
    billId: string;
  }
>;
export type MarkCustomerBillsReviewedAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_REVIEW_BILLS,
  void,
  {
    organisationId: number;
    customerId: string;
    bills: string[];
    billCreditNotes: string[];
  }
>;

export type CustomersUpdateInvoiceAction = PromiseAction<
  typeof ORGANISATIONS_CUSTOMERS_UPDATE_INVOICE,
  void,
  {
    organisationId: number;
    name: string;
  }
>;

export type CustomersActions =
  // Customers actions
  | CustomersLoadAction
  | CustomersMergeAction
  | CustomersUnmergeAction
  | CustomersUpdateCustomerAction
  // Any bill/invoice
  | CustomersUpdateInvoiceAction
  | CustomersUpdateBillAction
  // Any bill
  | MarkBillReviewedAction
  | MarkCustomerBillsReviewedAction
  // Manual customers
  | CustomersCreateManualCustomerAction
  // Manual invoices
  | CustomersCreateManualInvoiceAction
  | CustomersUpdateManualInvoiceAction
  | CustomersDeleteManualInvoiceAction
  // Manual bills
  | CustomersCreateManualBillAction
  | CustomersUpdateManualBillAction
  | CustomersDeleteManualBillAction;

type LoadCustomerResponse = { customers: Customer[] };

export const loadCustomersByIdsAction = (
  organisationId: number,
  customerIds: string[],
): ThunkAction<CustomersActions> => async (dispatch: ThunkDispatch<CustomersActions>, getState) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending' || customerIds.length === 0) {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_LOAD,
        () =>
          http<Customer[], LoadCustomerResponse>(
            `/api/v1/organisations/${organisationId}/customers_by_ids`,
            {
              body: {
                customer_ids: customerIds,
              },
            },
            response => response.customers,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

type MergeCustomerResponse = { customers: Customer[] };

export const mergeCustomersAction = (
  organisationId: number,
  customer: string,
  onto: string,
): ThunkAction<CustomersActions> => async (dispatch: ThunkDispatch<CustomersActions>, getState) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_MERGE,
        () =>
          http<Customer[], MergeCustomerResponse>(
            `/api/v1/organisations/${organisationId}/customers/merge/${customer}/${onto}`,
            {
              method: 'POST',
            },
          ),
        {
          organisationId,
          customer,
          onto,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const unmergeCustomerAction = (
  organisationId: number,
  customer: string,
  source: string,
  SXPCustomer: string,
): ThunkAction<CustomersActions> => async (dispatch: ThunkDispatch<CustomersActions>, getState) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_UNMERGE,
        () =>
          http<Customer[], CustomersUnmergeAction>(
            `/api/v1/organisations/${organisationId}/customers/unmerge/${customer}/${source}`,
            {
              method: 'POST',
            },
          ),
        {
          organisationId,
          customer,
          source,
          SXPCustomer,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

type CreateCustomerResponse = Customer;

export type CreateManualCustomerPayload = {
  name: string;
  role: 'customer' | 'supplier';
};

export type ManualInvoiceLine = {
  start_date: string;
  end_date: string;
  amount: number;
  description: string;
  account: Account['account_id'];
};

export type CreateManualInvoicePayload = {
  invoice_number: string;
  issue_date: string;
  customerId: Customer['id'];
  lines: ManualInvoiceLine[];
};

export type UpdateManualInvoicePayload = CreateManualInvoicePayload & { id: string };

export type UpdateInvoicePayload = {
  id: string;
  invoiceType: 'Invoice' | 'InvoiceCreditNote';
  customerId?: string;
  lines?: {
    invoiceLineId: string;
    manualStartDate?: string;
    manualEndDate?: string;
    accrual_status?: AccrualStatus;
  }[];
  renewal_status?: any;
};

export type CreateManualBillPayload = {
  invoiceNumber: string;
  accountId: Account['account_id'];
  supplierId: Customer['id'];
  amount: number;
  startDate: string;
  endDate: string;
};

export type UpdateManualBillPayload = CreateManualBillPayload & {
  id: string;
};

export type UpdateBillPayload = {
  id: string;
  bill_type: 'Bill' | 'BillCreditNote';
  lines: {
    bill_line_id: string;
    manual_start_date?: string;
    manual_end_date?: string;
    manual_account_id?: number;
    prepaymentStatus?: PrepaymentStatus;
  }[];
};

type Result<T> =
  | {
      success: false;
    }
  | {
      success: true;
      data: T;
    };

export type CreateManualCustomerActionResult = Result<Customer>;

export const createManualCustomersAction = (
  organisationId: number,
  customer: CreateManualCustomerPayload,
): ThunkAction<CustomersCreateManualCustomerAction, Promise<CreateManualCustomerActionResult>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    return {
      success: true,
      data: await dispatch(
        promiseAction(
          ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_CUSTOMER,
          () =>
            http<Customer, CreateCustomerResponse>(`/api/v1/organisations/${organisationId}/customers`, {
              method: 'POST',
              body: customer,
            }),
          {
            organisationId,
            customer,
          },
        ),
      ),
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const updateCustomerAction = (
  organisationId: number,
  customerId: Customer['id'],
  name?: Customer['name'],
  isExcluded?: Customer['is_excluded'],
): ThunkAction<CustomersUpdateCustomerAction> => async (dispatch: ThunkDispatch<CustomersActions>) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_UPDATE_CUSTOMER,
        () =>
          http<Customer, Customer>(`/api/v1/organisations/${organisationId}/customers/${customerId}`, {
            method: 'PATCH',
            body: {
              name,
              is_excluded: isExcluded,
            },
          }),
        {
          organisationId,
          customerId,
          name,
          isExcluded,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const customerCreateManualInvoiceAction = (
  organisationId: number,
  invoice: CreateManualInvoicePayload,
): ThunkAction<CustomersCreateManualInvoiceAction, Promise<any>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    let response = await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_CUSTOMER,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/invoices`, {
            method: 'POST',
            body: {
              invoice_number: invoice.invoice_number,
              issue_date: invoice.issue_date,
              customer_id: invoice.customerId,
              lines: invoice.lines,
            },
          }),
        {
          organisationId,
          invoice,
        },
      ),
    );
    return response;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const customerUpdateManualInvoiceAction = (
  organisationId: number,
  invoice: UpdateManualInvoicePayload,
): ThunkAction<CustomersCreateManualInvoiceAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_UPDATE_MANUAL_INVOICE,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/invoices/${invoice.id}`, {
            method: 'PUT',
            body: {
              invoice_number: invoice.invoice_number,
              issue_date: invoice.issue_date,
              customer_id: invoice.customerId,
              lines: invoice.lines,
            },
          }),
        {
          organisationId,
          invoice,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const customerDeleteManualInvoiceAction = (
  organisationId: number,
  invoice: Pick<UpdateManualInvoicePayload, 'id'>,
): ThunkAction<CustomersDeleteManualInvoiceAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_DELETE_MANUAL_INVOICE,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/invoices/${invoice.id}`, {
            method: 'DELETE',
          }),
        {
          organisationId,
          invoice,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const customerUpdateInvoiceAction = (
  organisationId: number,
  invoice: UpdateInvoicePayload,
): ThunkAction<CustomersUpdateInvoiceAction, Promise<any>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
): Promise<boolean> => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_UPDATE_INVOICE,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/accounting/invoices/${invoice.id}`, {
            method: 'POST',
            body: {
              invoice_type: invoice.invoiceType,
              customer_id: invoice.customerId,
              lines: !invoice.lines
                ? undefined
                : invoice.lines.map(line => ({
                    invoice_line_id: line.invoiceLineId,
                    manual_start_date: line.manualStartDate,
                    manual_end_date: line.manualEndDate,
                    accrual_status: line.accrual_status,
                  })),
              renewal_status: invoice.renewal_status,
            },
          }),
        {
          organisationId,
          invoice,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

// bills
export const customerUpdateBillAction = (
  organisationId: number,
  bill: UpdateBillPayload,
): ThunkAction<CustomersUpdateBillAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_UPDATE_BILL,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/accounting/bills/${bill.id}`, {
            method: 'POST',
            body: bill,
          }),
        {
          organisationId,
          bill,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const customerCreateManualBillAction = (
  organisationId: number,
  bill: CreateManualBillPayload,
): ThunkAction<CustomersCreateManualBillAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_CREATE_MANUAL_BILL,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/bills`, {
            method: 'POST',
            body: {
              invoice_number: bill.invoiceNumber,
              total_amount: bill.amount,
              account_id: bill.accountId,
              customer_id: bill.supplierId,
              start_date: bill.startDate,
              end_date: bill.endDate,
            },
          }),
        {
          organisationId,
          bill,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const customerUpdateManualBillAction = (
  organisationId: number,
  bill: UpdateManualBillPayload,
): ThunkAction<CustomersUpdateManualBillAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_UPDATE_MANUAL_BILL,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/bills/${bill.id}`, {
            method: 'PUT',
            body: {
              invoice_number: bill.invoiceNumber,
              total_amount: bill.amount,
              account_id: bill.accountId,
              customer_id: bill.supplierId,
              start_date: bill.startDate,
              end_date: bill.endDate,
            },
          }),
        {
          organisationId,
          bill,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const customerDeleteManualBillAction = (
  organisationId: number,
  bill: Pick<UpdateBillPayload, 'id'>,
): ThunkAction<CustomersDeleteManualBillAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_DELETE_MANUAL_BILL,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/manual/bills/${bill.id}`, {
            method: 'DELETE',
          }),
        {
          organisationId,
          bill,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const markBillExcluded = (
  organisationId: number,
  billType: 'bill' | 'credit_note',
  billId: string,
): ThunkAction<MarkBillReviewedAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_REVIEW_BILL,
        () =>
          http<void, void>(
            `/api/v1/organisations/${organisationId}/accounting/${
              billType === 'bill' ? 'bill' : 'bill_credit_note'
            }/${billId}/mark-excluded`,
            {
              method: 'POST',
            },
          ),
        {
          organisationId,
          billType,
          billId,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};

export const markCustomerBillsExcluded = (
  organisationId: number,
  customerId: string,
  bills: string[],
  billCreditNotes: string[],
): ThunkAction<MarkCustomerBillsReviewedAction, Promise<boolean>> => async (
  dispatch: ThunkDispatch<CustomersActions>,
  getState,
) => {
  const state = getState();
  const customers = selectCustomers(state, organisationId);

  if (customers?.status === 'pending') {
    return false;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_CUSTOMERS_REVIEW_BILLS,
        () =>
          http<void, void>(`/api/v1/organisations/${organisationId}/accounting/customer/${customerId}/mark-excluded`, {
            method: 'POST',
            body: {
              bills,
              bill_credit_notes: billCreditNotes,
            },
          }),
        {
          organisationId,
          customerId,
          bills,
          billCreditNotes,
        },
      ),
    );
    return true;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return false;
};
