import { Dayjs } from 'dayjs';
import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';
import { v4 as uuid } from 'uuid';

import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import { useDateSelectionDate } from '../../../components/molecules/DateSelect/useDateSelection';
import { ColorPalette } from '../../../components/organisms/MetricSchemaChartWrapper/generateColorPalette';
import { ValueState } from '../../../store/values';
import { getFiscalQuarter } from '../../../utils/dates';
import useMetricSchemaSeriesGroups from '../../../utils/metrics/useMetricSchemaSeriesGroups';
import { getDateKey } from '../ChartCard/helpers';
import { AmChartConfig, AmChartDataPoint, Period, PieChart } from '../ChartCard/types';

const PIE_SERIES_TEMPLATE: any = {
  id: uuid(),
  zIndex: 1,
  dataFields: {
    category: 'selector',
    value: 'value',
    valueY: '',
    categoryX: '',
    dateX: '',
  },
  labels: {
    disabled: true,
    radius: '15%',
    fontSize: 12,
    wrap: true,
    maxWidth: 130,
    text: '{category}',
  },
  alignLabels: false,
  slices: {
    showTooltipOn: 'hover',
    tooltipText: '{category}: {value}',
    propertyFields: {
      fill: 'color',
    },
  },
};

const usePieChartConfig = (config: PieChart): ValueState<AmChartConfig & { isEmpty: boolean }> => {
  const { default_budget_set_id: defaultBudgetId, financial_year_start: financialYearStart } = useOrganisationContext();
  const focalDate: Dayjs = useDateSelectionDate();
  const { fiscalYear, fiscalQuarter } = getFiscalQuarter(focalDate, 0, financialYearStart);
  const period: Period = config.chart_data.period;
  const dateKey: string = getDateKey(period, fiscalYear, fiscalQuarter, focalDate);
  const budgetId: number = config.chart_data.budget || defaultBudgetId;
  const metricSchema: MetricSchema2 = config.chart_data.metric_schema;
  const color: string = config.chart_data.color;
  const { seriesGroupsVS, labels } = useMetricSchemaSeriesGroups(metricSchema, dateKey, budgetId);

  if (seriesGroupsVS.status !== 'success') {
    return seriesGroupsVS as ValueState<AmChartConfig & { isEmpty: boolean }>;
  }

  const colors = ColorPalette.generate_tones(color, seriesGroupsVS.value.length);
  const data: AmChartDataPoint[] = seriesGroupsVS.value.map((seriesGroup, index) => {
    let value = 0;
    let isPercentage = false;

    if (seriesGroup[0]) {
      value = Number(seriesGroup[0].value || 0);
      isPercentage = seriesGroup[0].data_type?.toLowerCase() === 'percentage';
    }

    const formattedValue = isPercentage ? Number(value * 100).toFixed(2) : Math.round(value);

    return {
      selector: labels[index],
      value: formattedValue,
      color: colors[index],
    };
  });

  return {
    status: 'success',
    error: undefined,
    value: {
      series: [PIE_SERIES_TEMPLATE],
      xAxes: [],
      yAxes: [],
      legend: {
        fontSize: 10,
        marginBottom: 20,
        position: 'bottom',
      } as any,
      data,
      titles: [],
      isEmpty: data.every(d => !d.value),
    },
  };
};

export default usePieChartConfig;
