import React from 'react';
import { BorderStyle, BorderStyleEnum } from 'scalexp/store/state/reports/types';
import styled from 'styled-components';

import IconButton from '../../../components/atoms/IconButton';
import Typography from '../../../components/atoms/Typography';
import Select from '../../../components/molecules/Select';
import Tooltip from '../../../components/molecules/Tooltip';
import { rowBorderStyles } from '../rows/ReportEditorRow/helpers';

interface SelectBorderProps {
  borders: { borderTop?: BorderStyle; borderBottom?: BorderStyle };
  onChange: (value: { borderTop?: BorderStyle; borderBottom?: BorderStyle }) => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
`;

const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 120px 1fr;
`;

const BorderSelectItem = styled.div<{ side: string; type: 'SOLID' | 'DOUBLE' | 'SUBTLE' | 'NONE' }>`
  height: 20px;
  width: 30px;
  margin: 0 auto;
  border-bottom: ${({ type }) => rowBorderStyles[type]};
  border-width: 3px;
  font-size: ${({ theme }) => theme.font.size.small};
  text-align: center;
`;

const SelectBorder: React.FC<React.PropsWithChildren<SelectBorderProps>> = ({ borders, onChange }) => {
  return (
    <Tooltip
      trigger="click"
      placement="bottom"
      color="white"
      content={
        <StyledContainer onMouseDown={e => e.stopPropagation()}>
          <StyledRow>
            <Typography>Border Top:</Typography>
            <Select
              width="90px"
              placeholder="Top"
              onChange={value => onChange({ ...borders, borderTop: value as BorderStyle })}
              value={borders.borderTop}
              data={Object.keys(BorderStyleEnum).map((key: string) => ({ label: key, value: key }))}
              renderValue={value => (
                <BorderSelectItem type={value as BorderStyle} side="bottom">
                  {value === 'NONE' ? 'None' : ''}
                </BorderSelectItem>
              )}
              renderSelectItem={value => (
                <BorderSelectItem
                  type={value as BorderStyle}
                  data-testid={`border-bottom-item-${value.toLocaleLowerCase()}`}
                  side="bottom"
                >
                  {value === 'NONE' ? 'None' : ''}
                </BorderSelectItem>
              )}
              showCheckMark={false}
              clearable
              zIndex="highest"
            />
          </StyledRow>
          <StyledRow>
            <Typography>Border Bottom:</Typography>
            <Select
              width="90px"
              placeholder="Bottom"
              onChange={value => onChange({ ...borders, borderBottom: value as BorderStyle })}
              value={borders.borderBottom}
              data={Object.keys(BorderStyleEnum).map((key: string) => ({ label: key, value: key }))}
              renderValue={value => (
                <BorderSelectItem type={value as BorderStyle} side="bottom">
                  {value === 'NONE' ? 'None' : ''}
                </BorderSelectItem>
              )}
              renderSelectItem={value => (
                <BorderSelectItem
                  type={value as BorderStyle}
                  data-testid={`border-bottom-item-${value.toLocaleLowerCase()}`}
                  side="bottom"
                >
                  {value === 'NONE' ? 'None' : ''}
                </BorderSelectItem>
              )}
              showCheckMark={false}
              clearable
              zIndex="highest"
            />
          </StyledRow>
        </StyledContainer>
      }
    >
      <IconButton name="border_bottom" size="medium" />
    </Tooltip>
  );
};
export default SelectBorder;
