import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

export interface DividerProps extends HTMLAttributes<HTMLHRElement> {
  direction?: 'vertical' | 'horizontal';
  transparent?: boolean;
  height?: string;
  color?: string;
  noMargin?: boolean;
}

const StyledDivider = styled.hr<DividerProps>`
  border: 0;

  ${({ theme, direction, height = '100%', noMargin, color }) => {
    if (direction === 'horizontal') {
      return css`
        border-top: 1px solid ${color ? color : theme.palette.silver};
        width: 100%;
        height: 0px;
        margin: ${noMargin ? 0 : `${theme.spacing(2)} 0`};
      `;
    }
    if (direction === 'vertical') {
      return css`
        border-left: 1px solid ${color ? color : theme.palette.silver};
        width: 0px;
        height: 100%;
        height: ${height};
        margin: ${noMargin ? 0 : `0 ${theme.spacing(2)}`};
      `;
    }
    return '';
  }}

  ${({ transparent }) => {
    if (transparent) {
      return css`
        border: none;
      `;
    }
  }}
`;

const Divider: React.FC<React.PropsWithChildren<DividerProps>> = ({
  direction = 'horizontal',
  transparent = false,
  noMargin = false,
  color,
  ...other
}) => {
  return <StyledDivider direction={direction} transparent={transparent} noMargin={noMargin} color={color} {...other} />;
};

export default Divider;
