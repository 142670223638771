import React from 'react';
import { Link } from 'react-router-dom';
import Typography from 'scalexp/components/atoms/Typography';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import Column from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';
import styled, { css } from 'styled-components';

const StyledBadge = styled(Typography)`
  ${({ theme }) => css`
    width: 68px;
    height: 29px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.primary.light};
  `}
`;

const StyledTooltipContainer = styled(Column)`
  padding: 8px;
`;

const StyledButtonLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    padding: 8px 16px;
    gap: 8px;
    background-color: ${theme.palette.primary.light};
    border-radius: 8px;

    &:hover {
      text-decoration: none;
      outline: none;
    }
  `}
`;
const StyledList = styled.ul`
  & li::marker {
    color: ${({ theme }) => theme.palette.midnight};
  }
`;

const JournalTooltipContent = () => {
  const { prepaid_bills_location } = useActiveOrganisation();
  const locationPath = prepaid_bills_location === 'p_and_l' ? '/identify' : '/allocate';

  return (
    <StyledTooltipContainer width="390px" spacing="small">
      <Row spacing="small">
        <StyledBadge color="white" size="medium">
          New
        </StyledBadge>
        <Typography size="medium" weight="bold">
          Journals Tab
        </Typography>
      </Row>
      <Typography>All monthly tasks can now be found in the Journals tab</Typography>
      <Column spacing="tiny" width="100%">
        <StyledList>
          <li>
            <Typography weight="regular">Deferred Revenue Journal </Typography>
          </li>
          <li>
            <Typography weight="regular">Accrued Revenue Journal</Typography>
          </li>
          <li>
            <Typography weight="regular">Prepaid Expenses Journal</Typography>
          </li>
        </StyledList>
        <Row width="100%" hAlign="center">
          <StyledButtonLink to={`/journals/suppliers${locationPath}`}>
            <Typography color="white" weight="regular">
              Looking for “Cost”? Find it here
            </Typography>
          </StyledButtonLink>
        </Row>
      </Column>
    </StyledTooltipContainer>
  );
};

export default JournalTooltipContent;
