import dayjs from 'dayjs';
import { NEW_DATE_FORMAT } from 'scalexp/components/molecules/DateSelect/dateSupport';

const getFromAndToDatesFromFocal = (
  focusedMonth: string,
  fromDate = dayjs().startOf('month').subtract(1, 'months').format(NEW_DATE_FORMAT),
  toDate = dayjs().startOf('month').add(3, 'months').format(NEW_DATE_FORMAT),
) => {
  if (!focusedMonth) {
    return [fromDate, toDate];
  }

  if (dayjs(focusedMonth).isBetween(fromDate, toDate, null, '[]')) {
    return [fromDate, toDate];
  }

  if (dayjs(focusedMonth).isBefore(fromDate, 'months')) {
    return [focusedMonth, toDate];
  }

  return [fromDate, focusedMonth];
};

export default getFromAndToDatesFromFocal;
