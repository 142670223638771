import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import {
  ACTION_PRESENTATION_ADD_SLIDE,
  ACTION_PRESENTATION_DELETE_SLIDE,
  ACTION_PRESENTATION_DELETE_SLIDE_ELEMENT,
  ACTION_PRESENTATION_DUPLICATE_SLIDE,
  ACTION_PRESENTATION_LOAD,
  ACTION_PRESENTATION_OPTIMISTIC_UPDATE,
  ACTION_PRESENTATION_REORDER_SLIDE,
  ACTION_PRESENTATION_UPDATE,
  ACTION_PRESENTATION_UPDATE_SLIDE,
  ACTION_PRESENTATION_UPDATE_SLIDE_ELEMENT,
  UPDATE_PRESENTATION_SLIDE_COMPUTED_PROPERTIES,
} from 'scalexp/store/state/presentation/constants';

import { Presentation, Slide } from '../../../features/presentations/types';
import { PresentationsActions, PresentationVS } from './types';

const presentation = (
  prevState: PresentationVS = {
    status: 'pending',
    error: undefined,
    value: undefined,
  },
  action: PresentationsActions,
): PresentationVS => {
  return produce(prevState, state => {
    switch (action.type) {
      case ACTION_PRESENTATION_LOAD: {
        switch (action.status) {
          case 'pending':
            state.status = 'pending';
            state.error = undefined;
            state.value = undefined;
            return;

          case 'success':
            state.status = 'success';
            state.error = undefined;
            state.value = action.payload as WritableDraft<Presentation>;
            return;

          case 'error':
            state = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ACTION_PRESENTATION_UPDATE: {
        switch (action.status) {
          case 'pending':
            // @ts-ignore
            if (state.value && state.value.title !== action.params?.presentation?.title) {
              // @ts-ignore
              state.value.title = action.params?.presentation?.title;
            }
            if (state.value) {
              state.value.isSaving = true;
            }
            return;
          case 'success':
            state.value = {
              ...state.value,
              ...(action.payload as WritableDraft<Presentation>),
              // We don't want to override the slides since we have some frontend properties here.
              slides: state.value!.slides,
              isSaving: false,
            };
            return;
          default:
            return state;
        }
      }

      case ACTION_PRESENTATION_OPTIMISTIC_UPDATE: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value = {
                ...state.value,
                ...(action.params?.presentation ?? {}),
                isSaving: true,
              };
            }

            return;
          case 'success':
            if (state.value) {
              state.value.isSaving = false;
            }
            return;
          default:
            return state;
        }
      }

      case ACTION_PRESENTATION_ADD_SLIDE: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
            }
            return;
          case 'success':
            state.value?.slides.push(action.payload as WritableDraft<Slide>);
            if (state.value) {
              state.value.isSaving = false;
            }
            return;
          default:
            return state;
        }
      }

      case ACTION_PRESENTATION_REORDER_SLIDE: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
              const slides = [...state.value?.slides];
              const slideId = action.params?.slideId;
              const slideIndex = slides?.findIndex(slide => slide.id === slideId);
              const oldIndex = slideIndex;
              const newIndex = action.params.position!;
              const slide = slides[oldIndex];
              slides.splice(oldIndex, 1);
              slides.splice(newIndex, 0, slide);
              state.value.slides = slides;
              return state;
            }
            return;
          case 'success':
            if (state.value) {
              state.value.isSaving = false;
            }
            return;
          default:
            return state;
        }
      }
      case ACTION_PRESENTATION_DUPLICATE_SLIDE: {
        const { slideId } = action.params;
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
            }
            return;
          case 'success':
            const slideIndex = state.value?.slides.findIndex(slide => slide.id === slideId);
            state.value?.slides.splice(slideIndex! + 1, 0, action.payload as WritableDraft<Slide>);
            if (state.value) {
              state.value.isSaving = false;
            }
            return;
          default:
            return state;
        }
      }
      case UPDATE_PRESENTATION_SLIDE_COMPUTED_PROPERTIES: {
        if (state.status === 'pending') {
          if (state.value) {
            state.value.isSaving = true;
          }
        } else if (state.status === 'success') {
          const slideIndex = state.value.slides.findIndex(slide => slide.id === action.params?.slideId);
          if (slideIndex > -1) {
            const slide = state.value.slides[slideIndex];
            state.value.slides[slideIndex]['computedProperties'] = {
              ...slide.computedProperties,
              ...action.payload,
            };
          }
          if (state.value) {
            state.value.isSaving = false;
          }
        }
        return;
      }

      case ACTION_PRESENTATION_UPDATE_SLIDE: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
            }
            return;
          case 'success':
            if (state.value) {
              const slideIndex = state.value.slides.findIndex(slide => slide.id === action.params?.slideId);
              const slide = state.value.slides[slideIndex];
              if (slideIndex > -1) {
                state.value.slides[slideIndex] = {
                  ...slide,
                  ...action.payload,
                };
              }
              state.value.isSaving = false;
            }

            return;
          default:
            return state;
        }
      }
      case ACTION_PRESENTATION_DELETE_SLIDE: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
            }
            return;
          case 'success':
            if (state.value) {
              state.value.slides = state.value.slides.filter(slide => {
                return slide.id !== action.params?.slideId;
              });
              state.value.isSaving = false;
            }

            return;
          default:
            return state;
        }
      }

      case ACTION_PRESENTATION_UPDATE_SLIDE_ELEMENT: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
              state.value.savingType = action.params?.savingType;
            }
            return;
          case 'success':
            if (state.value) {
              const slideIndex = state.value.slides.findIndex(slide => slide.id === action.params?.slideId);
              if (slideIndex > -1) {
                const slide = state.value.slides[slideIndex];
                // @ts-ignore
                slide[action.params.elementKey] = action.payload;
              }
              state.value.isSaving = false;
              state.value.savingType = undefined;
            }

            return;
          default:
            return state;
        }
      }

      case ACTION_PRESENTATION_DELETE_SLIDE_ELEMENT: {
        switch (action.status) {
          case 'pending':
            if (state.value) {
              state.value.isSaving = true;
            }
            return;
          case 'success':
            if (state.value) {
              state.value.slides = state.value.slides.map(slide => {
                if (slide.id === action.params?.slideId) {
                  return {
                    ...slide,
                    [action.params.elementKey]: null,
                  };
                }
                return slide;
              });
              state.value.isSaving = false;
            }

            return;
          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default presentation;
