import React, { HTMLAttributes } from 'react';
import { Logo } from 'scalexp/components/organisms/Header/Logo';
import styled from 'styled-components';

import TextButton from '../../atoms/TextButton';

export interface AuthHeaderProps extends HTMLAttributes<HTMLDivElement> {}

const StyledHeader = styled.div<AuthHeaderProps>`
  width: 100%;
  height: 60px;
  background: transparent;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.high};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  padding-right: 16px;
`;

export const LogoContainer = styled.div`
  margin-right: 16px;

  img.logo {
    content: url('/images/logos/scalexp.svg');
  }

  @media (min-width: 1300px) {
    img.logo {
      content: url('/images/logos/scalexp-banner.svg');
    }
  }
`;

const AuthHeader: React.FC<React.PropsWithChildren<AuthHeaderProps>> = ({ children, ...other }) => {
  return (
    <StyledHeader>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <a href="mailto:support@scalexp.com ">
        <TextButton variant="secondary" iconLeft="info">
          Contact
        </TextButton>
      </a>
    </StyledHeader>
  );
};

export default AuthHeader;
