import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'scalexp/store/state/types';

import { deleteBudget, loadBudgetSets, renameBudget } from './actions';
import { selectBudgetSets } from './selectors';
import { BudgetSetDetailsVS } from './types';

export const useBudgetSets = (organisationId: number): BudgetSetDetailsVS => {
  const budgetSets: BudgetSetDetailsVS = useSelector<Store, ReturnType<typeof selectBudgetSets>>(state =>
    selectBudgetSets(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadBudgetSets(organisationId));
  }, [organisationId]);

  if (budgetSets === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...budgetSets,
    reload,
  };
};

export const useDeleteBudget = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    (budgetSetId: number) => {
      return dispatch(deleteBudget(organisationId, budgetSetId));
    },
    [organisationId],
  );
};

export const useRenameBudget = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    (budgetSetId: number, budgetSetName: string) => {
      return dispatch(renameBudget(organisationId, budgetSetId, budgetSetName));
    },
    [organisationId],
  );
};
