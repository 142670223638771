import { Store } from 'scalexp/store/state/types';

import { DeferredSeriesRemainingVS } from './types';

export const selectDeferredSeriesRemaining = (
  state: Store,
  organisationId: number,
): DeferredSeriesRemainingVS | undefined => {
  return state.deferredSeriesRemaining[organisationId];
};
