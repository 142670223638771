import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import Typography from '../Typography';

type CheckBoxSize = 'small' | 'large';

export interface CheckboxProps {
  customSize?: CheckBoxSize;
  color?: 'primary' | 'success';
  checked?: boolean;
  indeterminate?: boolean;
  label?: string;
  disabled?: boolean;
  className?: string;
  onChange?: (checked: boolean) => void;
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`;

const StyledCheckBox = styled.span<CheckboxProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${({ indeterminate, theme }) => (indeterminate ? 'unset' : theme.spacing(0.4))};
  background-color: ${({ disabled, theme }) => (disabled ? theme.palette.silver : theme.palette.white)};
  cursor: pointer;

  ${({ checked, indeterminate, color, disabled, theme }) => {
    if (disabled) {
      return css`
        background-color: ${theme.palette.silver};
        border: 1px solid ${theme.palette.silver};
      `;
    }

    if (!checked && !indeterminate) {
      return css`
        border: 1px solid ${theme.palette.fieldGrey};
      `;
    }

    switch (color) {
      case 'primary':
        return css`
          background-color: ${theme.palette.primary.main};
          border: 1px solid ${theme.palette.primary.main};
        `;
      case 'success':
        return css`
          background-color: ${theme.palette.success.main};
          border: 1px solid ${theme.palette.success.main};
        `;
      default:
        return '';
    }
  }};

  &:hover {
    ${({ color, disabled, theme }) => {
      if (disabled) {
        return `border: 1px solid ${theme.palette.silver}`;
      }

      switch (color) {
        case 'primary':
          return css`
            border: 1px solid ${theme.palette.primary.main};
          `;
        case 'success':
          return css`
            border: 1px solid ${theme.palette.success.main};
          `;
        default:
          return '';
      }
    }};
  }

  ${({ customSize, theme }) => {
    switch (customSize) {
      case 'small':
        return css`
          height: ${theme.sizing(4)};
          width: ${theme.sizing(4)};
          border-radius: ${theme.sizing(0.5)};
        `;
      case 'large':
        return css`
          height: ${theme.sizing(6)};
          width: ${theme.sizing(6)};
          border-radius: ${theme.sizing(1)};
        `;
      default:
        return '';
    }
  }};

  &:after {
    content: '';
    display: ${({ checked, indeterminate }) => (checked || indeterminate ? 'flex' : 'none')};
    border: solid ${({ disabled, theme }) => (disabled ? theme.palette.fieldGrey : theme.palette.white)};
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);

    ${({ customSize }) => {
      switch (customSize) {
        case 'small':
          return css`
            width: 4px;
            height: 8px;
            border-width: 0 1px 1px 0;
          `;
        case 'large':
          return css`
            width: 5px;
            height: 12px;
            border-width: 0 2px 2px 0;
          `;
        default:
          return '';
      }
    }};

    ${({ indeterminate }) => {
      if (indeterminate) {
        return css`
          width: 0;
          transform: rotate(90deg);
        `;
      }
    }};
  }
`;

const StyledLabel = styled(Typography)`
  cursor: pointer;
  user-select: none;
`;

const Checkbox: React.FC<React.PropsWithChildren<CheckboxProps>> = ({
  customSize = 'small',
  color = 'primary',
  checked,
  indeterminate = false,
  label,
  disabled,
  className = '',
  onChange,
}) => {
  const [innerChecked, setInnerChecked] = useState(checked);

  const isChecked = checked === undefined ? innerChecked : checked;
  const isIndeterminate = !isChecked && indeterminate;

  const handleChange = () => {
    if (disabled) {
      return;
    }
    setInnerChecked(!isChecked);
    onChange?.(!isChecked);
  };

  const typographySize = customSize === 'small' ? 'small' : 'medium';

  return (
    <StyledContainer className={className}>
      <StyledCheckBox
        customSize={customSize}
        color={color}
        role="checkbox"
        aria-checked={innerChecked}
        checked={isChecked}
        indeterminate={isIndeterminate}
        disabled={disabled}
        onClick={handleChange}
      />
      {!!label && (
        <StyledLabel size={typographySize} weight="regular" onClick={handleChange}>
          {label}
        </StyledLabel>
      )}
    </StyledContainer>
  );
};

export default Checkbox;
