import { combineReducers } from 'redux';
import { Store } from 'scalexp/store/state/types';

import accountGroups from './accountGroups/reducers';
import accountRules from './accountRules/reducers';
import accounts from './accounts/reducers';
import actualsExchangeRateOverride from './actualsExchangeRates/reducers';
import budgetExchangeRates from './budgetExchangeRates/reducers';
import budgets from './budgets/reducers';
import budgetsets from './budgetsets/reducers';
import charts from './charts/reducers';
import columnsPresets from './columnsPresets/reducers';
import consolidatedBudgetsets from './consolidatedBudgetsets/reducers';
import customers from './customers/reducers';
import deferredAccountMapping from './deferredAccountMapping/reducers';
import deferredBills from './deferredBills/reducers';
import deferredBillsRemaining from './deferredBillsRemaining/reducers';
import deferredBillsSummation from './deferredBillsSummation/reducers';
import deferredInvoices from './deferredInvoices/reducers';
import deferredInvoicesSummation from './deferredInvoicesSummation/reducers';
import deferredSeries from './deferredSeries/reducers';
import deferredSeriesRemaining from './deferredSeriesRemaining/reducers';
import deferredSeriesRemainingByAccount from './deferredSeriesRemainingByAccount/reducers';
import deferredSeriesRemainingByCustomer from './deferredSeriesRemainingByCustomer/reducers';
import derivedMetrics from './derivedMetrics/reducers';
import editedReport from './editedReport/reducers';
import exchangeRates from './exchangeRates/reducers';
import manualJournals from './manualJournals/reducers';
import monthEndCategories from './monthEnd/reducers';
import nativeMetrics from './nativeMetrics/reducers';
import newdashboards from './newDashboards/reducers';
import organisations from './organisations/reducers';
import pipelines from './pipelines/reducers';
import prepaymentsPresets from './prepaymentsPresets/reducers';
import presentation from './presentation/reducers';
import presentationEditor from './presentationEditor/reducers';
import reportGrouping from './reportGrouping/reducers';
import reports from './reports/reducers';
import trackingCategories from './trackingCategories/reducers';

const rootReducer = combineReducers<Store>({
  account: accounts,
  accountGroups: accountGroups,
  actualsExchangeRateOverride,
  monthEndCategories,
  deferredAccountMapping,
  deferredInvoices,
  deferredInvoicesSummation,
  deferredBills,
  deferredBillsSummation,
  deferredSeriesRemaining,
  deferredSeriesRemainingByAccount,
  deferredSeriesRemainingByCustomer,
  deferredBillsRemaining,
  prepaymentsPresets: prepaymentsPresets,
  deferredSeries,
  customers,
  charts,
  reports,
  columnsPresets,
  trackingCategories,
  nativeMetrics,
  exchangeRates,
  derivedMetrics,
  organisations,
  manualJournals,
  newdashboards,
  budgetExchangeRates,
  budgetsets,
  consolidatedBudgetsets,
  budgets,
  pipelines,
  accountRules,
  editedReport,
  reportGrouping,
  presentation,
  presentationEditor,
});

export default rootReducer;
