import throttle from 'lodash/throttle';
import { useState, useLayoutEffect, useRef, useCallback, MutableRefObject } from 'react';

export const usePortalPositioning = (
  show: boolean,
  // if we wan't to close on scroll
  setShow?: (show: boolean) => void,
): {
  containerRef: MutableRefObject<HTMLDivElement | null>;
  containerCallbackRef: (domNode: HTMLDivElement | null) => void;
  containerRect: DOMRect;
  elementRef: MutableRefObject<HTMLDivElement | null>;
  elementCallbackRef: (domNode: HTMLDivElement | null) => void;
  elementDimensions: { width: number; height: number };
} => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [containerRect, setContainerRect] = useState(new DOMRect());
  const [elementDimensions, setElementDimensions] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const containerCallbackRef = useCallback((domNode: HTMLDivElement | null) => {
    if (domNode) {
      containerRef.current = domNode;
      setContainerRect(domNode.getBoundingClientRect());
    }
  }, []);

  const elementCallbackRef = useCallback((domNode: HTMLDivElement | null) => {
    if (domNode) {
      elementRef.current = domNode;
      setElementDimensions({
        width: domNode.getBoundingClientRect().width,
        height: domNode.getBoundingClientRect().height,
      });
    }
  }, []);

  // handling scroll and resize
  useLayoutEffect(() => {
    if (!show) {
      return;
    }

    const handleScrollOrResize = throttle(
      (e: Event) => {
        if (
          e.type === 'scroll' &&
          e.target !== elementRef.current &&
          ![...(elementRef.current?.childNodes || [])].find(child => child === e.target) &&
          e.target !== document
        ) {
          setShow?.(false);
        }

        if (containerRef.current) {
          setContainerRect(containerRef.current.getBoundingClientRect());
        }
        if (elementRef.current) {
          setElementDimensions({
            width: elementRef.current.getBoundingClientRect().width,
            height: elementRef.current.getBoundingClientRect().height,
          });
        }
      },
      100,
      { leading: true },
    );
    window.addEventListener('scroll', handleScrollOrResize, true);
    window.addEventListener('resize', handleScrollOrResize, true);

    // container resize
    const ro = new ResizeObserver(() => {
      handleScrollOrResize({ type: 'resize' } as Event);
    });
    if (containerRef.current) {
      ro.observe(containerRef.current);
    }

    // element resize
    if (elementRef.current) {
      ro.observe(elementRef.current);
    }

    return () => {
      window.removeEventListener('scroll', handleScrollOrResize);
      window.removeEventListener('resize', handleScrollOrResize);
      ro.disconnect();
    };
  }, [show]);

  return { containerRef, containerCallbackRef, containerRect, elementRef, elementCallbackRef, elementDimensions };
};
