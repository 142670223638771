import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';
import { MetricSchema2Complex } from 'scalexp/utils/metrics/metricSchema2';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectDerivedMetrics } from './selectors';
import { DerivedMetric } from './types';

export const ORGANISATIONS_DERIVED_METRICS_LOAD = '[derivedMetrics] LOAD';
export const ORGANISATIONS_DERIVED_METRICS_UPDATE_METRIC = '[derivedMetrics] UPDATE_METRIC';

type DerivedMetricActionLoad = PromiseAction<
  typeof ORGANISATIONS_DERIVED_METRICS_LOAD,
  DerivedMetric[],
  { organisationId: number }
>;

type DerivedMetricActionUpdate = PromiseAction<
  typeof ORGANISATIONS_DERIVED_METRICS_UPDATE_METRIC,
  DerivedMetric,
  {
    organisationId: number;
    derivedMetricId: DerivedMetric['metricId'];
    name: DerivedMetric['name'];
    metricSchema: DerivedMetric['metricSchema'];
  }
>;

export type DerivedMetricsActions = DerivedMetricActionLoad | DerivedMetricActionUpdate;

export type DerivedMetricDTO = {
  metric_id: string;
  name: string;
  metric_schema: MetricSchema2Complex;
  description: string;
};

type DerivedMetricResponse = {
  data: DerivedMetricDTO[];
};

export type UpdateDerivedMetricActionResult =
  | {
      success: false;
    }
  | {
      success: true;
    };

const parseDTO = (dto: DerivedMetricDTO): DerivedMetric => ({
  metricId: dto.metric_id,
  name: dto.name,
  metricSchema: dto.metric_schema,
  description: dto.description,
});

export const loadDerivedMetricsAction = (organisationId: number): ThunkAction<DerivedMetricsActions> => async (
  dispatch: ThunkDispatch<DerivedMetricsActions>,
  getState,
) => {
  const state = getState();
  const derivedMetrics = selectDerivedMetrics(state);

  if (derivedMetrics?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DERIVED_METRICS_LOAD,
        () =>
          http<DerivedMetric[], DerivedMetricResponse>(
            `/api/v1/organisations/${organisationId}/derived_metrics`,
            {},
            response => response.data.map(parseDTO),
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updateDerivedMetricAction = (
  organisationId: number,
  derivedMetricId: DerivedMetric['metricId'],
  name: DerivedMetric['name'],
  metricSchema: DerivedMetric['metricSchema'],
): ThunkAction<DerivedMetricActionUpdate, Promise<UpdateDerivedMetricActionResult>> => async (
  dispatch: ThunkDispatch<DerivedMetricsActions>,
  getState,
) => {
  const state = getState();
  const derivedMetrics = selectDerivedMetrics(state);

  if (derivedMetrics?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const derivedMetric = await dispatch(
      promiseAction(
        ORGANISATIONS_DERIVED_METRICS_UPDATE_METRIC,
        async () =>
          http<DerivedMetric, DerivedMetricResponse>(`/api/v1/organisations/${organisationId}/derived_metrics`, {
            method: 'PUT',
            body: {
              metric_id: derivedMetricId,
              name: name,
              metric_schema: metricSchema,
            },
          }),
        {
          organisationId,
          derivedMetricId,
          name,
          metricSchema,
        },
      ),
    );
    return {
      success: true,
      derivedMetric,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
