import React, { useMemo } from 'react';
import ChartEditorAddRowSelect from 'scalexp/features/chart-editor/ChartEditorAddRowSelect';
import { PieChart, TimeChart, TimeChartData, TimeSeries, WaterfallChart } from 'scalexp/features/chart/ChartCard/types';
import { DeferredRevenueConfig } from 'scalexp/features/deferred-revenue/types';
import {
  MetricSchema2,
  MetricSchema2Complex,
  MetricSchema2Data,
  MetricSchema2DataSourceDerivedMetric,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataSourceNativeMetric,
  MetricSchema2Simple,
} from 'scalexp/utils/metrics/metricSchema2';
import styled from 'styled-components';

import Divider from '../../../components/atoms/Divider';
import Icon from '../../../components/atoms/Icon';
import Input from '../../../components/atoms/Input';
import Toggle from '../../../components/atoms/Toggle';
import Typography from '../../../components/atoms/Typography';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import Select from '../../../components/molecules/Select';
import FormulaEditorModal from '../../../components/organisms/FormulaEditorModal';
import { theme } from '../../../theme';
import usePickerOptions from '../../report-editor/ReportEditor/usePickerOptions';
import { useChartDeferredRevenueConfigOptions } from '../ChartCard/hooks';

const SettingsWrapper = styled(Column)`
  padding: 19px 16px;

  & > div {
    flex-shrink: 0;
  }
  & > div:last-child {
    padding-bottom: 40px;
  }
`;
const TIME_CHART_PERIODS = [
  { label: 'Month', value: 'MONTH' },
  { label: 'Quarter To Date', value: 'QUARTER_TO_DATE' },
  { label: 'Quarter', value: 'QUARTER' },
  { label: 'Year To Date', value: 'YEAR_TO_DATE' },
  { label: 'Full Year', value: 'YEAR' },
];
const DEFAULT_HEADER_METRIC_SCHEMA: MetricSchema2 = {
  schemaType: 'simple',
  decimals: 0,
  nodes: [
    {
      data: {
        metricId: 'REVENUE_TOTAL',
        operator: 'native',
        isCashflow: false,
      },
      operator: 'add',
    },
  ],
};

interface ChartConfigurationPropsType {
  isKPIChart?: boolean;
}

const ChartConfiguration: React.FC<ChartConfigurationPropsType> = ({ isKPIChart }) => {
  const { chart, setConfig } = useEditChartContext();
  const pickerOptions = usePickerOptions();
  const [showModal, setShowModal] = React.useState(false);
  const [configureDetails, setConfigureDetails] = React.useState<null | {
    metricSchema: MetricSchema2Complex;
    updateMetricSchema: (updatedMetricSchema: MetricSchema2Complex) => void;
  }>(null);

  const metricSchema: MetricSchema2 | null = useMemo(() => {
    if (chart.header_config?.metricSchema) {
      return chart.header_config.metricSchema;
    } else if ('series' in chart.chart_data && chart.chart_data.series.length > 0) {
      return chart.chart_data.series[0].metric_schema;
    } else if ('metric_schema' in chart.chart_data) {
      return chart.chart_data.metric_schema;
    }
    return DEFAULT_HEADER_METRIC_SCHEMA;
  }, [chart.chart_data, chart.header_config]);

  const isComplexCase: boolean = metricSchema.schemaType === 'complex';
  const firstNode: MetricSchema2Data | null = metricSchema?.schemaType === 'simple' ? metricSchema.nodes[0] : null;
  const isInvoicedRevenue = firstNode?.data?.operator === 'invoiced-revenue';

  const configOptions = useChartDeferredRevenueConfigOptions();

  const normalizedPickerOptions = useMemo(() => {
    const options = [];
    pickerOptions.forEach(option => {
      if (['dm', 'nm', 'account', 'sm', 'pm', 'ps', 'group', 'ir'].includes(option.id.split(':')[0])) {
        options.push({
          ...option,
          label: option.name,
          value: option.id,
          group: option.role,
        });
      }
    });
    if (isComplexCase) {
      options.push({
        id: '',
        group: '',
        source_id: '',
        role: '',
        name: 'Custom Formula',
        label: 'Custom Formula',
        value: '',
      });
    }
    return options;
  }, [pickerOptions, metricSchema]);

  const updateChartLeftYAxis = (leftYAxisLabel: string | null) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        y_axis_label: leftYAxisLabel,
      },
    } as TimeChart | PieChart | WaterfallChart);
  };

  const handleConfigure = (metricSchema: MetricSchema2Complex) => {
    setConfigureDetails({
      metricSchema,
      updateMetricSchema: updatedMetricSchema => {
        setConfig({
          ...chart,
          header_config: {
            ...chart.header_config,
            metricSchema: updatedMetricSchema,
          },
        });
      },
    });
    setShowModal(true);
  };

  const updateChartRightYAxis = (rightYAxisLabel: string | null) => {
    setConfig({
      ...chart,
      chart_data: {
        ...chart.chart_data,
        right_y_axis_label: rightYAxisLabel,
      },
    } as TimeChart | PieChart | WaterfallChart);
  };

  const getSelectedValue = () => {
    // complex formula
    if (metricSchema.schemaType === 'complex') {
      return '';
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'group') {
      return `group:${firstNode.data.groupId}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'pipeline') {
      return `pm:${firstNode.data.pipelineId}`;
    } else if (firstNode?.data?.operator === 'entity' && firstNode?.data?.entity === 'stage') {
      return `ps:${firstNode.data.stageId}:${firstNode.data.pipelineId}`;
    }
    // get value from the choices
    else {
      return normalizedPickerOptions.find(option =>
        [
          (firstNode!.data as MetricSchema2DataSourceNativeMetric | MetricSchema2DataSourceDerivedMetric).metricId,
          (firstNode!.data as MetricSchema2DataSourceEntityAccount).accountId?.toString(),
        ].includes(option.id.split(':')[1]),
      )?.id;
    }
  };

  const handleMetricChange = (metricSchema: MetricSchema2Simple) => {
    const newHeaderConfig: {
      metricSchema: MetricSchema2;
    } | null = {
      ...chart.header_config,
      metricSchema,
    };
    setConfig({
      ...chart,
      header_config: newHeaderConfig,
    });
  };
  const getInvoicedRevenueValue = () => {
    if (firstNode?.data?.operator === 'invoiced-revenue' && firstNode?.data.configId) {
      return firstNode.data.configId;
    }
  };
  const handleUpdateInvoicedRevenueValue = (newValue: string | null) => {
    if (!firstNode) {
      return;
    }
    const seriesCopy = Object.assign({}, chart.header_config?.metricSchema.nodes[0]);
    // @ts-ignore
    seriesCopy.data.configId = newValue;
    setConfig({
      ...chart,
      header_config: {
        ...chart.header_config,
        metricSchema: {
          ...chart.header_config?.metricSchema,
          nodes: [seriesCopy],
        } as MetricSchema2,
      },
    });
  };

  return (
    <SettingsWrapper width="100%" spacing="xlarge">
      <Column spacing="small" width="100%">
        <Typography size="small" weight="regular">
          {isKPIChart ? 'KPI' : 'Chart'} Name
        </Typography>
        <Input
          width="100%"
          defaultValue={chart.name}
          customSize="large"
          onBlur={e =>
            setConfig({
              ...chart,
              name: e.currentTarget.value,
            })
          }
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setConfig({
                ...chart,
                name: e.currentTarget.value,
              });
            }
          }}
        />
      </Column>
      {chart.chart_type !== 'kpi_chart' && (
        <Column width="100%">
          <Row width="100%" vAlign="center" hAlign="space-between">
            <Typography color="secondary" size="medium" weight="regular">
              Show summary results at top
            </Typography>
            <Toggle
              checked={Boolean(chart.header_config?.metricSchema)}
              onChange={checked => {
                if (!checked) {
                  setConfig({ ...chart, header_config: null });
                } else {
                  setConfig({ ...chart, header_config: { ...chart.header_config, metricSchema: metricSchema } });
                }
              }}
            />
          </Row>
          {chart.header_config?.metricSchema && (
            <Column width="100%" spacing="small">
              <Row hAlign="space-between" vAlign="center" width="100%">
                <ChartEditorAddRowSelect
                  includeFixedNumber={false}
                  includeBrackets={false}
                  size="xlarge"
                  isComplexCase={isComplexCase}
                  selectWidth={isComplexCase ? 345 : undefined}
                  onChange={handleMetricChange}
                  value={getSelectedValue()!}
                  handleFormulaCategorySelected={() => {
                    setConfigureDetails({
                      metricSchema: {
                        schemaType: 'complex',
                        nodes: [],
                        decimals: 0,
                        dataType: 'monetary',
                        impact: 'neutral',
                        aggregation: 'sum',
                      },
                      updateMetricSchema: updatedMetricSchema => {
                        setConfig({
                          ...chart,
                          header_config: {
                            ...chart.header_config,
                            metricSchema: {
                              ...updatedMetricSchema,
                              schemaType: 'complex',
                            },
                          },
                        });
                      },
                    });
                    setShowModal(true);
                  }}
                />
                {isComplexCase && (
                  <Icon
                    clickable
                    name="settings"
                    size={6}
                    color={theme.palette.granite}
                    onClick={() => handleConfigure(chart.header_config?.metricSchema as MetricSchema2Complex)}
                  />
                )}
              </Row>
              {isInvoicedRevenue && !isComplexCase && (
                <Row hAlign="space-between" vAlign="center" width="100%">
                  <Select<DeferredRevenueConfig['id'] | null>
                    width="100%"
                    size="medium"
                    selectWidth={300}
                    data={configOptions}
                    value={getInvoicedRevenueValue()}
                    onChange={value => {
                      handleUpdateInvoicedRevenueValue?.(value);
                    }}
                    placeholder={'Config'}
                    onClick={e => e.stopPropagation()}
                  />
                </Row>
              )}
            </Column>
          )}
        </Column>
      )}

      <Divider />
      {'y_axis_label' in chart.chart_data || 'show_zero_left_y_axis' in chart.chart_data ? (
        <Column width="100%" spacing="large">
          {'y_axis_label' in chart.chart_data && (
            <Column width="100%" spacing="small">
              <Typography size="small" weight="regular">
                Optional Left Y axis Label
              </Typography>
              <Input
                width={'100%'}
                type="text"
                placeholder="Optional label"
                customSize="large"
                defaultValue={chart.chart_data.y_axis_label ?? ''}
                onBlur={e => updateChartLeftYAxis(e.currentTarget.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    updateChartLeftYAxis(e.currentTarget.value);
                  }
                }}
              />
            </Column>
          )}
          {'right_y_axis_label' in chart.chart_data &&
          (chart.chart_data.series as TimeSeries[]).find(series => series.y_axis_side === 'RIGHT') ? (
            <Column width="100%" spacing="small">
              <Typography size="small" weight="regular">
                Optional Right Y axis Label
              </Typography>
              <Input
                width={'100%'}
                type="text"
                placeholder="Optional label"
                customSize="large"
                defaultValue={chart.chart_data.right_y_axis_label ?? ''}
                onBlur={e => updateChartRightYAxis(e.currentTarget.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    updateChartRightYAxis(e.currentTarget.value);
                  }
                }}
              />
            </Column>
          ) : null}
          {'show_zero_left_y_axis' in chart.chart_data &&
          (chart.chart_data as TimeChartData).series.find(series => series.y_axis_side === 'LEFT') ? (
            <Row width="100%" hAlign="space-between" vAlign="center">
              <Typography color="secondary" size="medium" weight="regular">
                Always show zero on left Y-axis
              </Typography>
              <Toggle
                checked={(chart.chart_data as TimeChartData).show_zero_left_y_axis}
                onChange={value =>
                  setConfig({
                    ...chart,
                    chart_data: {
                      ...chart.chart_data,
                      show_zero_left_y_axis: value,
                    },
                  } as TimeChart)
                }
              />
            </Row>
          ) : null}
          {(chart.chart_data as TimeChartData).series.find(series => series.y_axis_side === 'RIGHT') ? (
            <Row width="100%" hAlign="space-between" vAlign="center">
              <Typography color="secondary" size="medium" weight="regular">
                Always show zero on right Y-axis
              </Typography>
              <Toggle
                checked={(chart.chart_data as TimeChartData).show_zero_right_y_axis}
                onChange={value =>
                  setConfig({
                    ...chart,
                    chart_data: {
                      ...chart.chart_data,
                      show_zero_right_y_axis: value,
                    },
                  } as TimeChart)
                }
              />
            </Row>
          ) : null}
          {chart.chart_type === 'time_chart' && (
            <>
              <Row width="100%" spacing="small">
                <Row width="100%" spacing="small">
                  <Typography color="secondary" size="medium" weight="regular">
                    Chart period
                  </Typography>
                </Row>
                <Select
                  width="100%"
                  value={chart.chart_data.period}
                  data={TIME_CHART_PERIODS}
                  onChange={value =>
                    setConfig({
                      ...chart,
                      chart_data: {
                        ...chart.chart_data,
                        period: value,
                        series: (chart.chart_data as TimeChartData).series.map(series => ({
                          ...series,
                          periods: value === 'MONTH' ? 12 : value === 'QUARTER' ? 4 : 4,
                        })),
                      },
                    } as TimeChart)
                  }
                />
              </Row>
            </>
          )}
        </Column>
      ) : null}
      {showModal && configureDetails != null && (
        <FormulaEditorModal configureDetails={configureDetails} onHide={() => setShowModal(false)} />
      )}
    </SettingsWrapper>
  );
};

export default ChartConfiguration;
