import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'scalexp/store/state/types';

import { loadTrackingCategoriesAction } from './actions';
import { selectTrackingCategories } from './selectors';
import { TrackingCategoriesVS } from './types';

export const useTrackingCategories = (organisationId: number): TrackingCategoriesVS => {
  const trackingCategories: TrackingCategoriesVS | undefined = useSelector<
    Store,
    ReturnType<typeof selectTrackingCategories>
  >(state => selectTrackingCategories(state, organisationId));
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadTrackingCategoriesAction(organisationId));
  }, []);

  if (trackingCategories === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...trackingCategories,
    reload,
  };
};
