import React from 'react';
import Input from 'scalexp/components/atoms/Input';
import DropDown from 'scalexp/components/molecules/DropDown';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import { MetricSchema2Decimals } from 'scalexp/utils/metrics/metricSchema2';

interface ReportEditorUpdateDecimalsProps {
  type?: 'drop-down' | 'input';
  decimals: MetricSchema2Decimals;
  handleUpdateDecimals: (decimals: MetricSchema2Decimals) => void;
}

const ReportEditorUpdateDecimals: React.FC<React.PropsWithChildren<ReportEditorUpdateDecimalsProps>> = ({
  type = 'drop-down',
  decimals,
  handleUpdateDecimals,
}) => {
  if (type === 'drop-down') {
    const decimalList: MetricSchema2Decimals[] = [0, 1, 2];

    return (
      <DropDown
        size="medium"
        placement="topStart"
        customButton={
          <Tooltip content="Decimals">
            <img src="/images/icons/decimals.svg" alt="decimals" style={{ cursor: 'pointer' }} />
          </Tooltip>
        }
        onClick={e => e.stopPropagation()}
        zIndex="highest"
      >
        {decimalList.map(item => (
          <DropDown.Item key={`${item}`} onClick={() => handleUpdateDecimals(item)} active={item === decimals}>
            {item}
          </DropDown.Item>
        ))}
      </DropDown>
    );
  }

  return (
    <Input
      type="number"
      width="100%"
      min={0}
      max={2}
      value={decimals}
      onChange={e => {
        const newDecimals = Number(e.target.value);

        if (newDecimals < 0 || newDecimals > 2) {
          return;
        }

        handleUpdateDecimals(newDecimals as MetricSchema2Decimals);
      }}
      onKeyDown={e => e.preventDefault()}
      onClick={e => e.stopPropagation()}
    />
  );
};

export default ReportEditorUpdateDecimals;
