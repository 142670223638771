import React, { HTMLAttributes } from 'react';
import Icon from 'scalexp/components/atoms/Icon';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import { theme } from 'scalexp/theme';

interface DragHanldeProps extends HTMLAttributes<HTMLDataElement> {
  tooltipText?: string;
}

export const DragHandle: React.FC<DragHanldeProps> = ({ tooltipText, ...props }) =>
  tooltipText ? (
    <Tooltip content={tooltipText}>
      <Icon name="drag_indicator" rounded color={theme.palette.granite} size={5} marginRight={0} clickable {...props} />
    </Tooltip>
  ) : (
    <Icon name="drag_indicator" rounded color={theme.palette.granite} size={5} marginRight={0} clickable {...props} />
  );
