import { yupResolver } from '@hookform/resolvers/yup';
import React, { HTMLAttributes, ReactNode } from 'react';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TextButton, { TextButtonProps } from 'scalexp/components/atoms/TextButton';
import FormAutoSubmit from 'scalexp/components/molecules/Form/FormAutoSubmit';
import * as yup from 'yup';

import Divider from '../../atoms/Divider';
import Column from '../../layout/Column';
import Row from '../../layout/Row';

export interface FormProps<Input extends FieldValues> {
  onSubmit: SubmitHandler<Input>;
  actions?: ReactNode;
  children: HTMLAttributes<HTMLElement>['children'];
  schema: yup.AnyObjectSchema;
  defaultValues?: object;
  noDivider?: boolean;
  layout?: 'vertical' | 'inline';
  className?: string;
  mode?: 'onBlur' | 'onChange' | 'onSubmit';
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit';
  autoSubmit?: boolean;
}

const Form = <Input extends {}>({
  onSubmit,
  children,
  actions,
  schema,
  defaultValues,
  layout = 'vertical',
  noDivider = true,
  className,
  mode,
  reValidateMode,
  autoSubmit = false,
}: FormProps<Input>) => {
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode,
    reValidateMode,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const _actions = actions
    ? React.Children.map(actions, child => {
        if (React.isValidElement(child) && child.type === TextButton) {
          return React.cloneElement<TextButtonProps>(child as React.ReactElement<TextButtonProps>, {
            loading: isSubmitting,
            disabled: isSubmitting,
          });
        }
        return child;
      })
    : null;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={className}>
        {layout === 'vertical' && (
          <Column height="100%" vAlign="space-between">
            <Column width="100%">{children}</Column>
            {!noDivider && <Divider direction="horizontal" />}
            {_actions && (
              <Row width="100%" hAlign="end">
                {_actions}
              </Row>
            )}
          </Column>
        )}
        {layout === 'inline' && (
          <Row>
            {children}
            {_actions}
          </Row>
        )}
        {autoSubmit && <FormAutoSubmit onSubmit={onSubmit} />}
      </form>
    </FormProvider>
  );
};

export default Form;
