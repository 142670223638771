import get from 'lodash/get';
import React, { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import Typography, { TypographyColor } from 'scalexp/components/atoms/Typography';
import Column from 'scalexp/components/layout/Column';
import Row from 'scalexp/components/layout/Row';

export interface LabeledFormFieldProps {
  labelDirection?: string;
  labelColor?: TypographyColor;
  children: ReactElement;
  width?: string;
  label: string;
  inputId?: string;
  customSize?: 'tiny' | 'small' | 'medium' | 'large';
}

const LabeledFormField: React.FC<React.PropsWithChildren<LabeledFormFieldProps>> = ({
  label,
  inputId,
  labelDirection = 'column',
  labelColor = 'black',
  customSize = 'small',
  width,
  children,
}) => {
  const Wrapper = labelDirection === 'column' ? Column : Row;
  const labelSize = customSize === 'large' ? 'medium' : customSize;
  const childProps = children.props;
  const {
    formState: { errors },
  } = useFormContext();
  const name = childProps.name;
  const error = errors[name] || get(errors, name);
  const _inputId = inputId || name;

  return (
    <Wrapper spacing="tiny" vAlign="center" width={width}>
      <label style={{ flex: '0 0 auto' }} htmlFor={_inputId}>
        <Typography size={labelSize} color={error ? 'danger' : labelColor}>
          {label}
        </Typography>
      </label>
      {React.cloneElement(children, {
        id: _inputId,
        customSize: childProps.customSize || customSize,
        name,
      })}
      {error?.message && (
        <Typography size={labelSize} color="danger">
          {`${error?.message}`}
        </Typography>
      )}
    </Wrapper>
  );
};

export default LabeledFormField;
