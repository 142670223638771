import { Store } from 'scalexp/store/state/types';

import { ActualsExchangeRateOverridesVS } from './types';

export const selectActualsExchangeRateOverrides = (
  state: Store,
  organisationId: number,
): ActualsExchangeRateOverridesVS | undefined => {
  return state.actualsExchangeRateOverride[organisationId];
};
