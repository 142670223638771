import {
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import React, { KeyboardEventHandler, useMemo, useState, ReactNode, useEffect, useRef } from 'react';
import { DATE_FORMAT_DISPLAY } from 'scalexp/components/molecules/DateSelect/dateSupport';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import { FormulaPickerOption } from 'scalexp/features/report-editor/ReportEditorAddRowSelect/helpers';
import ReportEditorCustomRowConfig from 'scalexp/features/report-editor/ReportEditorCustomRowConfig';
import { reportEditorcalculationRequest } from 'scalexp/features/report-editor/rows/ReportEditorCustomRow/utils';
import {
  MetricSchema2Complex,
  MetricSchema2ComplexWithIds,
  MetricSchema2DataOrNodesWithId,
  MetricSchema2DataType,
  MetricSchema2DataWithId,
  MetricSchema2NodesWithId,
} from 'scalexp/utils/metrics/metricSchema2';
import { recursiveRemoveIds } from 'scalexp/utils/metrics/ms2utils';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import Icon from '../../../components/atoms/Icon';
import Input from '../../../components/atoms/Input';
import TextButton from '../../../components/atoms/TextButton';
import Typography from '../../../components/atoms/Typography';
import useActiveOrganisation from '../../../components/contexts/OrganisationContext/useActiveOrganisation';
import Column from '../../../components/layout/Column';
import Row from '../../../components/layout/Row';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import { useDateSelectionDate } from '../../../components/molecules/DateSelect/useDateSelection';
import DropDown from '../../../components/molecules/DropDown';
import { MetricRow, Report } from '../../../store/state/reports/types';
import { theme } from '../../../theme';
import useMetricSchemaSeries from '../../../utils/metrics/useMetricSchemaSeries';
import { DISPLAY_COLUMNS, formatValue } from '../../report-editor/ReportEditor/helpers';
import { replaceReferences } from '../../report/report-wrapper/helpers';
import FormulaEditorAddRowAndBrackets from '../FormulaEditorAddRowAndBrackets';
import FormulaEditorRows from '../FormulaEditorRows';
import {
  addIdsToNestedNodes,
  useGetDragOverlay,
  getItemAndParentByPath,
  isDraggingOverDescendants,
  moveNestedItemsWithDifferentParents,
  moveNestedItemsWithSameParent,
} from './helpers';

interface FormulaEditorProps {
  pickerOptions: FormulaPickerOption[];
  metricSchema: MetricSchema2Complex;
  handleCancel: VoidFunction;
  handleSave: (metricSchema: MetricSchema2Complex) => void;
  name?: string;
  handleNameChange?: (name: string) => void;
  report: Report | null;
  hideTotalRow?: boolean;
  extraInfo?: ReactNode;
  includeRows?: boolean;
  showDemoLink?: boolean;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(5.5)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
`;

const StyledValuesGrid = styled.div<{ width?: string }>`
  width: ${({ width = 'unset' }) => width};
  display: grid;
  grid-template-columns: 160px;
  justify-content: end;
  gap: ${({ theme }) => theme.spacing(5)};
  padding: ${({ theme }) => `0 ${theme.spacing(4)}`};
  text-align: end;
`;

const StyledRowsContainer = styled.div`
  width: 100%;
  max-height: 50vh;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  padding-top: ${({ theme }) => theme.spacing(2)};
  overflow-y: auto;
`;

const StyledResultContainer = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: ${({ theme }) => theme.spacing(4)};
  border: 1px solid ${({ theme }) => theme.palette.granite};
  border-radius: ${({ theme }) => theme.spacing(2)};
`;

const StyledActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  span {
    white-space: nowrap;
  }
`;

const EditMetricDemoLink = styled.a`
  display: flex;
  gap: 16px;
  align-items: center;
  text-decoration: none;
  color: unset;
  &:hover {
    text-decoration: none;
  }
`;

const RenameIcon = styled(Icon)`
  transition: 0.3s;
  padding: 5px;
  max-width: 30px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.white};
    border-radius: 8px;
  }
`;

const EqualIcon = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  padding-bottom: 3px;
  background-color: #f6c155;
  color: #7c5f23;
`;

const formattingList: {
  label: string;
  value: MetricSchema2DataType;
}[] = [
  { label: '$€£', value: 'monetary' },
  { label: 'Number', value: 'numerical' },
  { label: '%', value: 'percentage' },
];

const FormulaEditor: React.FC<FormulaEditorProps> = ({
  pickerOptions,
  metricSchema,
  handleCancel,
  handleSave,
  name,
  handleNameChange,
  report,
  hideTotalRow,
  extraInfo,
  includeRows,
  showDemoLink = false,
}) => {
  const date = useDateSelectionDate();
  const [currencyCode] = useCurrencySelection();

  const editNameInputRef = useRef<HTMLInputElement>(null);

  const [editedMetricSchema, setEditedMetricSchema] = useState<MetricSchema2ComplexWithIds>({
    ...metricSchema,
    nodes: (metricSchema.nodes || []).map(ms => addIdsToNestedNodes(JSON.parse(JSON.stringify(ms)))),
  });
  const [selectedNodeId, setSelectedNodeId] = useState<string | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [isEditingName, setIsEditingName] = useState(false);
  const [addedNodeId, setAddedNodeId] = useState<string | null>(null);
  const organisation = useActiveOrganisation();
  const { financial_year_start, default_currency_code } = organisation;

  useEffect(() => {
    if (editedMetricSchema.nodes.length > 0) {
      setSelectedNodeId(editedMetricSchema.nodes[0].id!);
    }
  }, []);

  useEffect(() => {
    if (isEditingName) {
      editNameInputRef.current?.focus();
    }
  }, [isEditingName]);

  const rowWithoutReferences = useMemo(() => {
    return replaceReferences(
      {
        id: uuidv4(),
        type: 'metric',
        metric: editedMetricSchema,
        bold: true,
      },
      report?.rows || [],
    ) as MetricRow;
  }, [editedMetricSchema, report?.rows]);

  const metricSchemaWithoutIds = recursiveRemoveIds(rowWithoutReferences.metric);
  const seriesVS = useMetricSchemaSeries(
    metricSchemaWithoutIds,
    reportEditorcalculationRequest(date, currencyCode || default_currency_code),
  );

  const getDragOverlay = useGetDragOverlay();

  const values: (number | null)[] = useMemo(() => {
    const series = seriesVS.value;
    if (!series) {
      return [];
    }

    return series.map(serie => (serie ? Number(serie.value) : null));
  }, [seriesVS.value, DISPLAY_COLUMNS, financial_year_start]);

  const handleAddRows = (values: string[]) => {
    let updatedNodes: MetricSchema2DataOrNodesWithId[] = [...editedMetricSchema.nodes];

    values.forEach(value => {
      const [type, newId, newIdPartTwo] = value.split(':');
      const nodeId = uuidv4();
      setAddedNodeId(nodeId);

      if (value === 'custom') {
        const newNode: MetricSchema2NodesWithId = {
          id: nodeId,
          operator: 'add',
          nodes: [],
        };
        updatedNodes.push(newNode);
        return;
      }
      if (value === 'constant') {
        const newNode: MetricSchema2DataWithId = {
          id: nodeId,
          operator: 'add',
          data: {
            operator: 'constant',
            value: 0,
          },
        };
        updatedNodes.push(newNode);
        return;
      }

      let node: MetricSchema2DataWithId;
      switch (type) {
        case 'nm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'native',
              metricId: newId,
              isCashflow: false,
            },
          };
          break;
        }
        case 'dm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'derived',
              metricId: newId,
            },
          };
          break;
        }
        case 'ir': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'invoiced-revenue',
              metricId: newId,
            },
          };
          break;
        }
        case 'account': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'account',
              accountId: parseInt(newId),
              isCashflow: false,
            },
          };
          break;
        }
        case 'group': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'group',
              groupId: parseInt(newId),
              isCashflow: false,
            },
          };
          break;
        }
        case 'sm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'sales',
              metricId: newId,
            },
          };
          break;
        }
        case 'pm': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'pipeline',
              pipelineMetricId: 'metric_deals_in_progress_acv',
              pipelineId: newId,
            },
          };
          break;
        }
        case 'ps': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'entity',
              entity: 'stage',
              stageMetricId: 'metric_deals_acv',
              pipelineId: newIdPartTwo,
              stageId: newId,
            },
          };
          break;
        }
        case 'row': {
          node = {
            id: nodeId,
            operator: 'add',
            data: {
              operator: 'reference',
              reference: 'row',
              rowId: newId,
            },
          };
          break;
        }
        default: {
          throw new Error(`Unknown case '${type}' from '${value}'`);
        }
      }

      updatedNodes.push(node);
    });
    setEditedMetricSchema(ms => ({ ...ms, nodes: updatedNodes }));
  };

  const handleAddBrackets = () => {
    const newNode: MetricSchema2DataOrNodesWithId = {
      id: uuidv4(),
      operator: 'add',
      nodes: [],
    };
    let updatedNodes: MetricSchema2DataOrNodesWithId[] = [...editedMetricSchema.nodes];
    updatedNodes.push(newNode);
    setAddedNodeId(newNode.id);
    setEditedMetricSchema(ms => ({ ...ms, nodes: updatedNodes }));
  };

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id.toString());
  };

  const handleCanDrag = (updatedNodes: MetricSchema2DataOrNodesWithId[], event: DragOverEvent) => {
    const { active, over } = event;
    const activeId = active.id.toString();
    const overId = over?.id.toString();
    if (!activeId || !overId || activeId === overId) {
      return null;
    }

    // getting active item
    const activePath: string = active.data.current?.['path'] || '';
    const activeResult = getItemAndParentByPath(updatedNodes, activePath);
    if (!activeResult) {
      return null;
    }
    const { item: activeItem } = activeResult;

    // getting overItem item
    const overPath: string = over?.data.current?.['path'] || '';
    const overResult = getItemAndParentByPath(updatedNodes, overPath);
    if (!overResult) {
      return null;
    }

    // edge case (avoid handling drag over for child nodes)
    if ('nodes' in activeItem && isDraggingOverDescendants(activeItem, overId)) {
      return null;
    }

    return { active, over, activeId, overId, activeResult, overResult };
  };

  // we handle dropping above and bellow a container on drag move
  const handleDragMove = (event: DragMoveEvent) => {
    setEditedMetricSchema((ms: MetricSchema2ComplexWithIds) => {
      let updatedNodes: MetricSchema2DataOrNodesWithId[] = [...ms.nodes];
      const canDrag = handleCanDrag(updatedNodes, event);
      if (!canDrag) {
        return ms;
      }
      const { active, over, activeId, overId, activeResult, overResult } = canDrag;
      const { item: activeItem, parent: activeParent } = activeResult;
      const { item: overItem, parent: overParent } = overResult;
      // if we are sorting we just return (sorting handled on drag end)
      if (activeParent?.id === overParent?.id && !('nodes' in overItem)) {
        return ms;
      }

      if ('nodes' in overItem) {
        if (
          Math.abs(
            (over?.rect.top || Number.MIN_SAFE_INTEGER) -
              (active.rect.current.translated?.top || Number.MAX_SAFE_INTEGER),
          ) < 20
        ) {
          return {
            ...ms,
            nodes: moveNestedItemsWithDifferentParents(
              ms.nodes,
              activeId,
              overId,
              activeItem,
              overItem,
              activeParent,
              overParent,
              'before',
            ),
          };
        }

        if (
          Math.abs(
            (over?.rect.bottom || Number.MIN_SAFE_INTEGER) -
              (active.rect.current.translated?.bottom || Number.MAX_SAFE_INTEGER),
          ) < 20
        ) {
          return {
            ...ms,
            nodes: moveNestedItemsWithDifferentParents(
              ms.nodes,
              activeId,
              overId,
              activeItem,
              overItem,
              activeParent,
              overParent,
              'after',
            ),
          };
        }
      }

      // handling moving between containers
      return {
        ...ms,
        nodes: moveNestedItemsWithDifferentParents(
          updatedNodes,
          activeId,
          overId,
          activeItem,
          overItem,
          activeParent,
          overParent,
        ),
      };
    });
  };

  const handleUpdateComplexMetricSchemaProperties = (
    complexMetricSchemaProperties: Partial<
      Pick<MetricSchema2Complex, 'dataType' | 'decimals' | 'impact' | 'aggregation'>
    >,
  ) => {
    setEditedMetricSchema(ms => ({ ...ms, ...complexMetricSchemaProperties }));
  };

  // we handle sorting on drag end
  const handleDragEnd = (event: DragEndEvent) => {
    setEditedMetricSchema((ms: MetricSchema2ComplexWithIds) => {
      let updatedNodes: MetricSchema2DataOrNodesWithId[] = [...editedMetricSchema.nodes];
      const canDrag = handleCanDrag(updatedNodes, event);
      if (!canDrag) {
        return ms;
      }
      const { activeId, overId, activeResult, overResult } = canDrag;
      const { parent: activeParent } = activeResult;
      const { item: overItem, parent: overParent } = overResult;

      // if they have the same parent (just sorting)
      if (activeParent?.id === overParent?.id && !('nodes' in overItem)) {
        const updatedNodes = moveNestedItemsWithSameParent(
          ms.nodes,
          activeId,
          overId,
          overItem,
          activeParent,
          overParent,
        );
        if (updatedNodes) {
          return {
            ...ms,
            nodes: updatedNodes,
          };
        }
      }

      return ms;
    });

    setActiveId(null);
  };

  const handleRenameInputKeyDown: KeyboardEventHandler<HTMLInputElement> = async e => {
    if (e.key === 'Escape') {
      setIsEditingName(false);
    }
    if (e.key === 'Enter') {
      handleNameChange?.(e.currentTarget.value);
      setIsEditingName(false);
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  return (
    <StyledContainer>
      {extraInfo}
      {!showDemoLink && (
        <Typography size="medium" color="secondary" weight="regular">
          Here you can create a new computation by adding, subtracting, multiplying, dividing any data in the system.
        </Typography>
      )}
      <Column>
        <StyledValuesGrid width="100%">
          <Typography>{date.format(DATE_FORMAT_DISPLAY)}</Typography>
        </StyledValuesGrid>
        <StyledRowsContainer>
          <DndContext
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragMove={handleDragMove}
            onDragEnd={handleDragEnd}
          >
            <FormulaEditorRows
              pickerOptions={pickerOptions}
              id=""
              nodes={editedMetricSchema.nodes as MetricSchema2DataOrNodesWithId[]}
              updateNodes={newNodes => {
                // TODO: is this correct?
                setEditedMetricSchema(ms => ({ ...ms, nodes: newNodes }));
              }}
              selectedNodeId={selectedNodeId}
              setSelectedNodeId={setSelectedNodeId}
              addedNodeId={addedNodeId}
              setAddedNodeId={setAddedNodeId}
              parentPath=""
              report={report}
              includeRows={includeRows}
              decimals={editedMetricSchema.decimals}
            />
            <DragOverlay>{getDragOverlay(activeId, editedMetricSchema.nodes, report?.rows || [])}</DragOverlay>
          </DndContext>
        </StyledRowsContainer>
      </Column>
      <FormulaEditorAddRowAndBrackets
        pickerOptions={pickerOptions}
        handleAddRows={handleAddRows}
        handleAddBrackets={handleAddBrackets}
        includeRows={includeRows}
      />
      {!hideTotalRow ? (
        <StyledResultContainer>
          <Row vAlign="center">
            <Row vAlign="center">
              <EqualIcon size="h5" color="primary">
                =
              </EqualIcon>
              {isEditingName && handleNameChange ? (
                <Input
                  defaultValue={name}
                  onKeyDown={handleRenameInputKeyDown}
                  width="340px"
                  onBlur={e => {
                    handleNameChange(e.currentTarget.value);
                    setIsEditingName(false);
                  }}
                  ref={editNameInputRef}
                />
              ) : (
                <Typography>{name}</Typography>
              )}
              {!isEditingName && handleNameChange && (
                <Tooltip content="Rename metric">
                  <RenameIcon
                    name="title"
                    size={5}
                    color={theme.palette.granite}
                    clickable
                    onClick={() => setIsEditingName(true)}
                  />
                </Tooltip>
              )}
            </Row>
            <ReportEditorCustomRowConfig
              complexMetricSchemaProperties={{
                dataType: editedMetricSchema.dataType,
                decimals: editedMetricSchema.decimals,
                impact: editedMetricSchema.impact,
                aggregation: editedMetricSchema.aggregation,
              }}
              updateComplexMetricSchemaProperties={handleUpdateComplexMetricSchemaProperties}
            />
          </Row>
          <StyledValuesGrid>
            <Row hAlign="flex-end">
              <Typography color="secondary" weight="bold">
                Total
              </Typography>
              <Typography color="secondary">
                {formatValue(
                  values[0],
                  currencyCode || default_currency_code,
                  editedMetricSchema.dataType.toLowerCase() as MetricSchema2DataType,
                  editedMetricSchema.decimals,
                )}
              </Typography>
            </Row>
          </StyledValuesGrid>
        </StyledResultContainer>
      ) : (
        <Row spacing="small" vAlign="center">
          <Typography color="secondary" weight="regular">
            Outcome Format
          </Typography>
          <DropDown
            size="medium"
            title={formattingList.find(item => item.value === editedMetricSchema.dataType)?.label}
            placement="topStart"
          >
            {formattingList.map(item => (
              <DropDown.Item
                key={item.value}
                onClick={() => {
                  setEditedMetricSchema(ms => ({
                    ...ms,
                    dataType: item.value as MetricSchema2DataType,
                  }));
                }}
                active={item.value === editedMetricSchema.dataType}
              >
                {item.value === undefined ? '$€£' : item.label}
              </DropDown.Item>
            ))}
          </DropDown>
        </Row>
      )}
      <StyledActionsContainer>
        <Row width="100%" hAlign="space-between" vAlign="center">
          {showDemoLink && (
            <EditMetricDemoLink href="https://www.youtube.com/watch?v=MPRj8x99EbM" target="_blank">
              <img
                src="/images/illustrations/edit-metric-library-demo.svg"
                alt="scalexp demo link edit metric library"
              />
              <Typography size="medium" weight="regular" color="secondary">
                Watch video to learn more about editing Metric
              </Typography>
            </EditMetricDemoLink>
          )}
          <Row hAlign="flex-end" width="100%">
            <TextButton variant="secondary" size="large" width="160px" onClick={handleCancel}>
              Cancel
            </TextButton>
            <TextButton size="large" width="160px" onClick={() => handleSave(recursiveRemoveIds(editedMetricSchema))}>
              Save
            </TextButton>
          </Row>
        </Row>
      </StyledActionsContainer>
    </StyledContainer>
  );
};

export default FormulaEditor;
