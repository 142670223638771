import { ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectBudgetSets } from './selectors';
import {
  BudgetSetDetails,
  budgetSetsActions,
  ListBudgetSetsResponse,
  ORGANISATIONS_BUDGETSETS_DELETE,
  ORGANISATIONS_BUDGETSETS_LOAD,
  ORGANISATIONS_BUDGETSETS_RENAME,
} from './types';

export const loadBudgetSets = (organisationId: number): ThunkAction<budgetSetsActions> => async (
  dispatch: ThunkDispatch<budgetSetsActions>,
  getState,
) => {
  const state = getState();
  const budgetSets = selectBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_BUDGETSETS_LOAD,
        () =>
          http<BudgetSetDetails[], ListBudgetSetsResponse>(
            `/api/v1/organisations/${organisationId}/budget_sets`,
            undefined,
            response => response.budget_sets,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deleteBudget = (organisationId: number, budgetSetId: number): ThunkAction<budgetSetsActions> => async (
  dispatch: ThunkDispatch<budgetSetsActions>,
  getState,
) => {
  const state = getState();
  const budgetSets = selectBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_BUDGETSETS_DELETE,
        () =>
          http<BudgetSetDetails[], ListBudgetSetsResponse>(
            `/api/v1/organisations/${organisationId}/budget_sets/${budgetSetId}`,
            { method: 'DELETE' },
          ),
        {
          organisationId,
          budgetsetId: budgetSetId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const renameBudget = (
  organisationId: number,
  budgetSetId: number,
  budgetSetName: string,
): ThunkAction<budgetSetsActions> => async (dispatch: ThunkDispatch<budgetSetsActions>, getState) => {
  const state = getState();
  const budgetSets = selectBudgetSets(state, organisationId);

  if (budgetSets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_BUDGETSETS_RENAME,
        () =>
          http<BudgetSetDetails[], ListBudgetSetsResponse>(
            `/api/v1/organisations/${organisationId}/budget_sets/${budgetSetId}`,
            { method: 'PATCH', body: { name: budgetSetName } },
          ),
        {
          organisationId,
          budgetsetName: budgetSetName,
          budgetsetId: budgetSetId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
