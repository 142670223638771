export const ACTION_PRESENTATION_LOAD = '[presentation] LOAD';
export const ACTION_PRESENTATION_UPDATE = '[presentation] UPDATE';
export const ACTION_PRESENTATION_OPTIMISTIC_UPDATE = '[presentation] OPTIMISTIC_UPDATE';
export const ACTION_PRESENTATION_ADD_SLIDE = '[presentation] ADD';
export const ACTION_PRESENTATION_UPDATE_SLIDE = '[presentation] UPDATE_SLIDE';
export const ACTION_PRESENTATION_DELETE_SLIDE = '[presentation] DELETE_SLIDE';
export const ACTION_PRESENTATION_DUPLICATE_SLIDE = '[presentation] DUPLICATE_SLIDE';
export const ACTION_PRESENTATION_UPDATE_SLIDE_ELEMENT = '[presentation] UPDATE_SLIDE_ELEMENT';
export const ACTION_PRESENTATION_DELETE_SLIDE_ELEMENT = '[presentation] DELETE_SLIDE_ELEMENT';
export const ACTION_PRESENTATION_REORDER_SLIDE = '[presentation] REORDER_SLIDE';
export const UPDATE_PRESENTATION_SLIDE_COMPUTED_PROPERTIES = '[presentation] UPDATE_SLIDE_COMPUTED_PROPERTIES';
