import { useMemo } from 'react';
import {
  PieChart,
  PieChartData,
  TimeChart,
  TimeChartData,
  TimeSeries,
  WaterfallChart,
  WaterfallSeries,
} from 'scalexp/features/chart/ChartCard/types';
import {
  MetricSchema2,
  MetricSchema2DataSource,
  MetricSchema2DataSourceEntityAccount,
  MetricSchema2DataType,
} from 'scalexp/utils/metrics/metricSchema2';

import {
  NATIVE_METRIC_DATA_TYPE,
  PIPELINE_METRIC_DATA_TYPE,
  PIPELINE_STAGE_METRIC_DATA_TYPE,
  REVENUE_METRIC_DATA_TYPE,
  SALES_METRIC_DATA_TYPE,
} from '../../../service/types/metricSchema';
import { useAccounts } from '../../../store/state/accounts/hooks';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import useActiveOrganisationId from '../OrganisationContext/useActiveOrganisationId';

export const useChartDataType = (chart: TimeChart | WaterfallChart | PieChart) => {
  const organisationId = useActiveOrganisationId();
  const accountsVS = useAccounts(organisationId);
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const accounts = Object.entries(accountsVS.value ?? {});

  const getSeriesDataType = (
    series: TimeSeries | WaterfallSeries | PieChartData,
  ): MetricSchema2DataType | undefined => {
    if (!series) {
      return undefined;
    }

    // @ts-ignore
    const metricSchema: MetricSchema2 = series.metric_schema as MetricSchema2;

    if (metricSchema.schemaType === 'complex') {
      return metricSchema.dataType;
    } else {
      const dataSource: MetricSchema2DataSource = metricSchema.nodes[0].data;
      switch (dataSource.operator) {
        case 'native':
          return NATIVE_METRIC_DATA_TYPE[dataSource.metricId];
        case 'invoiced-revenue':
          return REVENUE_METRIC_DATA_TYPE[dataSource.metricId];
        case 'derived':
          return derivedMetricsVS.value
            ? derivedMetricsVS.value[dataSource.metricId].metricSchema.dataType
            : 'monetary';
        case 'sales':
          return SALES_METRIC_DATA_TYPE[dataSource.metricId];
        case 'entity':
          if (dataSource.entity === 'account' && accountsVS.value && accountsVS.value[dataSource.accountId]) {
            const account = accountsVS.value[dataSource.accountId];
            return NATIVE_METRIC_DATA_TYPE[account.tag];
          } else if (dataSource.entity === 'pipeline') {
            return PIPELINE_METRIC_DATA_TYPE[dataSource.pipelineMetricId];
          } else if (dataSource.entity === 'stage') {
            return PIPELINE_STAGE_METRIC_DATA_TYPE[dataSource.stageMetricId];
          } else {
            const account = accounts.find(
              ([accountId, _]) =>
                parseInt(accountId) === (dataSource as MetricSchema2DataSourceEntityAccount).accountId,
            );
            if (account) {
              return account[1].data_type?.toLowerCase() as MetricSchema2DataType;
            }
          }
          return 'monetary';

        default:
          return 'monetary';
      }
    }
  };

  const chartDataType: MetricSchema2DataType = useMemo(() => {
    if (!('series' in chart.chart_data)) {
      return getSeriesDataType(chart.chart_data as PieChartData);
    }
    return getSeriesDataType((chart.chart_data as TimeChartData).series[0]);
  }, [chart, accountsVS.status, derivedMetricsVS.status])!;

  return { chartDataType, getSeriesDataType };
};
