import forIn from 'lodash/forIn';

type ErrorResponse = {
  [key: string]: Array<string>;
};

/*
 * Generate error message from error response, e.g.:
 * {"email": [
 *   "This field is required."
 * ]}
 * => "email: This field is required."
 */
const generateErrorMessage = (errorResponse: ErrorResponse): string => {
  let errorMessage = '';
  forIn(errorResponse, (value, key) => {
    errorMessage += ` ${key}: ${value[0]}.`;
  });
  return errorMessage;
};

export { generateErrorMessage };
