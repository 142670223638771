import { Store } from 'scalexp/store/state/types';

import { ValueState } from '../../values';
import { ConsolidatedBudgetSet } from './types';

export const selectConsolidatedBudgetSets = (
  state: Store,
  organisationId: number,
): ValueState<ConsolidatedBudgetSet[]> => {
  const consolidatedBudgetsets = state.consolidatedBudgetsets[organisationId];
  return consolidatedBudgetsets;
};
