import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';
import dayjs, { Dayjs } from 'dayjs';
import { DATE_FORMAT_DISPLAY } from 'scalexp/components/molecules/DateSelect/dateSupport';

import { getCurrencySymbol } from '../../../components/molecules/CurrencySelect/currencySupport';
import { theme } from '../../../theme';
import { AmChartConfig, TimeChartData } from '../ChartCard/types';

const X_AXIS_CATEGORY_TEMPLATE = {
  type: 'CategoryAxis',
  cursorTooltipEnabled: false,
  renderer: {
    labels: {
      location: 0.5,
    },
    minGridDistance: 50,
  },
  gridIntervals: [
    { timeUnit: 'month', count: 1 },
    { timeUnit: 'month', count: 2 },
    { timeUnit: 'month', count: 3 },
    { timeUnit: 'month', count: 6 },
  ],
  title: {
    text: '',
    fontSize: 10,
    fontWeight: 'LIGHTER',
  },
  dataFields: {
    category: 'category',
  },
};

const X_AXIS_DATE_TEMPLATE = {
  type: 'DateAxis',
  cursorTooltipEnabled: false,
  renderer: {
    labels: {
      location: 0.5,
    },
    minGridDistance: 50,
  },
  gridIntervals: [
    { timeUnit: 'month', count: 1 },
    { timeUnit: 'month', count: 2 },
    { timeUnit: 'month', count: 3 },
    { timeUnit: 'month', count: 6 },
  ],
  title: {
    text: '',
    fontSize: 10,
    fontWeight: 'LIGHTER',
  },
};

const Y_AXIS_TEMPLATE = {
  type: 'ValueAxis',
  cursorTooltipEnabled: false,
  title: {
    text: '',
    fontSize: 12,
    fontWeight: 400,
  },
};

export function getXAxis(data: TimeChartData, date: Dayjs, year: string, quarter: number, currency: string) {
  let template = data.period === 'MONTH' ? X_AXIS_DATE_TEMPLATE : X_AXIS_CATEGORY_TEMPLATE;
  const MAPPED_LABEL_OPTIONS = {
    // @ts-ignore
    CURRENCY: getCurrencySymbol[currency],
    YEAR_TO_DATE: `${year} YTD`,
    YEAR: year,
    QUARTER: `Q${quarter} ${year}`,
    MONTH: date.format(DATE_FORMAT_DISPLAY),
  };
  const prepend = data.x_axis_label_prepend ? MAPPED_LABEL_OPTIONS[data.x_axis_label_prepend] : '';
  const text = data.x_axis_label || '';
  const append = data.x_axis_label_append ? MAPPED_LABEL_OPTIONS[data.x_axis_label_append] : '';
  return {
    ...template,
    title: {
      ...template.title,
      text: `${prepend} ${text} ${append}`,
    },
  };
}

export function getYAxis(
  data: TimeChartData,
  date: Dayjs,
  year: string,
  quarter: number,
  currency: string,
  min: number | undefined,
  max: number | undefined,
  minRightAxis: number | undefined,
  maxRightAxis: number | undefined,
) {
  let leftYAxisLabel = data.y_axis_label?.replaceAll(/#|%/g, '') ?? '';
  let rightYAxisLabel = data.right_y_axis_label?.replaceAll(/#|%/g, '') ?? '';

  return [
    {
      ...Y_AXIS_TEMPLATE,
      min: min,
      max: max,
      title: {
        ...Y_AXIS_TEMPLATE.title,
        text: leftYAxisLabel,
      },
    },
    {
      ...Y_AXIS_TEMPLATE,
      min: minRightAxis,
      max: maxRightAxis,
      title: {
        ...Y_AXIS_TEMPLATE.title,
        text: rightYAxisLabel,
      },
    },
  ];
}

function customizeGrip(grip: any) {
  // Copied from amchart docs
  // Remove default grip image
  grip.icon.disabled = true;

  // Disable background
  grip.background.disabled = true;

  let boxContainer = grip.createChild(am4core.RoundedRectangle) as am4core.RoundedRectangle;
  boxContainer.height = 22;
  boxContainer.width = 17;
  boxContainer.fill = am4core.color(theme.palette.silver);
  boxContainer.align = 'center';
  boxContainer.valign = 'middle';
  boxContainer.cornerRadius(3, 3, 3, 3);

  let box = grip.createChild(am4core.RoundedRectangle);
  box.height = 20;
  box.width = 15;
  box.fill = am4core.color('#fff');
  box.align = 'center';
  box.valign = 'middle';
  box.cornerRadius(2, 2, 2, 2);

  let line = grip.createChild(am4core.Rectangle);
  line.width = 1;
  line.height = 8;
  line.fill = am4core.color(theme.palette.granite);
  line.valign = 'middle';
  line.align = 'right';
  line.marginRight = 6;

  let line2 = grip.createChild(am4core.Rectangle);
  line2.width = 1;
  line2.height = 8;
  line2.fill = am4core.color(theme.palette.granite);
  line2.valign = 'middle';
  line2.marginLeft = 6;
}

export const getTimeChartInstance = (
  config: AmChartConfig,
  element: HTMLElement,
  leftSeriesDataType: string | undefined,
  rightSeriesDataType: string | undefined,
  currency: string,
  minDate?: Date,
  maxDate?: Date,
) => {
  const chart = am4core.createFromConfig(config, element, am4charts.XYChart) as am4charts.XYChart;
  chart.fontSize = 12;

  // NOTE: we should be able to set this in the config we pass to
  // createFromConfig, or set chart.legend.labels.template.maxWidth directly,
  // but it is ignored. See
  // https://github.com/amcharts/amcharts4/issues/3333#issuecomment-787675155
  // for where I found the below solution
  chart.legend.labels.template.adapter.add('maxWidth', function () {
    return config.legend?.labels?.maxWidth;
  });

  // Make sure to display full item name on hovering
  chart.legend.itemContainers.template.tooltipText = '{category}';

  // If we are not a Waterfall chart, setup export fields accordingly such
  // that export to CSV gives nice names for the columns. Without this we
  // will get names like value-123asd.
  //
  // For Waterfall, as we are using a different format for data there, we
  // need to handle this separately
  chart.exporting.dataFields = Object.fromEntries(
    new Array<Array<string>>().concat(
      ...config.series.map(series => [
        [series.dataFields.dateX, 'Date'],
        [series.dataFields.valueY, series.name],
      ]),
    ),
  );
  chart.exporting.dateFields.pushAll(config.series.map(series => series.dataFields.dateX));
  chart.exporting.dateFormat = 'yyyy-MM-dd';

  const xAxis = chart.xAxes.getIndex(0);
  if (xAxis instanceof am4charts.DateAxis) {
    xAxis.dateFormats.setKey('month', 'MMM YY');
    xAxis.periodChangeDateFormats.setKey('month', 'MMM YY');

    chart.events.on('ready', () => {
      if (xAxis.min && xAxis.max) {
        xAxis.zoomToDates(
          minDate || new Date(xAxis.min),
          maxDate || new Date(xAxis.max),
          true,
          true, // this makes zoom instant
        );
      }
    });
  }

  try {
    chart.paddingBottom = 20;
    chart.paddingBottom = 0;
    chart.marginBottom = 0;
    const title = chart?.titles?.getIndex(0);
    if (chart.zoomOutButton) {
      chart.zoomOutButton.disabled = true;
    }
    if (title) {
      // To remove the top space we can just use title.dispose()
      title.dispose();
      chart.paddingTop = 20;
    }

    const yAxisLeft = chart?.yAxes.getIndex(0);
    const yAxisRight = chart?.yAxes.getIndex(1);
    const xAxis = chart?.xAxes.getIndex(0);
    if (
      (xAxis instanceof am4charts.DateAxis || xAxis instanceof am4charts.CategoryAxis) &&
      yAxisLeft instanceof am4charts.ValueAxis
    ) {
      yAxisLeft.renderer.labels.template.fill = am4core.color('#999999');
      yAxisLeft.title.fill = am4core.color('#999999');
      xAxis.renderer.labels.template.fill = am4core.color('#999999');
      xAxis.title.fill = am4core.color('#999999');
    }

    const legend = chart.legend;
    const scrollbar = chart.scrollbarX as am4charts.XYChartScrollbar;
    if (legend) {
      legend.dispose();
    }
    if (scrollbar) {
      if (window?.location?.pathname?.endsWith('/print')) {
        scrollbar.dispose();
      } else {
        scrollbar.minHeight = 5;
        scrollbar.fill = am4core.color(theme.palette.primary.offwhite);
        scrollbar.background.fill = am4core.color(theme.palette.primary.offwhite);
        scrollbar.background.fillOpacity = 0.5;
        scrollbar.thumb.background.fill = am4core.color(theme.palette.primary.offwhite);
        scrollbar.thumb.background.fillOpacity = 1;
        scrollbar.thumb.hoverable = false;
        scrollbar.parent = chart.bottomAxesContainer;
        customizeGrip(scrollbar.startGrip);
        customizeGrip(scrollbar.endGrip);
        chart.series.each(seriesItem => {
          const series = seriesItem as am4charts.XYSeries & { dataType: string; y_axis_side: 'LEFT' | 'RIGHT' };
          const formatter = getFormatter(series.dataType, currency);
          series.tooltipText = `{name}: {valueY.formatNumber("${formatter}")}`;
          if (yAxisRight && series.y_axis_side !== 'LEFT') {
            series.yAxis = yAxisRight;
          }
          if (series.className === 'LineSeries') {
            series.zIndex = 1000;
          }
          if (scrollbar?.series?.push) {
            scrollbar.series.push(series);
          }
        });
      }
    }
  } catch (error) {}
  {
    let [xAxis = null] = chart.xAxes;
    let [yAxisLeft = null, yAxisRight = null] = chart.yAxes;

    chart.series.each(series => {
      if (series instanceof am4charts.ColumnSeries) {
        series.columns.template.width = am4core.percent(100);
      }
    });
    if (xAxis) {
      xAxis.renderer.grid.template.disabled = true;
      let startLine = xAxis.axisRanges.create();
      startLine.grid.stroke = am4core.color(theme.palette.primary.offwhite);
      startLine.grid.strokeWidth = 1;
      startLine.grid.strokeOpacity = 1;
      let endLine = xAxis.axisRanges.create();
      endLine.grid.stroke = am4core.color(theme.palette.primary.offwhite);
      endLine.grid.strokeWidth = 1;
      endLine.grid.strokeOpacity = 1;
      let endDate = chart.data?.[chart?.data?.length - 1]?.date ?? new Date();
      // @ts-ignore - Typing is wrong
      endLine.value = dayjs(endDate).add(1, 'month').startOf('month').toDate();
      xAxis.renderer.cellStartLocation = 0.3;
      xAxis.renderer.cellEndLocation = 0.8;
    }
    if (yAxisLeft) {
      yAxisLeft.renderer.grid.template.stroke = am4core.color(theme.palette.primary.offwhite);
      yAxisLeft.renderer.grid.template.strokeOpacity = 1;
      yAxisLeft.numberFormatter.numberFormat = `'${getCurrencySymbol(currency)}'#,###`;
      yAxisLeft.numberFormatter.numberFormat = getFormatter(leftSeriesDataType, currency);
      if (!leftSeriesDataType) {
        yAxisLeft.disabled = true;
      }
    }
    if (yAxisRight) {
      yAxisRight.renderer.labels.template.fill = am4core.color('#999999');
      yAxisRight.title.fill = am4core.color('#999999');
      yAxisRight.renderer.grid.template.stroke = am4core.color(theme.palette.primary.offwhite);
      yAxisRight.renderer.grid.template.strokeOpacity = 1;
      if (leftSeriesDataType) {
        yAxisRight.renderer.grid.template.disabled = true;
      }
      yAxisRight.renderer.opposite = true;
      yAxisRight.title.rotation = -90;
      yAxisRight.numberFormatter = new am4core.NumberFormatter();
      yAxisRight.numberFormatter.numberFormat = getFormatter(rightSeriesDataType, currency);
      if (!rightSeriesDataType) {
        yAxisRight.disabled = true;
      }
    }
  }

  return chart;
};

const getFormatter = (type: string | undefined, currency: string) => {
  switch (type) {
    case 'numerical':
      return '#';

    case 'percentage':
      return "#.## '%'";

    case 'monetary':
      return `'${getCurrencySymbol(currency)}'#,###`;

    default:
      return `'${getCurrencySymbol(currency)}'#,###`;
  }
};
