import { DownloadJob, ProcessingJob } from '../connections/sync-context/types';

export const PROCESSING_JOBS: Record<
  ProcessingJob['task'],
  (
    reloadingIn: number,
  ) => {
    runningTitle: string;
    completedTitle: string;
    errorTitle: string;
  }
> = {
  PROCESSING_INVOICE_ACCOUNT_RULES: (reloadingIn: number) => ({
    runningTitle: 'Reprocessing your invoices with new account rules',
    completedTitle: `Reprocessing your invoices with new account rules is complete, Reloading in ${reloadingIn} seconds`,
    errorTitle: 'Reprocessing your invoices with new account rules failed, Please try again later.',
  }),
  PROCESSING_CRM_CLOSED_ON_DATE: (reloadingIn: number) => ({
    runningTitle: 'Reprocessing your Closed On deal values',
    completedTitle: `Reprocessing your Closed On deal values is complete, Reloading in ${reloadingIn} seconds`,
    errorTitle: 'Reprocessing your Closed On deal values failed, Please try again later.',
  }),
  PROCESSING_CRM_CUSTOM_MAPPING_DEAL_VALUES: (reloadingIn: number) => ({
    runningTitle: 'Reprocessing your CRM mapping deal values',
    completedTitle: `Reprocessing your CRM mapping deal values is complete, Reloading in ${reloadingIn} seconds`,
    errorTitle: 'Reprocessing your CRM mapping deal values failed, Please try again later.',
  }),
  PROCESSING_RENEWAL_CONTRACTS: (reloadingIn: number) => ({
    runningTitle: 'Reprocessing your invoices with new renewals config',
    completedTitle: `Reprocessing your invoices with new renewals config is complete, Reloading in ${reloadingIn} seconds`,
    errorTitle: 'Reprocessing your invoices with new renewals config failed, Please try again later.',
  }),
};

export const DOWNLOAD_JOBS: Record<
  DownloadJob['task'],
  {
    downloadTitle: string;
    fileName: string;
  }
> = {
  PROCESSING_INVOICE_LINES_DOWNLOAD: {
    downloadTitle: 'invoice lines',
    fileName: 'customers-invoice-lines.xlsx',
  },
  PROCESSING_CUSTOMERS_REVENUE_DOWNLOAD: {
    downloadTitle: 'customers revenue',
    fileName: 'customers-revenue.xlsx',
  },
  DEALS_IN_PROGRESS_DOWNLOAD: {
    downloadTitle: 'deals in progress',
    fileName: 'deals-in-progress.xlsx',
  },
  ACCRUALS_DOWNLOAD: {
    downloadTitle: 'accruals',
    fileName: 'accruals.xlsx',
  },
  PREPAYMENTS_DOWNLOAD: {
    downloadTitle: 'prepayments',
    fileName: 'prepayments.xlsx',
  },
  INVOICES_ISSUED_IN_DOWNLOAD: {
    downloadTitle: 'invoices issued in month',
    fileName: 'invoices_issued_in.xlsx',
  },
};
