import posthog from 'posthog-js';
import React, { HTMLAttributes, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader, Notification } from 'rsuite';
import Input from 'scalexp/components/atoms/Input';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import { StyledDeleteContainer } from 'scalexp/components/organisms/DashboardChart/DashboardChart.styles';
import { useDeferredRevenueConfigs } from 'scalexp/features/deferred-revenue/deferredRevenueConfigHooks';
import { CUSTOM_NATIVE_METRICS } from 'scalexp/features/report-editor/ReportEditor/usePickerOptions';
import { INVOICED_REVENUE_METRIC_DISPLAY_NAMES, INVOICED_REVENUE_METRIC_IDS } from 'scalexp/service/types/metricSchema';
import { MetricSchema2Simple } from 'scalexp/utils/metrics/metricSchema2';
import styled, { css } from 'styled-components';
import useSWR from 'swr';

import colors from '../../../colors';
import { Chart } from '../../../features/chart/ChartCard/types';
import NewChartModal from '../../../features/chart/NewChartModal';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames, useNativeMetrics } from '../../../store/state/nativeMetrics/hooks';
import { useDeleteChart } from '../../../store/state/newDashboards/hooks';
import { DashboardChartCard } from '../../../store/state/newDashboards/types';
import { DashboardChart } from '../../../store/state/newDashboards/types';
import fetcher from '../../../utils/fetcher';
import Icon from '../../atoms/Icon';
import ShadowedChartCardContainer from '../../atoms/ShadowedChartCardContainer';
import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import { useDashboardContext } from '../../contexts/NewDashboardContext';
import useActiveOrganisationId from '../../contexts/OrganisationContext/useActiveOrganisationId';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import Spacer from '../../layout/Spacer';
import { StyledChartImageContainer } from '../../organisms/ChartConfigWidget';
import { useChartData } from '../../organisms/DashboardChart/useChartData';
import { StyledChartImage } from '../NewChartsDropdown';
import Select from '../Select';
import KpiChartPlaceholder from './KpiChartPlaceholder';
import { getDefaultChart } from './helpers';

type ChartDetails = { name: string; description: string; template_json: Chart; id: number };
type ChartTemplatesResponse = {
  charts: ChartDetails[];
};

export const useChartTemplates = () => {
  const organisationId = useActiveOrganisationId();
  const endpoint = `/api/v1/organisations/${organisationId}/charts/templates`;
  const { data: chartConfigs, isValidating } = useSWR<ChartTemplatesResponse>([endpoint], key => {
    return fetcher(endpoint);
  });
  return { chartConfigs, isValidating };
};

export interface DashboardChartPlaceholderProps {
  card: DashboardChartCard;
}

export interface ChartPlaceholderProps {
  chartCard: DashboardChart;
}

export interface StyledChartPlaceholderProps extends HTMLAttributes<HTMLDivElement> {}

const Description = styled.div`
  margin-right: 5px;
  width: 60%;
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #f8f9fc;
  padding: ${({ theme }) => theme.spacing(5)};
`;

const StyledContent = styled(Column)`
  width: 100%;
  padding: 17px 8px;
  justify-content: flex-start;
`;

const CreateNewChart = styled(TextButton)`
  height: 44px;
`;

const StyledSmartInput = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background: ${theme.palette.white};
    border-radius: 100px;
    padding: 10px 18px;

    & div:first-child {
      flex: 1;
    }
    & input {
      background: ${theme.palette.white};
      border: none;
      outline: none;
    }
  `}
`;

const StyledAvatar = styled.div`
  ${({ theme }) => css`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: ${theme.palette.primary.main};
    color: ${theme.palette.white};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
  `}
`;

const StyledSuggestion = styled(Column)`
  ${({ theme }) => css`
    position: relative;
    width: 100%;
    gap: 12px;
    max-height: 200px;
    overflow: auto;
    padding: ${theme.spacing(2)} ${theme.spacing(8)};
    border-radius: ${theme.spacing(4)};
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: ${theme.palette.white};

    @keyframes fade {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    & *:not(${StyledAvatar}) {
      animation-duration: 0.5s;
      animation-name: fade;
      animation-delay: 0.5s;
      animation-fill-mode: backwards;
    }
  `}
  &  > div:last-child {
    gap: 8px;
    cursor: pointer;
  }

  & > div label {
    cursor: pointer;
  }
`;

const RadioInput = styled(Input)`
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const useKPITemplateOptions = (card: DashboardChartCard) => {
  const organisationId = useActiveOrganisationId();
  const nativeMetricsVS = useNativeMetrics();
  const nativeMetricNamesVS = useNativeMetricNames();
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const nativeMetrics = nativeMetricsVS.value;
  const nativeMetricNames = nativeMetricNamesVS.value;
  const derivedMetrics = derivedMetricsVS.value;

  if (!nativeMetrics || !nativeMetricNames || !derivedMetrics) {
    return {
      value: undefined,
    };
  }

  const nativeMetricsOptions = Object.entries(nativeMetricNames).map(([nativeMetricId, nativeMetricName]) => {
    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: {
            operator: 'native',
            metricId: nativeMetricId,
            isCashflow: false,
          },
        },
      ],
    };
    return {
      label: nativeMetricName,
      description: '',
      group: CUSTOM_NATIVE_METRICS.includes(nativeMetricId) ? 'MANUAL DATA' : 'FINANCIAL CATEGORY',
      value: {
        ...card,
        chart_data: {
          period: 'MONTH',
          value_basis: 'ACTUAL',
          metric_schema: metric,
        },
        name: nativeMetricName,
      },
    };
  });
  const derivedMetricsOptions = Object.entries(derivedMetrics).map(([derivedMetricId, derivedMetric]) => {
    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: {
            operator: 'derived',
            metricId: derivedMetricId,
          },
        },
      ],
    };
    return {
      label: derivedMetric.name,
      description: '',
      group: 'DEFINED METRIC',
      value: {
        ...card,
        chart_data: {
          period: 'MONTH',
          value_basis: 'ACTUAL',
          metric_schema: metric,
        },
        name: derivedMetric.name,
      },
    };
  });
  const revenueMetricOptions = INVOICED_REVENUE_METRIC_IDS.sort((a, b) => a.localeCompare(b)).map(invoicedMetricId => {
    const metric: MetricSchema2Simple = {
      schemaType: 'simple',
      decimals: 0,
      nodes: [
        {
          operator: 'add',
          data: {
            operator: 'invoiced-revenue',
            metricId: invoicedMetricId,
          },
        },
      ],
    };
    return {
      label: INVOICED_REVENUE_METRIC_DISPLAY_NAMES[invoicedMetricId],
      description: '',
      group: 'CUSTOMER PAGE DATA',
      value: {
        ...card,
        chart_data: {
          period: 'MONTH',
          value_basis: 'ACTUAL',
          metric_schema: metric,
        },
        name: INVOICED_REVENUE_METRIC_DISPLAY_NAMES[invoicedMetricId],
      },
    };
  });
  const options = [...nativeMetricsOptions, ...derivedMetricsOptions, ...revenueMetricOptions];
  return {
    value: options,
  };
};

const DashboardChartPlaceholder: React.FC<DashboardChartPlaceholderProps> = ({ card }) => {
  const { chartConfigs, isValidating } = useChartTemplates();
  const { dashboard } = useDashboardContext();

  const organisation = useActiveOrganisation();
  const organisationId = organisation.organisation_id;
  const { isEditMode, chartSetId } = useDashboardContext();
  const deleteChart = useDeleteChart(organisationId);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<string>('');
  const chartSuggestions = useMemo(() => {
    const suggestedChartIds = items.split(', ');
    return chartConfigs?.charts.filter(chart => suggestedChartIds.includes('' + chart.id)) ?? [];
  }, [items]);
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const { data: deferredRevenueConfigs } = useDeferredRevenueConfigs();

  const { value: kpiOptions } = useKPITemplateOptions(card);
  const { mutate } = useChartData(card.i);
  const [showAddChart, setShowAddChart] = useState(false);
  const isKPI = card.chart_type === 'kpi_chart';

  if (isValidating || !chartConfigs || !kpiOptions || !deferredRevenueConfigs) {
    return (
      <ShadowedChartCardContainer>
        <StyledContainer>
          <Loader size="lg" center content="Loading chart templates..." />
        </StyledContainer>
      </ShadowedChartCardContainer>
    );
  }

  const chartsList = chartConfigs.charts.filter(chart => chart.template_json.chart_type !== 'kpi_chart');

  const handleUpdateChartData = async (chart: Chart) => {
    const endpoint = `/api/v1/organisations/${organisationId}/charts/`;
    const result = await fetcher(endpoint, {
      method: 'POST',
      body: JSON.stringify({ ...chart, id: card.i }),
    });
    mutate(result);
  };
  const handleDeleteCard = async () => {
    if (chartSetId) {
      try {
        await deleteChart(chartSetId, card.i);
        Notification.success({ title: 'Chart deleted successfully' });
      } catch (error) {
        Notification.error({ title: "Couldn't delete chart" });
      }
    }
  };
  const chartOptions = chartsList.map(chart => ({
    label: chart.name,
    value: chart.template_json,
    description: chart.description,
  }));

  const createChartFromScratch = async (type: string) => {
    const endpoint = `/api/v1/organisations/${organisationId}/charts/`;

    const defaultChart = getDefaultChart(type);

    const result = await fetcher(endpoint, {
      method: 'POST',
      body: JSON.stringify({ ...defaultChart, id: card!.i }),
    });
    const mutationResult = await mutate(result);
    history.push(`/charts-editor/${mutationResult?.id}/edit?dashboardId=${dashboard.id}&isEditMode=1`);
  };

  const handleGenerate = async () => {
    if (!inputRef.current?.value) {
      return;
    }
    posthog.capture('Interacted with the AI dashboard', {
      organisationId,
    });
    const userInput = inputRef.current.value;

    await (async () => {
      const data = await fetchDashboardSuggestions(userInput);
      if (!data) {
        return;
      }
      setItems(data.trim());
    })();
  };
  const fetchDashboardSuggestions = async (input: string) => {
    setLoading(true);
    try {
      const response: { suggestion: string } | undefined = await fetcher(
        `/api/v1/organisations/${organisationId}/suggestions/dashboard/`,
        {
          method: 'POST',
          body: JSON.stringify({
            messages: [
              {
                role: 'user',
                content: input,
              },
            ],
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      setLoading(false);
      if (response) {
        if (response.suggestion === null) {
          Notification.error({
            title: 'Something went wrong. Please try again.',
            placement: 'bottomEnd',
          });
          return;
        }
        return response.suggestion;
      }
    } catch (error) {
      setLoading(false);
      Notification.error({
        title: 'Something went wrong. Please try again.',
        placement: 'bottomEnd',
      });
      console.error(error);
    }
  };

  return (
    <ShadowedChartCardContainer>
      {isEditMode && (
        <StyledDeleteContainer onClick={handleDeleteCard}>
          <Icon name="delete" color={colors.white} size={3.5} outlined marginRight={0} tooltipText="Delete chart" />
        </StyledDeleteContainer>
      )}
      <StyledContainer>
        {isKPI ? (
          <KpiChartPlaceholder handleUpdateChartData={handleUpdateChartData} card={card} />
        ) : (
          <StyledContent spacing="large">
            <Typography size="h6" weight="regular">
              Select Chart
            </Typography>
            <Row width="100%" wrap hAlign="space-between" onMouseDown={e => e.stopPropagation()}>
              <Select
                data={chartOptions}
                size="xlarge"
                width="360px"
                placeholder="Select existing  chart "
                searchable
                disabled={!isEditMode}
                renderSelectItem={(_, item) => (
                  <Row
                    key={item.label}
                    vAlign="center"
                    width="100%"
                    // @ts-ignore TODO: Find out why are we getting this error later
                    onClick={() => handleUpdateChartData(item.value)}
                  >
                    <Spacer flex margin={{ top: 3, bottom: 3, left: 4, right: 4 }}>
                      <Description>
                        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{item.label}</p>
                        <p style={{ fontSize: 11 }}>{item.description}</p>
                      </Description>
                    </Spacer>
                    <StyledChartImageContainer>
                      <StyledChartImage src={` /images/charts/${item.value.chart_type}.png`} alt="chart" />
                    </StyledChartImageContainer>
                  </Row>
                )}
              />
              <CreateNewChart
                onMouseDown={e => e.stopPropagation()}
                onClick={() => setShowAddChart(true)}
                size="xlarge"
                variant="secondary"
                width="155x"
              >
                <Typography size="medium">Create new chart</Typography>
              </CreateNewChart>
            </Row>
            <Typography noMargin size="medium" weight="regular">
              Or type a few keywords or phrases to find charts for your dashboard.
            </Typography>

            <Column width="100%" spacing="small">
              <StyledSmartInput>
                <Input
                  onMouseDown={e => e.stopPropagation()}
                  ref={inputRef}
                  onKeyDown={event => {
                    if (event.key === 'Enter' && !loading) {
                      handleGenerate();
                    }
                  }}
                  placeholder="e.g. I want a set of KPIs for my client. They are a marketing agency."
                />
                <TextButton
                  disabled={loading}
                  onClick={handleGenerate}
                  loadingText="Generating..."
                  onMouseDown={e => e.stopPropagation()}
                  loading={loading}
                  width="130px"
                  size="medium"
                  iconRight="auto_fix_normal"
                >
                  Search
                </TextButton>
              </StyledSmartInput>
              {chartSuggestions.length > 0 ? (
                <Row hAlign="flex-start" width="100%">
                  <StyledAvatar>AI</StyledAvatar>
                  <StyledSuggestion>
                    <Typography> I suggest one the following charts: </Typography>
                    <Column spacing="small" width="100%">
                      {chartSuggestions.map(chart => {
                        return (
                          <Row vAlign="center" width="100%">
                            <RadioInput
                              type="radio"
                              onMouseDown={e => e.stopPropagation()}
                              id={`chart-${chart.id}`}
                              name="menu"
                              value={chart.id}
                              onClick={() => handleUpdateChartData(chart.template_json)}
                            />
                            <label
                              htmlFor={`chart-${chart.id}`}
                              onClick={() => handleUpdateChartData(chart.template_json)}
                            >
                              {chart?.name}
                            </label>
                          </Row>
                        );
                      })}
                    </Column>
                  </StyledSuggestion>
                </Row>
              ) : (
                items !== '' &&
                !loading && (
                  <Row hAlign="flex-start" width="100%">
                    <StyledAvatar>AI</StyledAvatar>
                    <StyledSuggestion>
                      <Typography>{items}</Typography>
                    </StyledSuggestion>
                  </Row>
                )
              )}
            </Column>
          </StyledContent>
        )}
        <NewChartModal
          createChartFromScratch={createChartFromScratch}
          showModal={showAddChart}
          onHide={() => setShowAddChart(false)}
        />
      </StyledContainer>
    </ShadowedChartCardContainer>
  );
};

export default DashboardChartPlaceholder;
