import dayjs from 'dayjs';
import { KPIChart } from 'scalexp/features/chart/ChartCard/types';
import { MetricSchema2DataType } from 'scalexp/utils/metrics/metricSchema2';

import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import useActiveOrganisationId from '../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import useCurrencySelection from '../../../components/molecules/CurrencySelect/useCurrencySelection';
import useDateSelection from '../../../components/molecules/DateSelect/useDateSelection';
import { INVOICED_REVENUE_METRIC_DISPLAY_NAMES } from '../../../service/types/metricSchema';
import { useBudgetSets } from '../../../store/state/budgetsets/hooks';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames, useNativeMetrics } from '../../../store/state/nativeMetrics/hooks';
import { getFiscalQuarter } from '../../../utils/dates';
import { CalculationResultImpact } from '../../../utils/metrics/calculationRequestManager';
import { DateKeys, dateToRequest } from '../../../utils/metrics/calculations';
import useMetricSchemaSeries from '../../../utils/metrics/useMetricSchemaSeries';
import { formatValue } from '../../data-provenance/metric-Schema-viewer/utils';
import { getDateKey } from '../ChartCard/helpers';

const QUARTER_COUNT = 3;
const YEAR_COUNT = 12;

type KPIConfigSuccess = {
  status: 'success';
  value: {
    impact: CalculationResultImpact;
    formattedValue: string;
    changeValue: number;
    formattedChangeValue: string;
    metricName: string;
    currentMonthDate: string;
    dateString: string;
    budgetName: string;
  };
};

type KPIConfigPending = {
  status: 'pending' | 'error';
  value: null;
};
type KPIConfig = KPIConfigSuccess | KPIConfigPending;

const useKPIConfig = (config: KPIChart): KPIConfig => {
  const [currencyCode] = useCurrencySelection();
  const [date] = useDateSelection('date');
  const organisationId = useActiveOrganisationId();

  const {
    consolidation_type,
    financial_year_start: financialYearStart,
    default_budget_set_id,
    default_consolidated_budget_set_id,
    default_currency_code: defaultCurrencyCode,
  } = useOrganisationContext();
  const metricSchema = config.chart_data.metric_schema;
  const period = config.chart_data.period;
  const defaultBudgetId = consolidation_type === 'PARENT' ? default_consolidated_budget_set_id : default_budget_set_id;

  const currency = currencyCode || defaultCurrencyCode;

  const nativeMetricsVS = useNativeMetrics();
  const nativeMetricNamesVS = useNativeMetricNames();

  const budgetId = consolidation_type === 'PARENT' ? config.chart_data.consolidated_budget : config.chart_data.budget;
  const requestBudgetId = config.chart_data.versus === 'BUDGET' ? budgetId ?? defaultBudgetId : undefined;
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const yearsToRequest = [
    dayjs(date as string).format('YYYY'),
    dayjs(date as string)
      .subtract(1, 'year')
      .format('YYYY'),
  ];
  const previousQuarter = dayjs(date as string).subtract(1, 'quarter');
  const quartersToRequest = [
    `${dayjs(date as string).format('YYYY')}-Q${dayjs(date as string).quarter()}`,
    `${previousQuarter.format('YYYY')}-Q${previousQuarter.quarter()}`,
  ];
  const dateInstance = date ? dayjs(date) : dayjs().subtract(1, 'month');
  const previousPeriodCount = period === 'YEAR' ? YEAR_COUNT : period === 'QUARTER' ? QUARTER_COUNT : 1;
  const previousPeriodDateInstance = dateInstance.subtract(previousPeriodCount, 'month');
  const { fiscalYear, fiscalQuarter } = getFiscalQuarter(dateInstance, 0, financialYearStart);
  const { fiscalYear: previousPeriodFiscalYear, fiscalQuarter: previousPeriodFiscalQuarter } = getFiscalQuarter(
    previousPeriodDateInstance,
    0,
    financialYearStart,
  );
  const currentDateKey = getDateKey(period, fiscalYear, fiscalQuarter, dateInstance);
  const previousPeriodDateKey = getDateKey(
    period,
    previousPeriodFiscalYear,
    previousPeriodFiscalQuarter,
    previousPeriodDateInstance,
  );

  const metricSchemaSeriesVS = useMetricSchemaSeries(
    metricSchema,
    dateToRequest(
      (period === 'MONTH'
        ? [currentDateKey, previousPeriodDateKey]
        : period === 'YEAR'
        ? yearsToRequest
        : quartersToRequest) as DateKeys,
      //if budget provided then it's source budget if it's undefined then it's actual source
      requestBudgetId ? [requestBudgetId] : undefined,
      currency,
    ),
  );

  const budgetDetailsVS = useBudgetSets(organisationId);
  const nativeMetrics = nativeMetricsVS.value;
  const nativeMetricNames = nativeMetricNamesVS.value;
  const derivedMetrics = derivedMetricsVS.value;
  const metricSchemaSeries = metricSchemaSeriesVS.value;
  let metricName = '';
  const firstNode = metricSchema.nodes[0];
  const dataNode = firstNode && 'data' in firstNode && firstNode.data;

  if (
    metricSchemaSeriesVS.status !== 'success' ||
    nativeMetricsVS.status !== 'success' ||
    nativeMetricNamesVS.status !== 'success' ||
    budgetDetailsVS.status !== 'success' ||
    derivedMetricsVS.status !== 'success'
  ) {
    return {
      status: 'pending',
      value: null,
    };
  }
  const selectedBudget = budgetDetailsVS.value.find(budget => budget.id === budgetId);
  let budgetName = 'budget';
  if (selectedBudget) {
    budgetName = selectedBudget.name.toLowerCase();
  }

  if (!metricSchemaSeries || !nativeMetrics || !nativeMetricNames || !derivedMetrics) {
    return {
      status: 'error',
      value: null,
    };
  }
  if (dataNode && dataNode.operator === 'derived') {
    metricName = derivedMetrics[dataNode.metricId].name;
  } else if (dataNode && dataNode.operator === 'native') {
    metricName = nativeMetricNames[dataNode.metricId];
  } else if (dataNode && dataNode.operator === 'invoiced-revenue') {
    metricName = INVOICED_REVENUE_METRIC_DISPLAY_NAMES[dataNode.metricId];
  }

  let currentValue = 0;
  let previousValue = 0;
  let dataType: MetricSchema2DataType = metricSchemaSeries[0]?.data_type || 'monetary';
  let impact = metricSchemaSeries[0]?.impact || 'neutral';

  if (metricSchemaSeries[0]) {
    currentValue = Number(metricSchemaSeries[0].value);
  }
  if (metricSchemaSeries[1]) {
    previousValue = Number(metricSchemaSeries[1].value);
  }
  if (requestBudgetId !== undefined && metricSchemaSeries[2]) {
    previousValue = Number(metricSchemaSeries[2].value);
  }

  const denominatorValue = previousValue && currentValue ? currentValue - previousValue : 0;
  let changeValue = 0;
  let formattedChangeValue = '';
  if (config.chart_data.versus_type === 'ABSOLUTE') {
    changeValue = currentValue - previousValue;
    formattedChangeValue = formatValue(changeValue, dataType, currency, config.chart_data.metric_schema.decimals);
  } else {
    changeValue = (currentValue && previousValue ? denominatorValue / Math.abs(previousValue) : 0) * 100;
    formattedChangeValue = `${changeValue.toFixed(config.chart_data.metric_schema.decimals)}%`;
  }
  const formattedValue = formatValue(currentValue, dataType, currency, config.chart_data.metric_schema.decimals);

  return {
    status: 'success',
    value: {
      impact,
      formattedValue,
      changeValue,
      formattedChangeValue,
      metricName,
      currentMonthDate: dateInstance.format('YYYY-MM'),
      budgetName,
      dateString: `${
        period === 'YEAR' ? fiscalYear : period === 'QUARTER' ? `Q${fiscalQuarter}` : dateInstance.format('MMM')
      }`,
    },
  };
};

export default useKPIConfig;
