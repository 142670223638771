import React, { HTMLAttributes, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import RenameInput from 'scalexp/components/organisms/RenameInput';
import styled, { css } from 'styled-components';

import Icon from '../../atoms/Icon';
import Spacer from '../../layout/Spacer';
import { useOutsideAlerter } from '../MultiSelect/hooks';
import Tooltip from '../Tooltip';

export interface SidebarItemProps extends HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  handleRename?: (name: string) => void;
  name?: string;
  to?: string;
  iconTooltip?: string;
  icon?: string;
  grayed?: boolean;
  disabled?: boolean;
}

const StyledSidebarItem = styled.div<SidebarItemProps>`
  height: 40px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.palette.granite};
  font-size: ${({ theme }) => theme.font.size.small};
  line-height: ${({ theme }) => theme.font.lineheight.small};
  padding: 0 ${({ theme }) => theme.spacing(4)};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;

  & > *:first-child {
    flex: 1 0 auto;
  }
  & > *:last-child {
    flex: 1 0 auto;
  }

  ${({ active, theme }) =>
    active
      ? css`
          color: ${theme.palette.white};
          background-color: ${theme.palette.primary.main};
          font-weight: ${theme.font.weight.medium};
          border-radius: ${theme.sizing(2)};
        `
      : ''}

  &:hover {
    ${({ theme, active }) =>
      active
        ? ''
        : css`
            color: ${theme.palette.primary.main};
            background-color: ${theme.palette.white};
            font-weight: ${theme.font.weight.medium};
            border-radius: ${theme.sizing(2)};
          `}
  }

  ${({ grayed, active }) => (grayed && !active ? 'opacity: 0.2;' : '')}

  ${({ disabled }) =>
    disabled
      ? css`
          opacity: 0.2;
          cursor: not-allowed;
        `
      : ''}
`;

const ChildrenContainer = styled.div`
  flex: 2 1 auto;
  width: 80%;
  overflow: auto;
`;

const StyledLink = styled(Link)`
  width: 100%;
  &,
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
`;

const SidebarItem: React.FC<React.PropsWithChildren<SidebarItemProps>> = ({
  active,
  handleRename,
  children,
  name,
  iconTooltip = '',
  to,
  icon,
  grayed,
  disabled,
  ...other
}) => {
  const containerRef = useRef(null);

  const [sideBarEditMode, setSidebarEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useOutsideAlerter(containerRef, () => setSidebarEditMode(false));

  useEffect(() => {
    setSidebarEditMode(false);
  }, [active]);

  if (to && !disabled) {
    const saveRename = async (value: string) => {
      try {
        setIsLoading(true);
        await handleRename!(value);
        setIsLoading(false);
        setSidebarEditMode(false);
      } catch (error) {
        setIsLoading(false);
        setSidebarEditMode(false);
        console.log(error);
      }
    };

    return (
      <StyledLink to={to} ref={containerRef}>
        <StyledSidebarItem active={active} grayed={grayed} {...other}>
          {!sideBarEditMode &&
            icon &&
            (icon === 'none' ? (
              <Spacer margin={{ right: 8 }} />
            ) : (
              <Tooltip trigger="hover" placement="right" content={iconTooltip}>
                <Icon rounded color="inherit" marginRight={3} name={icon} size={5} />
              </Tooltip>
            ))}

          {active && handleRename && sideBarEditMode ? (
            <RenameInput autoFocus defaultValue={name} width="100%" onDone={saveRename} isLoading={isLoading} />
          ) : (
            <ChildrenContainer>{children}</ChildrenContainer>
          )}
          {active && handleRename && !sideBarEditMode && (
            <Icon
              clickable
              tooltipText="Rename"
              onClick={() => {
                setSidebarEditMode(prev => !prev);
              }}
              name={'edit'}
              rounded
              color="inherit"
            />
          )}
        </StyledSidebarItem>
      </StyledLink>
    );
  }

  return (
    <StyledSidebarItem active={active} grayed={grayed} disabled={disabled} {...other}>
      {icon && <Icon color="inherit" marginRight={3} name={icon} size={5} />}
      {children}
    </StyledSidebarItem>
  );
};

export default SidebarItem;
