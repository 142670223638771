import debounce from 'lodash/debounce';
import memoize from 'lodash/memoize';
import property from 'lodash/property';
import wrap from 'lodash/wrap';
import { PayloadAction, PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectNativeMetrics } from './selectors';
import { NativeMetric, NativeMetrics } from './types';

export const ORGANISATIONS_NATIVE_METRICS_LOAD = '[nativeMetrics] LOAD';
export const ORGANISATIONS_NATIVE_METRICS_UPDATE = '[nativeMetrics] UPDATE';

type NativeMetricResponse = {
  metrics: NativeMetric[];
};

export type NativeMetricsActionsLoad = PromiseAction<
  typeof ORGANISATIONS_NATIVE_METRICS_LOAD,
  NativeMetrics,
  { organisationId: number }
>;
type NativeMetricsActionUpdate = PayloadAction<
  typeof ORGANISATIONS_NATIVE_METRICS_UPDATE,
  { organisationId: number; tagName: string; nativeMetric: NativeMetric }
>;

export type NativeMetricsActions = NativeMetricsActionsLoad | NativeMetricsActionUpdate;

export const loadNativeMetricsAction = (organisationId: number): ThunkAction<NativeMetricsActions> => async (
  dispatch: ThunkDispatch<NativeMetricsActions>,
  getState,
) => {
  const state = getState();
  const nativeMetrics = selectNativeMetrics(state, organisationId);

  if (nativeMetrics?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_NATIVE_METRICS_LOAD,
        () =>
          http<NativeMetric[], NativeMetricResponse>(
            `/api/v1/organisations/${organisationId}/native_metrics`,
            {},
            response => response.metrics,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export type UpdateNativeMetricActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      native_metric: NativeMetric;
    };

type UpdateParams = { organisationId: number; tagName: string; nativeMetric: NativeMetric };
const debouncedUpdate = wrap(
  memoize(
    (updateParams: UpdateParams) =>
      debounce(
        async (updateParams: UpdateParams) =>
          await http(`/api/v1/organisations/${updateParams.organisationId}/native_metrics/${updateParams.tagName}`, {
            method: 'POST',
            body: updateParams.nativeMetric,
          }),
        500,
      ),
    property('tagName'),
  ),
  (getMemoizedFunc, updateParams: UpdateParams) => getMemoizedFunc(updateParams)(updateParams),
);

export const updateNativeMetricAction = (
  organisationId: number,
  tagName: string,
  nativeMetric: NativeMetric,
): ThunkAction<NativeMetricsActionUpdate> => async (dispatch: ThunkDispatch<NativeMetricsActions>, getState) => {
  const state = getState();
  const nativeMetricsVS = selectNativeMetrics(state, organisationId);

  if (nativeMetricsVS?.status !== 'success') {
    return;
  }

  const nativeMetrics = nativeMetricsVS.value;
  const oldNativeMetric = nativeMetrics[tagName];

  try {
    dispatch({
      type: ORGANISATIONS_NATIVE_METRICS_UPDATE,
      params: {
        organisationId,
        tagName,
        nativeMetric,
      },
    });

    await debouncedUpdate({ organisationId, tagName, nativeMetric });
  } catch (e) {
    console.log('Error dispatching action', e);

    // TODO: show error message
    dispatch({
      type: ORGANISATIONS_NATIVE_METRICS_UPDATE,
      params: {
        organisationId,
        tagName,
        nativeMetric: oldNativeMetric,
      },
    });
  }
};
