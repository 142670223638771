import React from 'react';
import Row from 'scalexp/components/layout/Row';
import styled, { css } from 'styled-components';

import { StyledBodyText } from '../../../components/atoms/Typography';
import LimitedTextWithTooltip from '../../../components/molecules/LimitedTextWithTooltip';

export const StyledChartTitleContainer = styled.div`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 14px 14px 0;
    `;
  }}
`;

export const StyledChartTitle = styled.div<{ withHeaderConfig?: boolean }>`
  ${({ theme, withHeaderConfig }) => css`
    ${StyledBodyText}:first-child {
      font-weight: ${theme.font.weight.bold} !important;
      font-size: ${theme.font.size.medium} !important;
      line-height: ${theme.font.lineheight.small} !important;
      max-width: ${withHeaderConfig ? '240px' : '100%'};
    }
    color: ${theme.palette.midnight};
    display: flex;
    gap: 14px;
    align-items: center;
  `}
`;

export interface ChartTitleProps {
  title: string;
  rightContent?: React.ReactNode;
  dataProvenanceContent?: React.ReactNode;
  headerConfig?: React.ReactNode;
}

const ChartTitle: React.FC<ChartTitleProps> = ({ title, rightContent, dataProvenanceContent, headerConfig }) => {
  return (
    <StyledChartTitleContainer>
      <Row spacing="small" vAlign="center" width="100%" wrap>
        <StyledChartTitle withHeaderConfig={headerConfig !== undefined}>
          <LimitedTextWithTooltip
            text={title}
            textProps={{ color: 'black', size: 'small' }}
            wordsCount={headerConfig ? 9 : 12}
          />
          {dataProvenanceContent}
        </StyledChartTitle>
        <div>{headerConfig}</div>
      </Row>

      {rightContent}
    </StyledChartTitleContainer>
  );
};
export default ChartTitle;
