import { Dayjs } from 'dayjs';

import { getFiscalQuarter } from '../../../utils/dates';
import { Period, TimeSeries } from './types';

const QUARTER_PERIODS = 3;
const YEAR_PERIODS = 12;

export const getDateKey = (period: Period, year: string, quarter: number, date: Dayjs) => {
  let dateKey = date.format('YYYY-MM');
  if (period === 'QUARTER') {
    dateKey = `${year}-Q${quarter}`;
  }
  if (period === 'QUARTER_TO_DATE') {
    dateKey = `${year}-QTD${quarter}`;
  }
  if (period === 'YEAR') {
    dateKey = `${year}`;
  }
  if (period === 'YEAR_TO_DATE') {
    dateKey = `${year}-YTD`;
  }
  return dateKey;
};

export function getDateBounds(series: TimeSeries[], period: Period, focalDate: Dayjs, financialYearStart?: number) {
  // map the series and create a set of date keys
  let dateKeys = series.flatMap(serie => {
    const multiplier =
      period === 'MONTH' ? 1 : period === 'QUARTER' || period === 'QUARTER_TO_DATE' ? QUARTER_PERIODS : YEAR_PERIODS;

    return Array.from({ length: serie.periods }, (_, i) => {
      const offset = i - serie.offset;
      const multipliedOffset = offset * multiplier;
      const { fiscalYear, fiscalQuarter } = getFiscalQuarter(focalDate, -multipliedOffset, financialYearStart);
      return getDateKey(period, fiscalYear, fiscalQuarter, focalDate.subtract(multipliedOffset, 'month'));
    }).reverse();
  });

  // remove duplicates
  return Array.from(new Set(dateKeys));
}
