import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectPrepaymentsPresets } from './selectors';
import { PrepaymentsPreset, PrepaymentsPresets } from './types';

export const ORGANISATIONS_PREPAYMENTS_PRESETS_LOAD = '[prepaymentsPresets] LOAD';
export const ORGANISATIONS_PREPAYMENTS_PRESETS_CREATE = '[prepaymentsPresets] CREATE';
export const ORGANISATIONS_PREPAYMENTS_PRESETS_UPDATE = '[prepaymentsPresets] UPDATE';
export const ORGANISATIONS_PREPAYMENTS_PRESETS_DELETE = '[prepaymentsPresets] DELETE';

type PrepaymentsPresetsResponse = PrepaymentsPresets;
type CreatePrepaymentsPresetResponse = PrepaymentsPresets;
type UpdatePrepaymentsPresetResponse = PrepaymentsPresets;
type DeletePrepaymentsPresetResponse = undefined;

type PrepaymentsPresetsActionLoad = PromiseAction<
  typeof ORGANISATIONS_PREPAYMENTS_PRESETS_LOAD,
  PrepaymentsPresets | undefined,
  { organisationId: number }
>;
type PrepaymentsPresetsActionCreate = PromiseAction<
  typeof ORGANISATIONS_PREPAYMENTS_PRESETS_CREATE,
  CreatePrepaymentsPresetResponse,
  { organisationId: number; presetId: string }
>;
type PrepaymentsPresetsActionUpdate = PromiseAction<
  typeof ORGANISATIONS_PREPAYMENTS_PRESETS_UPDATE,
  UpdatePrepaymentsPresetResponse,
  { organisationId: number; presetId: string }
>;
type PrepaymentsPresetsActionDelete = PromiseAction<
  typeof ORGANISATIONS_PREPAYMENTS_PRESETS_DELETE,
  DeletePrepaymentsPresetResponse,
  { organisationId: number; presetId: string }
>;
export type PrepaymentsPresetsActions =
  | PrepaymentsPresetsActionLoad
  | PrepaymentsPresetsActionCreate
  | PrepaymentsPresetsActionUpdate
  | PrepaymentsPresetsActionDelete;

export const loadPrepaymentsPresetsAction = (organisationId: number): ThunkAction<PrepaymentsPresetsActions> => async (
  dispatch: ThunkDispatch<PrepaymentsPresetsActions>,
  getState,
) => {
  const state = getState();
  const prepaymentsPresets = selectPrepaymentsPresets(state, organisationId);

  if (prepaymentsPresets?.status === 'pending') {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_PREPAYMENTS_PRESETS_LOAD,
        () =>
          http<PrepaymentsPresets | undefined, PrepaymentsPresetsResponse>(
            `/api/v1/organisations/${organisationId}/insights/prepayment/presets`,
            {},
            response => response,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export type CreatePrepaymentsPresetActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      suppliers_preset: PrepaymentsPreset;
    };

export type UpdatePrepaymentsPresetActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      suppliers_preset: PrepaymentsPreset;
    };

export type DeletePrepaymentsPresetActionResult =
  | {
      success: false;
    }
  | {
      success: true;
      suppliers_preset: PrepaymentsPreset;
    };

export const createPrepaymentsPresetAction = (
  organisationId: number,
  prepaymentsPreset: PrepaymentsPreset,
): ThunkAction<PrepaymentsPresetsActionCreate, Promise<CreatePrepaymentsPresetActionResult>> => async (
  dispatch: ThunkDispatch<PrepaymentsPresetsActions>,
  getState,
) => {
  const state = getState();
  const prepaymentsPresets = selectPrepaymentsPresets(state, organisationId);

  if (prepaymentsPresets?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const fetchedPrepaymentsPreset = await dispatch(
      promiseAction(
        ORGANISATIONS_PREPAYMENTS_PRESETS_CREATE,
        async () =>
          http<PrepaymentsPreset, CreatePrepaymentsPresetResponse>(
            `/api/v1/organisations/${organisationId}/insights/prepayment/presets`,
            {
              method: 'POST',
              body: {
                ...prepaymentsPreset,
                id: undefined,
              },
            },
          ),
        {
          organisationId,
          prepaymentsPreset,
        },
      ),
    );
    return {
      success: true,
      suppliers_preset: fetchedPrepaymentsPreset,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const updatePrepaymentsPresetAction = (
  organisationId: number,
  presetId: string,
  prepaymentsPreset: PrepaymentsPreset,
): ThunkAction<PrepaymentsPresetsActionUpdate, Promise<UpdatePrepaymentsPresetActionResult>> => async (
  dispatch: ThunkDispatch<PrepaymentsPresetsActions>,
  getState,
) => {
  const state = getState();
  const prepaymentsPresets = selectPrepaymentsPresets(state, organisationId);

  if (prepaymentsPresets?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const fetchedPrepaymentsPreset = await dispatch(
      promiseAction(
        ORGANISATIONS_PREPAYMENTS_PRESETS_UPDATE,
        async () =>
          http<PrepaymentsPreset, UpdatePrepaymentsPresetResponse>(
            `/api/v1/organisations/${organisationId}/insights/prepayment/presets/${presetId}`,
            {
              method: 'PUT',
              body: prepaymentsPreset,
            },
          ),
        {
          organisationId,
          presetId,
        },
      ),
    );
    return {
      success: true,
      suppliers_preset: fetchedPrepaymentsPreset,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};

export const deletePrepaymentsPresetAction = (
  organisationId: number,
  presetId: string,
  prepaymentsPreset: PrepaymentsPreset,
): ThunkAction<PrepaymentsPresetsActionDelete, Promise<DeletePrepaymentsPresetActionResult>> => async (
  dispatch: ThunkDispatch<PrepaymentsPresetsActions>,
  getState,
) => {
  const state = getState();
  const prepaymentsPresets = selectPrepaymentsPresets(state, organisationId);

  if (prepaymentsPresets?.status === 'pending') {
    return {
      success: false,
    };
  }

  try {
    const deletedPrepaymentsPreset = await dispatch(
      promiseAction(
        ORGANISATIONS_PREPAYMENTS_PRESETS_DELETE,
        async () =>
          http<PrepaymentsPreset, DeletePrepaymentsPresetResponse>(
            `/api/v1/organisations/${organisationId}/insights/revenue_journal/presets/${presetId}`,
            {
              method: 'DELETE',
              body: {
                name: prepaymentsPreset.name,
              },
            },
          ),
        {
          organisationId,
          prepaymentsPreset,
        },
      ),
    );
    return {
      success: true,
      suppliers_preset: deletedPrepaymentsPreset,
    };
  } catch (e) {
    console.log('Error dispatching action', e);
  }
  return {
    success: false,
  };
};
