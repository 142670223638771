import { PayloadAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { Report, Row } from '../reports/types';

export const ACTION_EDITED_REPORT_UPDATE = '[editedReport] UPDATE';

export const ACTION_EDITED_REPORT_SET_SELECTED_ROWID = '[editedReport] SET_SELECTED_ROWID';
export const ACTION_EDITED_REPORT_SET_ADDED_COMPUTATION_ROWID = '[editedReport] SET_ADDED_CCOMPUTATION_ROWID';
export const ACTION_EDITED_REPORT_ADD_ROW = '[editedReport] ADD_ROW';
export const ACTION_EDITED_REPORT_UPDATE_ROW = '[editedReport] UPDATE_ROW';
export const ACTION_EDITED_REPORT_DELETE_ROW = '[editedReport] DELETE_ROW';

export type EditedReportActionUpdate = PayloadAction<
  typeof ACTION_EDITED_REPORT_UPDATE,
  { report: Report | null; isDirty: boolean }
>;

export type EditedReportActionSetSelectedRowId = PayloadAction<
  typeof ACTION_EDITED_REPORT_SET_SELECTED_ROWID,
  { rowId: string | null }
>;
export type EditedReportActionSetAddedRowId = PayloadAction<
  typeof ACTION_EDITED_REPORT_SET_ADDED_COMPUTATION_ROWID,
  { rowId: string | null }
>;
export type EditedReportActionAddRow = PayloadAction<typeof ACTION_EDITED_REPORT_ADD_ROW, { row: Row }>;
export type EditedReportActionUpdateRow = PayloadAction<typeof ACTION_EDITED_REPORT_UPDATE_ROW, { row: Row }>;
export type EditedReportActionDeleteRow = PayloadAction<typeof ACTION_EDITED_REPORT_DELETE_ROW, { rowId: string }>;

export type EditedReportActions =
  | EditedReportActionUpdate
  | EditedReportActionSetSelectedRowId
  | EditedReportActionSetAddedRowId
  | EditedReportActionAddRow
  | EditedReportActionUpdateRow
  | EditedReportActionDeleteRow;

export const updateEditedReportAction = (
  report: Report | null,
  isDirty = true,
): ThunkAction<EditedReportActionUpdate> => async (dispatch: ThunkDispatch<EditedReportActions>) => {
  dispatch({ type: ACTION_EDITED_REPORT_UPDATE, params: { report, isDirty } });
};

export const setEditedReportSelectedRowIdAction = (
  rowId: string | null,
): ThunkAction<EditedReportActionSetSelectedRowId> => async (dispatch: ThunkDispatch<EditedReportActions>) => {
  dispatch({ type: ACTION_EDITED_REPORT_SET_SELECTED_ROWID, params: { rowId } });
};

export const setEditedReportAddedComputationRowIdAction = (
  rowId: string | null,
): ThunkAction<EditedReportActionSetAddedRowId> => async (dispatch: ThunkDispatch<EditedReportActions>) => {
  dispatch({ type: ACTION_EDITED_REPORT_SET_ADDED_COMPUTATION_ROWID, params: { rowId } });
};

export const addEditedReportRowAction = (row: Row): ThunkAction<EditedReportActionAddRow> => async (
  dispatch: ThunkDispatch<EditedReportActions>,
) => {
  dispatch({ type: ACTION_EDITED_REPORT_ADD_ROW, params: { row } });
};

export const updateEditedReportRowAction = (row: Row): ThunkAction<EditedReportActionUpdateRow> => async (
  dispatch: ThunkDispatch<EditedReportActions>,
) => {
  dispatch({ type: ACTION_EDITED_REPORT_UPDATE_ROW, params: { row } });
};

export const deleteEditedReportRowAction = (rowId: string): ThunkAction<EditedReportActionDeleteRow> => async (
  dispatch: ThunkDispatch<EditedReportActions>,
) => {
  dispatch({ type: ACTION_EDITED_REPORT_DELETE_ROW, params: { rowId } });
};
