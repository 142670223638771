import React, { useState } from 'react';
import { ChartsSidebarWrapper } from 'scalexp/features/chart-editor/NewChartsSidebar/NewChartsSidebar.styles';

import Loader from '../../../components/atoms/Loader';
import { useEditChartContext } from '../../../components/contexts/NewEditChartContext';
import { useOrganisationContext } from '../../../components/contexts/OrganisationContext';
import Column from '../../../components/layout/Column';
import Message from '../../../components/molecules/Message';
import Switcher from '../../../components/molecules/Switcher';
import { useBudgetSets } from '../../../store/state/budgetsets/hooks';
import { useConsolidatedBudgetSets } from '../../../store/state/consolidatedBudgetsets/hooks';
import { useDerivedMetrics } from '../../../store/state/derivedMetrics/hooks';
import { useNativeMetricNames } from '../../../store/state/nativeMetrics/hooks';
import ChartConfiguration from '../../chart/ChartConfiguration';
import KPIChartOptions from '../KPIChartOptions';
import PieChartOptions from '../PieChartOptions';
import TimeChartOptions from '../TimeChartOptions';
import WaterfallChartOptions from '../WaterfallChartOptions';

export interface ChartsSidebarProps {}

const ChartsSidebar: React.FC<ChartsSidebarProps> = () => {
  const organisation = useOrganisationContext();
  const [activeMenu, setActiveMenu] = useState<'CHART_SERIES_CONFIGURATION' | 'CHART_CONFIGURATION'>(
    'CHART_SERIES_CONFIGURATION',
  );
  const organisationId = organisation.organisation_id;
  const { chart } = useEditChartContext();
  const nativeMetricNamesVS = useNativeMetricNames();
  const derivedMetricsVS = useDerivedMetrics(organisationId);
  const budgetSetsVS = useBudgetSets(organisationId);
  const consolidatedbudgetSetsVS = useConsolidatedBudgetSets(organisationId);

  if (
    nativeMetricNamesVS.status === 'pending' ||
    derivedMetricsVS.status === 'pending' ||
    budgetSetsVS.status === 'pending' ||
    consolidatedbudgetSetsVS.status === 'pending'
  ) {
    return <Loader content="Loading..." />;
  }

  if (
    nativeMetricNamesVS.status === 'error' ||
    derivedMetricsVS.status === 'error' ||
    budgetSetsVS.status === 'error' ||
    consolidatedbudgetSetsVS.status === 'error'
  ) {
    return <Message variant="danger"> Couldn't load metric names.</Message>;
  }

  const budgetOptions = [
    { label: 'default Budget', value: null },
    ...budgetSetsVS.value.map(budgetSet => ({ label: budgetSet.name, value: budgetSet.id })),
  ];
  const consolidatedBudgetOptions = [
    { label: 'default Budget', value: null },
    ...consolidatedbudgetSetsVS.value?.map(budgetSet => ({
      label: budgetSet.name,
      value: budgetSet.id,
    })),
  ];
  const isPieChart = chart.chart_type === 'pie_chart';
  const isWaterfallChart = chart.chart_type === 'waterfall_chart';
  const isTimeChart = chart.chart_type === 'time_chart';
  const isKPIChart = chart.chart_type === 'kpi_chart';

  return (
    <ChartsSidebarWrapper role="navigation">
      <Column width="100%">
        <Switcher
          width="100%"
          size="medium"
          value={activeMenu}
          onChange={(value: 'CHART_SERIES_CONFIGURATION' | 'CHART_CONFIGURATION') => setActiveMenu(value)}
          data={[
            { label: isKPIChart ? 'KPI Series' : 'Chart Series', value: 'CHART_SERIES_CONFIGURATION' },
            { label: `${isKPIChart ? 'KPI Name' : 'Chart Name'} & Settings`, value: 'CHART_CONFIGURATION' },
          ]}
        />
      </Column>
      <Column width="100%">
        {activeMenu === 'CHART_CONFIGURATION' ? (
          <ChartConfiguration isKPIChart={isKPIChart} />
        ) : isTimeChart ? (
          <TimeChartOptions
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            chart={chart}
          />
        ) : isKPIChart ? (
          <KPIChartOptions
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            chart={chart}
          />
        ) : isWaterfallChart ? (
          <WaterfallChartOptions
            consolidatedBudgetOptions={consolidatedBudgetOptions}
            budgetOptions={budgetOptions}
            chart={chart}
          />
        ) : (
          isPieChart && (
            <PieChartOptions
              consolidatedBudgetOptions={consolidatedBudgetOptions}
              budgetOptions={budgetOptions}
              chart={chart}
            />
          )
        )}
      </Column>
    </ChartsSidebarWrapper>
  );
};

export default ChartsSidebar;
