import styled from 'styled-components';

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const HiddenContainer = styled.div`
  display: none;
`;
export const StyledResizeImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
`;
export const StyledDeleteContainer = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  left: -6px;
  top: -6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.palette.midnight};
  border-radius: 50%;
  opacity: 0.6;
  cursor: pointer;
`;
