import React, { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { theme as importedTheme } from '../../../theme';
import { mapTypographyColor, TypographyColor as TC } from './helpers';

export type TypographySize = 'tiny' | 'small' | 'medium' | 'h6' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1';
export type TypographyWeight = 'regular' | 'medium' | 'semibold' | 'bold';
export type TypographyAlignment = 'left' | 'center' | 'right' | 'justify';
export type TypographyColor = TC;

export interface TypographyProps extends HTMLAttributes<HTMLSpanElement> {
  size?: TypographySize;
  weight?: TypographyWeight;
  color?: TypographyColor;
  align?: TypographyAlignment;
  display?: 'inline' | 'block' | 'inline-flex';
  children: string | React.ReactNode;
  fontSizeRelative?: boolean;
  noMargin?: boolean;
}

// Take the theme font definitions and replace rem with em
const RELATIVE_THEME_FONT_SIZES = Object.fromEntries(
  Object.entries(importedTheme.font.size).map(([k, v]) => [k, v.substr(0, v.length - 3) + v.substr(v.length - 2, 2)]),
);
const RELATIVE_THEME_LINEHEIGHT_SIZES = Object.fromEntries(
  Object.entries(importedTheme.font.lineheight).map(([k, v]) => [
    k,
    v.substr(0, v.length - 3) + v.substr(v.length - 2, 2),
  ]),
);

export const StyledBodyText = styled.span<TypographyProps>`
  margin-top: ${({ noMargin }) => (noMargin ? 'unset' : 'auto')};
  margin-bottom: ${({ noMargin }) => (noMargin ? 'unset' : 'auto')};
  align-items: center;
  font-family: Inter, serif;
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  ${({ display }) => `display: ${display};`}
  ${({ theme, size, fontSizeRelative }) => {
    const fontSizes = fontSizeRelative ? RELATIVE_THEME_FONT_SIZES : theme.font.size;
    const lineHeightSizes = fontSizeRelative ? RELATIVE_THEME_LINEHEIGHT_SIZES : theme.font.lineheight;
    if (size === 'tiny') {
      return css`
        font-size: ${theme.font.size.tiny};
        line-height: ${theme.font.lineheight.tiny};
      `;
    }
    if (size === 'small') {
      return css`
        font-size: ${fontSizes.small};
        line-height: ${lineHeightSizes.small};
      `;
    }
    if (size === 'medium') {
      return css`
        font-size: ${fontSizes.medium};
        line-height: ${lineHeightSizes.medium};
      `;
    }
  }}


  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}

  ${({ align }) => (align ? `text-align: ${align};` : '')}
`;

const StyledH6 = styled.h6<TypographyProps>`
  ${({ display }) =>
    css`
      display: ${display};
    `}
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  font-size: ${({ theme }) => theme.font.size.h6};
  line-height: ${({ theme }) => theme.font.lineheight.h6};
  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}
`;

const StyledH5 = styled.h5<TypographyProps>`
  ${({ display }) =>
    css`
      display: ${display};
    `}
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  font-size: ${({ theme }) => theme.font.size.h5};
  line-height: ${({ theme }) => theme.font.lineheight.h5};
  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}
`;

const StyledH4 = styled.h4<TypographyProps>`
  ${({ display }) =>
    css`
      display: ${display};
    `}
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  font-size: ${({ theme }) => theme.font.size.h4};
  line-height: ${({ theme }) => theme.font.lineheight.h4};
  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}
`;

const StyledH3 = styled.h3<TypographyProps>`
  ${({ display }) =>
    css`
      display: ${display};
    `}
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  font-size: ${({ theme }) => theme.font.size.h3};
  line-height: ${({ theme }) => theme.font.lineheight.h3};
  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}
`;

const StyledH2 = styled.h2<TypographyProps>`
  ${({ display }) =>
    css`
      display: ${display};
    `}
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  font-size: ${({ theme }) => theme.font.size.h2};
  line-height: ${({ theme }) => theme.font.lineheight.h2};
  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}
`;

const StyledH1 = styled.h1<TypographyProps>`
  ${({ display }) =>
    css`
      display: ${display};
    `}
  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
  font-size: ${({ theme }) => theme.font.size.h1};
  line-height: ${({ theme }) => theme.font.lineheight.h1};
  ${({ theme, color }) => mapTypographyColor(theme, color)}
  ${({ theme, weight = 'medium' }) =>
    css`
      font-weight: ${theme.font.weight[weight]};
    `}
`;

const Typography: React.FC<React.PropsWithChildren<TypographyProps>> = ({
  weight = 'medium',
  size = 'small',
  color = 'black',
  display = 'inline',
  align,
  noMargin = false,
  ...other
}) => {
  switch (size) {
    case 'h6':
      return <StyledH6 color={color} weight={weight} align={align} display={display} {...other} />;
    case 'h5':
      return <StyledH5 color={color} weight={weight} align={align} display={display} {...other} />;
    case 'h4':
      return <StyledH4 color={color} weight={weight} align={align} display={display} {...other} />;
    case 'h3':
      return <StyledH3 color={color} weight={weight} align={align} display={display} {...other} />;
    case 'h2':
      return <StyledH2 color={color} weight={weight} align={align} display={display} {...other} />;
    case 'h1':
      return <StyledH1 color={color} weight={weight} align={align} display={display} {...other} />;
    default:
      return (
        <StyledBodyText
          size={size}
          weight={weight}
          align={align}
          color={color}
          display={display}
          noMargin={noMargin}
          {...other}
        />
      );
  }
};

export default Typography;
