import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { BooleanParam, useQueryParam } from 'use-query-params';

import colors from '../../colors';
import Icon from '../../components/atoms/Icon';
import ShadowedChartCardContainer from '../../components/atoms/ShadowedChartCardContainer';
import SubHeader from '../../components/atoms/SubHeader';
import TextButton from '../../components/atoms/TextButton';
import Typography from '../../components/atoms/Typography';
import { EditChartProvider, useEditChartContext } from '../../components/contexts/NewEditChartContext';
import { useOrganisationContext } from '../../components/contexts/OrganisationContext';
import useActiveOrganisationId from '../../components/contexts/OrganisationContext/useActiveOrganisationId';
import Column from '../../components/layout/Column';
import Row from '../../components/layout/Row';
import Spacer from '../../components/layout/Spacer';
import BlurredModal from '../../components/molecules/BlurredModal';
import CurrencySelect from '../../components/molecules/CurrencySelect';
import useCurrencySelection from '../../components/molecules/CurrencySelect/useCurrencySelection';
import useDateSelection from '../../components/molecules/DateSelect/useDateSelection';
import HeaderConfig from '../../components/molecules/HeaderConfig';
import MonthSelection from '../../components/molecules/MonthSelection';
import WidePage from '../../components/templates/WidePage';
import NewChartsSidebar from '../../features/chart-editor/NewChartsSidebar';
import ChartCard from '../../features/chart/ChartCard';
import { useOrganisation } from '../../store/state/organisations/hooks';

export interface ChartsEditorPageRouterProps {
  chartId: string;
  globalChartId: string;
}

const ChartWrapper = styled(Row)`
  height: calc(100% - 60px);
`;

const KPIChartWrapper = styled.div``;
export const StyledChartsEditorPage = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    background-color: ${theme.palette.backgroundGrey};
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `}
`;

const MainContent = styled.div`
  padding-top: 40px;
  display: flex;
  justify-content: center;
  width: calc(100% - 400px);
  height: calc(100vh - 40px - 40px - 56px);
  padding-bottom: 40px;
  display: flex;
  overflow-y: auto;

  & > *:first-child {
    width: 860px;
    height: 500px;
  }
  & ${KPIChartWrapper} {
    width: 400px;
    height: 160px;
  }
`;

interface ChartsEditorPageProps {
  chartIdToConfigure?: string;
  handleExit?: () => void;
}

const ChartsEditorPage: React.FC<ChartsEditorPageProps> = ({ chartIdToConfigure, handleExit }) => {
  const [dateFor] = useDateSelection();
  const organisation = useOrganisationContext();
  const fallbackCurrency = organisation.default_currency_code;
  const location = useLocation();

  const [currencyCode = fallbackCurrency] = useCurrencySelection();
  const { chartId } = useParams<ChartsEditorPageRouterProps>();
  return (
    <EditChartProvider chartId={chartIdToConfigure || chartId}>
      <WidePage wide>
        <StyledChartsEditorPage>
          <NewEditChartHeader
            handleExit={handleExit}
            hideChartDisplayOption={chartIdToConfigure !== undefined && location.pathname.startsWith('/presentations')}
          />
          <NewEditChartWrapper chartId={chartId} dateFor={dateFor} currencyCode={currencyCode} />
        </StyledChartsEditorPage>
      </WidePage>
    </EditChartProvider>
  );
};

function NewEditChartWrapper({ dateFor, currencyCode }: any) {
  const { chart } = useEditChartContext();
  const [updatedChartId, setupdatedChartId] = useState<any>(0);
  const isKPIChart = chart.chart_type === 'kpi_chart';
  const headerConfig = chart.header_config?.metricSchema && (
    <HeaderConfig
      period={chart.chart_type === 'time_chart' ? chart.chart_data.period : 'MONTH'}
      metricSchema={chart?.header_config?.metricSchema}
    />
  );
  useEffect(() => {
    setupdatedChartId(updatedChartId + 1);
  }, [chart]);
  if (isKPIChart) {
    return (
      <ChartWrapper width="100%">
        <NewChartsSidebar />
        <MainContent>
          <KPIChartWrapper>
            <ShadowedChartCardContainer className="kpi-wrapper">
              <ChartCard key={`${dateFor}-${currencyCode}-${updatedChartId}`} chartData={chart} />
            </ShadowedChartCardContainer>
          </KPIChartWrapper>
        </MainContent>
      </ChartWrapper>
    );
  }
  return (
    <ChartWrapper width="100%">
      <NewChartsSidebar />
      <MainContent>
        <ShadowedChartCardContainer>
          <ChartCard
            key={`${dateFor}-${currencyCode}-${updatedChartId}`}
            chartData={chart}
            headerConfig={headerConfig}
          />
        </ShadowedChartCardContainer>
      </MainContent>
    </ChartWrapper>
  );
}

function NewEditChartHeader({
  hideChartDisplayOption = false,
  handleExit,
}: {
  hideChartDisplayOption?: boolean;
  handleExit?: () => void;
}) {
  const history = useHistory();
  const [dashboardId] = useQueryParam('dashboardId');
  const [from] = useQueryParam('from');
  const [date, setDate] = useDateSelection('date');
  const { saveChart, isDirty, setIsDirty } = useEditChartContext();
  const [isSaving] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const organisationId = useActiveOrganisationId();
  const organisation = useOrganisation(organisationId);
  const fallbackCurrency = organisation?.value?.default_currency_code ?? 'GBP';
  const [editModeParam] = useQueryParam('isEditMode', BooleanParam);
  const navigateBack = useCallback(() => {
    setIsDirty(false);
    if (from === 'landing-page') {
      history.push(`/`);
    } else {
      dashboardId
        ? history.push(`/dashboards/${dashboardId}?isEditMode=${editModeParam ? '1' : '0'}`)
        : history.push(`/dashboards/`);
    }
  }, [dashboardId]);
  const handleSave = () => {
    saveChart();
  };

  useEffect(() => {
    if (!date) {
      const fallbackDate = dayjs().startOf('month').subtract(1, 'month').format('YYYY-MM');
      setDate(fallbackDate);
    }
  }, [date]);
  React.useEffect(() => {
    if (isExiting && !isDirty) {
      navigateBack();
    }
  }, [isExiting]);

  return (
    <SubHeader>
      <Row hAlign={!hideChartDisplayOption ? 'space-between' : 'flex-end'} width="100%">
        {!hideChartDisplayOption && (
          <Row>
            <MonthSelection value={date!} onChange={value => setDate(value)} placement="bottomStart" />
            <CurrencySelect fallbackCurrency={fallbackCurrency} />
          </Row>
        )}

        <Row>
          <BlurredModal showModal={isDirty && isExiting} onHide={() => setIsExiting(false)} showCloseButton={false}>
            <Spacer padding={{ left: 6, right: 6, top: 4, bottom: 4 }} margin={{ top: 4 }}>
              <Column spacing="medium">
                <Row vAlign="center" spacing="small">
                  <Icon name="warning" color={colors.granite} size={11.5} marginRight={0} />
                  <Typography color="secondary" size="h4">
                    You have unsaved changes
                  </Typography>
                </Row>
                <Typography weight="regular" size="medium">
                  Are you sure you want to exit without saving your changes?
                </Typography>
                <Row spacing="small" hAlign="end" width="100%">
                  <TextButton width="180px" variant="secondary" onClick={() => setIsExiting(false)}>
                    Cancel
                  </TextButton>
                  <TextButton width="180px" variant="danger" onClick={navigateBack}>
                    Exit
                  </TextButton>
                </Row>
              </Column>
            </Spacer>
          </BlurredModal>
          {
            <TextButton
              width="100px"
              iconRight="exit_to_app"
              loading={isSaving}
              variant="secondary"
              disabled={isSaving}
              onClick={() => (handleExit ? handleExit() : setIsExiting(true))}
            >
              Exit
            </TextButton>
          }

          <TextButton iconRight="save" loading={isSaving} onClick={handleSave}>
            Save
          </TextButton>
        </Row>
      </Row>
    </SubHeader>
  );
}

export default ChartsEditorPage;
