import { MutableRefObject, useRef } from 'react';
import { Whisper, WhisperProps } from 'rsuite';
import styled, { css } from 'styled-components';

import Icon from '../../atoms/Icon';
import Input from '../../atoms/Input';
import Typography from '../../atoms/Typography';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import Column from '../../layout/Column';
import Row from '../../layout/Row';

interface ColourPickerProps {
  color: string;
  handleColor: (color: string) => void;
  placement?: WhisperProps['placement'];
  showBrandColor?: boolean;
}

const StyledColourPicker = styled.div`
  ${({ theme }) => css`
    padding: ${theme.sizing(3)};
    position: absolute;
    margin-top: ${theme.sizing(2)};
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.fieldGrey};
    box-shadow: 0px 2px 6px 2px rgba(172, 186, 224, 0.2);
    border-radius: 3px;
    z-index: ${theme.zIndex.highest};
  `}
`;
const COLOUR_PICKER_COLORS = [
  ['#afcae7', '#90FCE9', '#A5F877', '#FFF196', '#f5bcbc', '#FF89C6', '#FFFFFF'],
  ['#6794dc', '#4AA59D', '#84c77e', '#FF7F00', '#E34B37', '#DE67A3', '#ACBAE0'],
  ['#11345F', '#245958', '#306E1D', '#C86527', '#9F1A05', '#BA3477', '#4B5D78'],
  ['#001835', '#064342', '#134006', '#A3460C', '#9E1B07', '#9D0D56', '#000000'],
];
const ColorSquare = styled.div<{ color: string; active: boolean }>`
  ${({ theme, color, active }) => css`
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background-color: ${color};
    cursor: pointer;
    border: ${active ? '2px' : '1px'} solid
      ${active ? theme.palette.sky : color === '#FFFFFF' ? theme.palette.fieldGrey : 'none'};
    &:hover {
      outline: 2px solid ${theme.palette.sky};
    }
  `}
`;

const StyledColourToggle = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.palette.silver};
    border: 1px solid ${theme.palette.fieldGrey};
    border-radius: 2px;
    width: 40px;
    padding: 1px;
    cursor: pointer;
    & ${ColorSquare}: hover {
      outline: none;
    }

    & .material-icons {
      position: absolute;
      color: ${theme.palette.primary.main};
      right: -3px;
      top: calc(50% - 6px);
    }
  `}
`;

const BrandColorContainer = styled(Column)`
  margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const ColourPicker: React.FC<React.PropsWithChildren<ColourPickerProps>> = ({
  color: selectedColor,
  handleColor,
  placement = 'bottomEnd',
  showBrandColor = true,
}) => {
  const inputRef = useRef() as MutableRefObject<HTMLInputElement>;
  const organisation = useActiveOrganisation();
  const primary_color = organisation.primary_brand_colour;
  const secondary_color = organisation.secondary_brand_colour;
  return (
    <Whisper
      trigger="click"
      placement={placement}
      speaker={
        <StyledColourPicker>
          {showBrandColor && (primary_color || secondary_color) && (
            <BrandColorContainer spacing="small">
              <Typography color="secondary">Brand colours:</Typography>
              <Column spacing="tiny">
                {primary_color && (
                  <Row width="100%" vAlign="center" spacing="small">
                    <ColorSquare
                      key={primary_color}
                      active={selectedColor === primary_color}
                      onClick={() => handleColor(primary_color)}
                      color={primary_color}
                    />
                    <Typography weight="regular" size="tiny">
                      Primary Colour
                    </Typography>
                  </Row>
                )}
                {secondary_color && (
                  <Row width="100%" vAlign="center" spacing="small">
                    <ColorSquare
                      key={secondary_color}
                      active={selectedColor === secondary_color}
                      onClick={() => handleColor(secondary_color)}
                      color={secondary_color}
                    />
                    <Typography weight="regular" size="tiny">
                      Secondary Colour
                    </Typography>
                  </Row>
                )}
              </Column>
            </BrandColorContainer>
          )}
          <Column spacing="tiny">
            {COLOUR_PICKER_COLORS.map((colorList, index) => (
              <Row spacing="tiny" key={index}>
                {colorList.map(color => (
                  <ColorSquare
                    key={color}
                    active={selectedColor === color}
                    onClick={() => handleColor(color)}
                    color={color}
                  />
                ))}
              </Row>
            ))}
            <Row hAlign="space-between" width="100%">
              <Typography color="secondary"> Hex:</Typography>{' '}
              <Row vAlign="center" spacing="tiny">
                #
                <Input
                  ref={inputRef}
                  placeholder="000000"
                  maxLength={6}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      handleColor('#' + event.currentTarget.value);
                      inputRef.current?.blur();
                    }
                  }}
                  pattern="[a-fA-F\d]"
                  onChange={e => {
                    inputRef.current.value = e.target.value.replaceAll('#', '');
                  }}
                  defaultValue={selectedColor.replaceAll('#', '')}
                  width="90px"
                />
              </Row>
            </Row>
          </Column>
        </StyledColourPicker>
      }
    >
      <StyledColourToggle>
        <ColorSquare active={false} color={selectedColor} />
        <Icon name="expand_more" color="inherit" />
      </StyledColourToggle>
    </Whisper>
  );
};

export default ColourPicker;
