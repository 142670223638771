import dayjs from 'dayjs';
import { DATE_FORMAT, NEW_DATE_FORMAT } from 'scalexp/components/molecules/DateSelect/dateSupport';
import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectDeferredSeries } from './selectors';
import { DeferredSeries } from './types';

export const ORGANISATIONS_DEFERRED_SERIES_BY_ACCOUNT_LOAD = '[deferredSeries] LOAD';

export type DeferredSeriesActions = PromiseAction<
  typeof ORGANISATIONS_DEFERRED_SERIES_BY_ACCOUNT_LOAD,
  DeferredSeries,
  { organisationId: number }
>;

type DeferredDataResponse = DeferredSeries;

export const loadDeferredSeriesAction = (
  organisationId: number,
  fromDate: Date,
  toDate: Date,
  accountsFilter: string[],
  focusDate?: Date,
): ThunkAction<DeferredSeriesActions> => async (dispatch: ThunkDispatch<DeferredSeriesActions>, getState) => {
  const state = getState();
  const deferredSeries = selectDeferredSeries(state, organisationId);

  if (deferredSeries?.status === 'pending') {
    return;
  }

  const body: Record<string, string | boolean | string[]> = {
    start: dayjs(fromDate).format(NEW_DATE_FORMAT),
    end: dayjs(toDate).format(NEW_DATE_FORMAT),
    account_ids: accountsFilter,
    include_issued: true,
    include_pending: false,
    include_renewals: false,
  };

  if (focusDate) {
    body['issued_date_start'] = dayjs(focusDate).format(DATE_FORMAT);
    body['issued_date_end'] = dayjs(focusDate).add(1, 'month').format(DATE_FORMAT);
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_DEFERRED_SERIES_BY_ACCOUNT_LOAD,
        () =>
          http<DeferredSeries, DeferredDataResponse>(
            `/api/v1/organisations/${organisationId}/invoices/deferred/by_account`,
            { body },
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
