import styled, { css } from 'styled-components';

export type SelectSize = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
export type SelectPlacement = 'topStart' | 'bottomStart' | 'bottomEnd';

export const StyledContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  position: relative;
  cursor: pointer;

  .expand-icon {
    position: absolute;
    right: 5px;
  }
`;

export const StyledSelect = styled.div<{
  size: SelectSize;
  customWidth?: number | 'maxOptionWidth';
  height: number;
  placement: SelectPlacement;
  container: DOMRect;
  select: { width: number; height: number };
  zIndex: 'high' | 'highest';
  containerWidth: string;
}>`
  /* padding: 8px; */
  position: absolute;
  width: ${({ customWidth, container }) => {
    if (customWidth === 'maxOptionWidth') {
      return `unset`;
    }
    return `${customWidth ?? container.width}px`;
  }};
  min-width: ${({ customWidth, containerWidth }) => {
    if (customWidth === 'maxOptionWidth' && containerWidth.includes('px')) {
      return containerWidth;
    }
  }};
  max-height: ${({ height }) => `${height}px`};
  overflow-y: auto;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-top: unset;
  background-color: ${({ theme }) => theme.palette.white};

  ${({
    placement,
    container: { top, left, width, height },
    select: { height: selectHeight, width: selectWidth },
    customWidth,
  }) => {
    const usedWidth = customWidth === 'maxOptionWidth' ? selectWidth : customWidth ?? selectWidth;
    switch (placement) {
      case 'topStart':
        return css`
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomStart':
        return css`
          top: calc(${top}px + ${height}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomEnd':
        return css`
          top: ${top + height + window.scrollY}px;
          left: ${left - usedWidth + width + window.scrollX}px;
        `;
    }
  }};

  ${({ size, theme }) => {
    switch (size) {
      case 'tiny':
      case 'small': {
        return css`
          border-bottom-left-radius: ${theme.spacing(1)};
          border-bottom-right-radius: ${theme.spacing(1)};
        `;
      }
      case 'medium':
      case 'large':
      case 'xlarge': {
        return css`
          border-bottom-left-radius: ${theme.spacing(2)};
          border-bottom-right-radius: ${theme.spacing(2)};
        `;
      }
      default:
        return '';
    }
  }}
  input {
    width: calc(100% - 2px);
    margin: ${({ theme }) => theme.spacing(0.5)};
  }
  .expand-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export const StyledSearchInputContainer = styled.div`
  position: sticky;
  top: 0;
  padding: 0 8px;
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

export const StyledGroup = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(8)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:empty {
    display: none;
  }
`;

export const StyledMenuItem = styled.div<{ selected?: boolean; depth?: number }>`
  min-height: ${({ theme }) => `${theme.spacing(9)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  padding-left: ${({ theme, depth = 4 }) => theme.spacing(depth)};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.primary.offwhite : theme.palette.white)};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .material-icons {
    width: 100%;
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    color: ${({ theme }) => theme.palette.primary.main};
  }

  &:hover {
    background-color: ${({ theme, selected }) =>
      selected ? theme.palette.primary.offwhite : theme.palette.primary.offwhite};
  }
`;

export const StyledCategoriesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(3)} `};
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.sizing(2)};
`;

export const StyledCategoryTitleContainer = styled.div`
  position: sticky;
  top: 32px;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.sizing(2)};
  padding: ${({ theme }) => theme.sizing(4)};
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 10;
`;

export const StyledIconsContainer = styled.div<{ iconsCount: number }>`
  width: ${({ iconsCount }) => `${iconsCount * 18 + 10}px`};
  display: flex;
`;

export const StyledImage = styled.div<{ order: number; zIndex: number }>`
  position: relative;
  left: ${({ order }) => -((order + 1) * 6)}px;
  z-index: ${({ zIndex }) => zIndex};
`;

export const StyledCategory = styled.div`
  height: 62px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.sizing(2.5)};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  cursor: pointer;
`;

export const StyledValue = styled.div<{
  size: SelectSize;
  showSelect: boolean;
  hasError?: boolean;
  disabled?: boolean;
  bold?: boolean;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.palette.fieldGrey}`};
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  ${({ size, showSelect, theme }) => {
    switch (size) {
      case 'tiny': {
        return css`
          height: 20px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'small': {
        return css`
          height: 28px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'medium': {
        return css`
          height: 36px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(3)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 6px;
          }
        `;
      }
      case 'large': {
        return css`
          height: 40px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(4)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 8px;
          }
        `;
      }
      case 'xlarge': {
        return css`
          height: 44px;
          padding: 0 ${theme.spacing(4)} ${showSelect ? '1px' : '0'} ${theme.spacing(5)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 10px;
          }
        `;
      }
      default:
        return '';
    }
  }};
  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        border: 2px solid ${theme.palette.danger.dark};
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      `;
    }
  }}

  // removing the border and raduis from the bottom when the select is shown
  ${({ showSelect }) =>
    showSelect
      ? css`
          border-bottom: unset;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        `
      : ''}

  &:hover,
  &:focus {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(70%);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
