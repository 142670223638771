import React, { useState } from 'react';
import TextButton from 'scalexp/components/atoms/TextButton';
import useActiveOrganisation from 'scalexp/components/contexts/OrganisationContext/useActiveOrganisation';
import UpgradePlanNotificationModal from 'scalexp/features/trial/UpgradePlanNotificationModal';
import fetcher from 'scalexp/utils/fetcher';
import styled, { css } from 'styled-components';
import { useQueryParam } from 'use-query-params';

import Notification from '../../atoms/Notification';
import Typography from '../../atoms/Typography';
import Row from '../../layout/Row';

interface TrialBannerPropsType {
  daysRemaining: number;
  status: 'trial' | 'expired' | 'active';
}

const TrialBannerContainer = styled(Row)<{ expired: boolean }>`
  ${({ theme, expired }) => css`
    background-color: ${expired ? theme.palette.danger.main : theme.palette.midnight};
    height: 50px;
    overflow: hidden;
    padding: 0 32px;
  `}
`;
const StyledLink = styled.a<{ variant: 'primary' | 'secondary' }>`
  ${({ theme, variant }) => css`
    height: ${theme.sizing(7)};
    width: ${theme.sizing(27)};
    display: flex;
    font-weight: 500;
    font-size: ${theme.font.size.small};
    justify-content: center;
    text-decoration: none;
    align-items: center;
    border-radius: 4px;
    border: 1.2px solid ${variant === 'secondary' ? theme.palette.midnight : theme.palette.primary.main};
    background-color: ${variant === 'secondary' ? theme.palette.white : theme.palette.primary.main};
    color: ${variant === 'primary' ? theme.palette.white : theme.palette.midnight};

    &:hover {
      text-decoration: none;
      color: ${variant === 'primary' ? theme.palette.white : theme.palette.midnight};
    }
  `}
`;

const DaysBudge = styled(Typography)`
  ${({ theme }) => css`
    width: ${theme.sizing(17.5)};
    height: ${theme.sizing(7)};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8c7bf2;
    border-radius: 4px;
  `}
`;

const TrialBanner: React.FC<TrialBannerPropsType> = ({ daysRemaining, status }) => {
  const [showUpgradeModalNotification, setShowUpgradeModalNotification] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const activeOrganisation = useActiveOrganisation();
  const [, setUpgradeRequest] = useQueryParam('upgrade_request');

  const handleUpgrade = async () => {
    setRequestLoading(true);
    try {
      await fetcher(`/api/v2/organisation/${activeOrganisation.organisation_id}/upgrade_request`, {
        method: 'POST',
      });
      setShowUpgradeModalNotification(true);
      setIsRequestSent(true);
      setTimeout(() => {
        window.HubSpotConversations.widget.open();
      }, 1000);
    } catch (error) {
      Notification.error({ title: 'Could not upgrade your plan' });
    }
    setRequestLoading(false);
    setUpgradeRequest('enabled');
    Notification.info({
      duration: 5000,
      top: 150,
      title:
        'Thanks for letting us know that you want to upgrade.  Please use the Live Chat window below for an immediate response. ',
      placement: 'topEnd',
    });
  };

  return (
    <TrialBannerContainer hAlign="space-between" vAlign="center" expired={status === 'expired' || daysRemaining < 0}>
      {status === 'expired' || daysRemaining < 0 ? (
        <>
          <div />
          <Typography weight="regular" size="medium" color="white">
            Your trial has expired. Your data will be deleted soon if you do not upgrade.
          </Typography>
        </>
      ) : (
        <>
          <Row>
            <Typography weight="regular" size="medium" color="white">
              Your ScaleXP trial ends in
            </Typography>
            <DaysBudge color="white" weight="bold">
              {daysRemaining} days
            </DaysBudge>
          </Row>
          <Row>
            <Typography display="block" weight="regular" size="medium" color="white">
              Upgrade to a paid plan to unlock ongoing access to the benefits of ScaleXP
            </Typography>
          </Row>
        </>
      )}
      <Row>
        <StyledLink href="https://meetings.hubspot.com/scalexp/45-min-product-demo-clone" variant="secondary">
          Contact
        </StyledLink>

        {!activeOrganisation.upgrade_requested && !isRequestSent && (
          <TextButton disabled={requestLoading} loading={requestLoading} width="108px" onClick={handleUpgrade}>
            Upgrade
          </TextButton>
        )}
        <UpgradePlanNotificationModal
          show={showUpgradeModalNotification}
          onHide={() => setShowUpgradeModalNotification(false)}
        />
      </Row>
    </TrialBannerContainer>
  );
};

export default TrialBanner;
