import React from 'react';
import Row from 'scalexp/components/layout/Row';
import styled, { css } from 'styled-components';

import { CalculationResultImpact } from '../../../utils/metrics/calculationRequestManager';
import Icon from '../Icon';
import Typography from '../Typography';

export interface ChangeTextProps {
  value: number;
  period?: string;
  impact: CalculationResultImpact;
  formattedValue: string;
}
export interface StyledChangeTextProps {
  impact: CalculationResultImpact;
}
export const StyledChangeText = styled(Row)<StyledChangeTextProps>`
  ${({ theme, impact }) =>
    css`
      padding: ${theme.spacing(1.25)};
      flex: 0 0 auto;
      border-radius: ${theme.spacing(1)};
      background-color: ${theme.palette.primary.offwhite};
      & * {
        line-height: unset;
      }
      span:first-child {
        font-weight: ${theme.font.weight.semibold};
      }

      ${impact === 'positive'
        ? css`
            background-color: ${theme.palette.success.offwhite};
            & span {
              color: ${theme.palette.success.dark};
            }
            color: ${theme.palette.success.dark};
          `
        : ''}

      ${impact === 'negative'
        ? css`
            background-color: ${theme.palette.danger.offwhite};
            & span {
              color: ${theme.palette.danger.dark};
            }
            color: ${theme.palette.danger.dark};
          `
        : ''}
        &> span:not(:nth-child(2)) {
        font-size: 12px;
      }
    `}
`;
const ChangeText: React.FC<React.PropsWithChildren<ChangeTextProps>> = ({
  value,
  period = 'previous month',
  impact,
  formattedValue,
}) => {
  let impactAfterValue = value === 0 ? 'neutral' : impact;
  if (value < 0 && impact !== 'neutral') {
    impactAfterValue = impact === 'positive' ? 'negative' : 'positive';
  }
  return (
    <StyledChangeText impact={impactAfterValue} spacing="tiny" vAlign="center">
      <Typography weight="medium" size="tiny">
        {formattedValue}
      </Typography>
      <Icon marginRight={0.5} color="inherit" name={value >= 0 ? 'north_east' : 'south_east'} size={3} />
      <Typography size="tiny">vs {period}</Typography>
    </StyledChangeText>
  );
};
export default ChangeText;
