import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import colors from 'scalexp/colors';
import Icon from 'scalexp/components/atoms/Icon';
import Typography from 'scalexp/components/atoms/Typography';
import Aligner from 'scalexp/components/layout/Aligner';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import JournalTooltipContent from 'scalexp/components/organisms/Header/JournalTooltipContent';
import TopDivider from 'scalexp/components/organisms/Header/TopDivider';
import { PERMISSIONS, Permission, useHasPermissionChecker } from 'scalexp/service/permissions';

import { NavItem as StyledNavItem } from './Header.styles';

const DIVIDER = 'divider' as const;

type MenuItem =
  | {
      icon: string;
      to: string;
      text: string;
      permission?: Permission;
      isActive?: () => boolean;
      tooltip?: ReactNode;
    }
  | typeof DIVIDER;

const NavItem = ({ icon, ...props }: { to: string; icon: string; children?: string; isActive?: boolean }) => {
  return (
    <StyledNavItem to={props.to} $isActive={props.isActive}>
      <Aligner direction="vertical" hAlign="center" vAlign="center" gap="4px">
        <Icon name={icon} color="#fff" />
        <Typography color="white" size="tiny" weight="regular">
          {props.children}
        </Typography>
      </Aligner>
    </StyledNavItem>
  );
};

const TopMenu: FC = () => {
  const pathname = useLocation()?.pathname;
  const permissionChecker = useHasPermissionChecker();

  const menuItems: MenuItem[] = [
    DIVIDER,
    { icon: 'text_snippet', to: '/reports', text: 'Reports', permission: PERMISSIONS.REPORTS },
    { icon: 'pie_chart', to: '/dashboards', text: 'Dashboards', permission: PERMISSIONS.DASHBOARDS },
    { icon: 'present_to_all', to: '/presentations', text: 'Presentations', permission: PERMISSIONS.PRESENTATIONS },
    { icon: 'supervised_user_circle', to: '/analysis', text: 'Customers', permission: PERMISSIONS.READ_CUSTOMER_PAGES },
    {
      icon: 'book',
      to: '/journals',
      text: 'Journals',
      permission: PERMISSIONS.JOURNALS,
      tooltip: <JournalTooltipContent />,
    },
    {
      icon: 'upload',
      to: '/oauth/',
      text: 'Load Data',
      isActive: () => pathname?.startsWith('/oauth') || pathname?.startsWith('/metrics'),
      permission: PERMISSIONS.WRITE_ALL,
    },
  ];

  return (
    <>
      {menuItems.map((item, index) => {
        if (item === DIVIDER) {
          return <TopDivider key={index} />;
        }

        if (item.permission && !permissionChecker(item.permission)) {
          return null;
        }

        const navItem = (
          <NavItem
            icon={item.icon}
            isActive={item.isActive ? item.isActive() : pathname?.startsWith(item.to)}
            to={item.to}
            key={item.to}
          >
            {item.text}
          </NavItem>
        );

        return item.tooltip ? (
          <Tooltip
            tooltipStyles={{ borderRadius: 8 }}
            content={item.tooltip}
            placement="bottom"
            color={colors.blue[50]}
          >
            {navItem}
          </Tooltip>
        ) : (
          navItem
        );
      })}
    </>
  );
};

export default TopMenu;
