import React, { HTMLAttributes, ChangeEvent } from 'react';
import styled from 'styled-components';

import Icon from '../../atoms/Icon';
import Input from '../../atoms/Input';

export interface SearchInputProps extends HTMLAttributes<HTMLDivElement> {
  customSize?: 'small' | 'medium';
  width?: string;
  value?: string;
  setValue: (value: string) => void;
  iconPosition?: 'start' | 'end';
  customIcon?: string;
  hasError?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
}

const StyledContainer = styled.div<{
  size: 'small' | 'medium';
  width?: string;
  iconPosition: 'start' | 'end';
}>`
  ${({ width }) => (width ? `width: ${width};` : '')}
  position: relative;
  display: flex;
  align-items: center;

  input {
    ${({ theme, iconPosition, size }) => {
      let spacing = 7;
      switch (size) {
        case 'small':
          spacing = 7;
          break;
        case 'medium':
          spacing = 9;
          break;
      }

      return iconPosition === 'start'
        ? `padding-left: ${theme.spacing(spacing)}`
        : `padding-right: ${theme.spacing(spacing)}`;
    }}
  }
`;

const StyledIcon = styled(Icon)<{ $cursor?: boolean; iconPosition: 'start' | 'end' }>`
  position: absolute;
  ${({ iconPosition }) => (iconPosition === 'start' ? `left: 10px` : `right: 10px`)};
  color: ${({ theme }) => theme.palette.granite};
  cursor: ${({ $cursor }) => ($cursor ? 'pointer' : 'unset')};
  z-index: ${({ theme }) => theme.zIndex.medium};
`;

const SearchInput = React.forwardRef<HTMLInputElement, React.PropsWithChildren<SearchInputProps>>(
  (
    {
      customSize = 'small',
      autoFocus = false,
      width = '100%',
      value,
      setValue,
      iconPosition = 'end',
      disabled,
      customIcon,
      ...other
    },
    ref,
  ) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    };

    let iconSize = 4;
    switch (customSize) {
      case 'small':
        iconSize = 4;
        break;
      case 'medium':
        iconSize = 6;
        break;
    }

    return (
      <StyledContainer size={customSize} width={width} iconPosition={iconPosition}>
        {iconPosition === 'start' && (
          <StyledIcon
            size={iconSize}
            iconPosition={iconPosition}
            $cursor={!!value}
            name={value ? 'clear' : customIcon ?? 'search'}
            onClick={() => setValue('')}
            marginRight={0}
          />
        )}
        <Input
          ref={ref}
          autoFocus={autoFocus}
          width={width}
          customSize={customSize}
          placeholder="search"
          value={value}
          onChange={handleChange}
          disabled={disabled}
          {...other}
        />
        {iconPosition === 'end' && (
          <StyledIcon
            size={iconSize}
            iconPosition={iconPosition}
            $cursor={!!value}
            name={value ? 'clear' : 'search'}
            onClick={() => setValue('')}
            marginRight={0}
          />
        )}
      </StyledContainer>
    );
  },
);
export default SearchInput;
