export const PERIODS_OPTIONS = [
  { label: 'Month', value: 'MONTH' },
  { label: 'Quarter', value: 'QUARTER' },
  { label: 'Year', value: 'YEAR' },
  { label: 'Year to date', value: 'YEAR_TO_DATE' },
];
export const NUMBER_OF_MONTHS = 24;
export const FUTURE_MONTH_OPTIONS = (period = 'month', customMonthNumber?: number) => [
  { label: '0', value: 0 },
  ...[...new Array(customMonthNumber ?? NUMBER_OF_MONTHS)].map((_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
  })),
];
export const PAST_MONTH_OPTIONS = (period = 'month', customMonthNumber?: number) => [
  { label: '-1', value: 1 },
  { label: '0', value: 0 },
  ...[...new Array(customMonthNumber ?? NUMBER_OF_MONTHS)].map((_, index) => ({
    label: `${index + 1}`,
    value: (index + 1) * -1,
  })),
];
export const ACTUAL_BUDGET_OPTIONS = [
  { label: 'Actual', value: 'ACTUAL' },
  { label: 'Budget', value: 'BUDGET' },
];
export const SERIES_TYPES_OPTIONS = [
  { label: 'Column', value: 'ColumnSeries', img: '/images/charts/bar-chart-type.svg' },
  { label: 'Line', value: 'LineSeries', img: '/images/charts/line-chart-type.svg' },
  { label: 'Stacked Column', value: 'Stacked', img: '/images/charts/stacked-bar-chart-type.svg' },
];
