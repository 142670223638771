import Input from 'scalexp/components/atoms/Input';
import SidebarWrapper from 'scalexp/components/atoms/SidebarWrapper';
import Row from 'scalexp/components/layout/Row';
import styled, { css } from 'styled-components';

export const SeriesSectionTitle = styled.div`
  ${({ theme }) => css`
    width: 100%;
    background: ${theme.palette.backgroundGrey};
    padding: ${theme.spacing(4)} ${theme.spacing(5)};
    border-top: 1px solid ${theme.palette.silver};
    .material-icons {
      cursor: pointer;
    }
  `}
`;
export const ChartsSidebarWrapper = styled(SidebarWrapper)`
  ${({ theme }) => css`
    width: ${theme.sizing(93.75)};
    border: none;
    box-shadow: inset -1px 0px 0px #e2e2ea;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    align-items: center;
    padding: 0;
    background-color: ${theme.palette.white};
    & > *:first-child {
      height: 74px;
      flex: 0 0 auto;
      padding: 0 16px;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dfdfdf;
    }
    & > *:nth-child(2) {
      overflow: auto;
    }
  `}
`;
export const DeleteSeriesContainer = styled.div<{ visibility: boolean }>`
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.3s;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.backgroundGrey};
  z-index: ${({ theme }) => theme.zIndex.low};
  display: flex;
  border-radius: inherit;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme, visibility }) => {
    if (visibility) {
      return css`
        opacity: 1;
        z-index: ${theme.zIndex.regular};
      `;
    }
  }}
  & div span:nth-child(3) {
    background: #ffffff;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    width: 236px;
    min-height: 36px;
  }
`;
export const SeriesOptionContainer = styled.div<{ active?: boolean }>`
  background: ${({ theme }) => theme.palette.backgroundGrey};
  border-radius: ${({ theme }) => theme.sizing(3)};
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.spacing(5)} ${({ theme }) => theme.spacing(3.75)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
  position: relative;
  border: 2px solid transparent;

  ${({ theme, active }) => {
    if (active) {
      return css`
        opacity: 1;
        border: 2px solid ${theme.palette.primary.main};
      `;
    }
    if (active === false) {
      return css`
        opacity: 0.8;
      `;
    }
  }}
`;
export const StyledSidebarInput = styled(Input)`
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  height: ${({ theme }) => theme.sizing(9)};
  border-radius: ${({ theme }) => theme.sizing(2)}; ;
`;
export const StyledDisabledSeriesName = styled(Row)`
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  height: ${({ theme }) => theme.sizing(9)};
  border-radius: ${({ theme }) => theme.sizing(2)};
  cursor: not-allowed;
  width: ${({ theme }) => theme.spacing(61.75)};
  background: ${({ theme }) => theme.palette.silver};
  padding-left: ${({ theme }) => theme.spacing(3)};
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const StyledSidebarColorPicker = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: ${({ theme }) => theme.sizing(2)};
  width: ${({ theme }) => theme.sizing(8)};
  height: ${({ theme }) => theme.sizing(8)};
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    display: none;
  }
  & > div {
    border: none;
    border-radius: 4px;
    width: auto;
  }
  & > div > div {
    border-radius: 4px;
  }
`;
export const SwitcherSidebarWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-radius: ${({ theme }) => theme.sizing(2)};
  & > div {
    background-color: ${({ theme }) => theme.palette.white};
    border: none;
    border-radius: inherit;
    gap: 0;
  }
  & > div > div {
    border-radius: 4px;
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.sizing(3)};
    line-height: ${({ theme }) => theme.sizing(4.5)};
  }
`;
export const StyledNodeChoice = styled(Row)`
  width: 100%;
  overflow: hidden;
  & > span:first-child {
    width: ${({ theme }) => theme.sizing(6.25)};
  }
  & > span:nth-child(2) {
    overflow: hidden;
    width: calc(100% - ${({ theme }) => theme.sizing(7.5)});
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  & > span:only-child {
    width: 100%;
  }
`;
