import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'scalexp/store/state/types';

import { useOrganisation } from '../organisations/hooks';
import { createBudget, deleteBudget, loadBudgetSets, updateBudget } from './actions';
import { selectConsolidatedBudgetSets } from './selectors';
import { ConsolidatedBudgetSet, ConsolidatedBudgetSetsVS } from './types';

export const useConsolidatedBudgetSets = (organisationId: number): ConsolidatedBudgetSetsVS => {
  const organisation = useOrganisation(organisationId);
  const budgetSets: ConsolidatedBudgetSetsVS = useSelector<Store, ReturnType<typeof selectConsolidatedBudgetSets>>(
    state => selectConsolidatedBudgetSets(state, organisationId),
  );
  const dispatch = useDispatch();

  const reload = useCallback(() => {
    dispatch(loadBudgetSets(organisationId));
  }, [organisationId]);

  if (!(organisation.value?.children || []).length) {
    return {
      status: 'success',
      value: [],
      reload,
      error: undefined,
    };
  }

  if (budgetSets === undefined) {
    reload();
    return {
      status: 'pending',
      reload,
      error: undefined,
      value: undefined,
    };
  }

  return {
    ...budgetSets,
    reload,
  };
};

export const useDeleteConsolidatedBudget = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    (budgetSetId: number) => {
      return dispatch(deleteBudget(organisationId, budgetSetId));
    },
    [organisationId],
  );
};

export const useCreateConsolidatedBudget = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    (budgetSet: Omit<ConsolidatedBudgetSet, 'id'>) => {
      return dispatch(createBudget(organisationId, budgetSet));
    },
    [organisationId],
  );
};

export const useUpdateConsolidatedBudget = (organisationId: number) => {
  const dispatch = useDispatch();
  return useCallback(
    (budgetSetId: number, budgetSet: ConsolidatedBudgetSet) => {
      return dispatch(updateBudget(organisationId, budgetSetId, budgetSet));
    },
    [organisationId],
  );
};
