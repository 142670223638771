import { PromiseAction, ThunkAction, ThunkDispatch } from 'scalexp/store/types';

import { promiseAction } from '../../actions';
import http from '../../http';
import { selectMonthEndCategories } from './selectors';
import { CreateMonthEndTaskPayload, MonthEndCategory, MonthEndTask } from './types';

export const ORGANISATIONS_MONTH_END_CATEGORIES_LOAD = '[MonthEndCategories] LOAD';
export const ORGANISATIONS_MONTH_END_CATEGORIES_ADD = '[MonthEndCategories] ADD';
export const ORGANISATIONS_MONTH_END_CATEGORY_RENAME = '[MonthEndCategories] RENAME';
export const ORGANISATIONS_MONTH_END_CATEGORY_DELETE = '[MonthEndCategories] DELETE';
export const ORGANISATIONS_MONTH_END_CATEGORIES_REORDER = '[MonthEndCategories] REORDER';
export const ORGANISATIONS_MONTH_END_CATEGORIES_ADD_TASK = '[MonthEndCategories] TASKS_ADD';
export const ORGANISATIONS_MONTH_END_CATEGORIES_DELETE_TASK = '[MonthEndCategories] TASKS_DELETE';
export const ORGANISATIONS_MONTH_END_CATEGORIES_UPDATE_TASK = '[MonthEndCategories] TASKS_UPDATE';
export const ORGANISATIONS_MONTH_END_CATEGORY_TASKS_REORDER = '[MonthEndCategories] TASKS_REORDER';

export type MonthEndCategoriesLoad = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORIES_LOAD,
  MonthEndCategoryResponse,
  { organisationId: number }
>;
export type MonthEndCategoryAdd = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORIES_ADD,
  MonthEndCategory,
  { organisationId: number; categoryId: string; taskInfo: string }
>;

export type MonthEndCategoriesReorder = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORIES_REORDER,
  MonthEndCategoryResponse,
  { organisationId: number; monthEndCategoriesIds: string[] }
>;

export type MonthEndCategoryTasksReorder = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORY_TASKS_REORDER,
  MonthEndCategoryResponse,
  { organisationId: number; categoryId: string; taskIds: string[] }
>;

export type MonthEndCategoriesAddTask = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORIES_ADD_TASK,
  MonthEndTask,
  { organisationId: number; taskInfo: CreateMonthEndTaskPayload }
>;

export type MonthEndCategoriesDeleteTask = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORIES_DELETE_TASK,
  MonthEndTask,
  { organisationId: number; categoryId: string; taskId: string }
>;

export type MonthEndCategoriesUpdateTask = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORIES_UPDATE_TASK,
  MonthEndTask,
  { organisationId: number; taskInfo: MonthEndTask; isSubTask?: boolean }
>;

export type MonthEndCategoryRename = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORY_RENAME,
  MonthEndCategory,
  { organisationId: number; categoryId: string; categoryName: string }
>;
export type MonthEndCategoryDelete = PromiseAction<
  typeof ORGANISATIONS_MONTH_END_CATEGORY_DELETE,
  MonthEndCategory,
  { organisationId: number; categoryId: string }
>;

export type MonthEndCategoriesActions =
  | MonthEndCategoriesLoad
  | MonthEndCategoryAdd
  | MonthEndCategoriesAddTask
  | MonthEndCategoriesDeleteTask
  | MonthEndCategoryRename
  | MonthEndCategoriesUpdateTask
  | MonthEndCategoryDelete
  | MonthEndCategoryTasksReorder;

type MonthEndCategoryResponse = { results: MonthEndCategory[] };

export const loadMonthEndCategoriesAction = (
  organisationId: number,
  month: string,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  const state = getState();
  const MonthEndCategories = selectMonthEndCategories(state, organisationId);

  if (MonthEndCategories?.status === 'pending' || !month) {
    return;
  }

  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORIES_LOAD,
        () =>
          http<MonthEndCategory[], MonthEndCategoryResponse>(
            `/api/v2/monthend/${organisationId}/month/${month}/categories/`,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const addNewMonthEndCategory = (
  organisationId: number,
  categoryName: string,
  displayOrder: number,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORIES_ADD,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/category/`,
            {
              method: 'POST',
              body: {
                name: categoryName,
                display_order: displayOrder,
              },
            },
            res => res,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const reorderMonthEndCategories = (
  organisationId: number,
  monthEndCategoriesIds: string[],
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORIES_REORDER,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/order/categories/`,
            {
              method: 'PUT',
              body: monthEndCategoriesIds,
            },
            res => res,
          ),
        {
          organisationId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const renameMonthEndCategory = (
  organisationId: number,
  categoryId: string,
  categoryName: string,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORY_RENAME,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/category/${categoryId}/`,
            {
              method: 'PATCH',
              body: {
                name: categoryName,
              },
            },
            res => res,
          ),
        {
          organisationId,
          categoryId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
export const deleteMonthEndCategory = (
  organisationId: number,
  categoryId: string,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORY_DELETE,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/category/${categoryId}/`,
            {
              method: 'DELETE',
            },
            res => res,
          ),
        {
          organisationId,
          categoryId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const addNewTaskToMonthEndCategory = (
  organisationId: number,
  taskInfo: CreateMonthEndTaskPayload,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    const response = await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORIES_ADD_TASK,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/task/`,
            {
              method: 'POST',
              body: {
                ...taskInfo,
              },
            },
            res => res,
          ),
        {
          organisationId,
        },
      ),
    );
    return response;
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const deleteTaskFromMonthEndCategory = (
  organisationId: number,
  categoryId: string,
  taskId: string,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORIES_DELETE_TASK,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/task/${taskId}/`,
            {
              method: 'DELETE',
            },
            res => res,
          ),
        {
          organisationId,
          categoryId,
          taskId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const updateTaskFromMonthEndCategory = (
  organisationId: number,
  taskInfo: MonthEndTask,
  month: string,
  isSubTask?: boolean,
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORIES_UPDATE_TASK,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/month/${month}/task/${taskInfo.id}/`,
            {
              method: 'PATCH',
              body: {
                ...taskInfo,
              },
            },
            res => res,
          ),
        {
          organisationId,
          categoryId: taskInfo.category,
          taskId: taskInfo.id,
          isSubTask,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};

export const reorderMonthEndCategoryTasks = (
  organisationId: number,
  categoryId: string,
  taskIds: string[],
): ThunkAction<MonthEndCategoriesActions> => async (dispatch: ThunkDispatch<MonthEndCategoriesActions>, getState) => {
  try {
    await dispatch(
      promiseAction(
        ORGANISATIONS_MONTH_END_CATEGORY_TASKS_REORDER,
        () =>
          http(
            `/api/v2/monthend/${organisationId}/category/${categoryId}/order/tasks/`,
            {
              method: 'PUT',
              body: taskIds,
            },
            res => res,
          ),
        {
          organisationId,
          categoryId,
        },
      ),
    );
  } catch (e) {
    console.log('Error dispatching action', e);
  }
};
