import produce from 'immer';
import {
  ACTION_DASHBOARDS_CREATE,
  ACTION_DASHBOARDS_CREATE_CHART,
  ACTION_DASHBOARDS_DELETE,
  ACTION_DASHBOARDS_DELETE_CHART,
  ACTION_DASHBOARDS_LOAD,
  ACTION_DASHBOARDS_UPDATE,
  ACTION_DASHBOARDS_UPDATE_CHART,
  ACTION_DASHBOARDS_UPDATE_DETAILS,
} from 'scalexp/store/state/newDashboards/constants';

import { DashboardChartCard, Dashboards, DashboardsActions, OrganisationDashboards } from './types';

const dashboardsList = (prevState: OrganisationDashboards = {}, action: DashboardsActions): OrganisationDashboards => {
  return produce(prevState, state => {
    switch (action.type) {
      case ACTION_DASHBOARDS_LOAD: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: action.payload.reduce((acc, dashboard) => {
                acc[dashboard.id] = {
                  ...dashboard,
                  charts: dashboard.charts.map(chart => ({ ...chart, i: chart.id, w: chart.width, h: chart.height })),
                };
                return acc;
              }, {} as Dashboards),
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ACTION_DASHBOARDS_CREATE:
      case ACTION_DASHBOARDS_UPDATE: {
        const { organisationId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            state[organisationId] = {
              status: 'success',
              error: undefined,
              value: {
                ...(state[organisationId].value || {}),
                [action.payload.id]: {
                  ...action.payload,
                  charts: action.payload.charts.map(chart => ({
                    ...chart,
                    i: chart.id,
                    w: chart.width,
                    h: chart.height,
                  })),
                },
              },
            };
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }
      case ACTION_DASHBOARDS_UPDATE_DETAILS: {
        const { organisationId, dashboardId } = action.params;
        switch (action.status) {
          case 'success':
            const dashboards = state[organisationId].value;
            const dashboard = dashboards?.[dashboardId];
            if (dashboard) {
              dashboard.name = action.payload.name;
              dashboard.pinned = action.payload.pinned;
              dashboard.currency = action.payload.currency;
            }
            return;
          default:
            return state;
        }
      }
      case ACTION_DASHBOARDS_DELETE: {
        const { organisationId, dashboardId } = action.params;
        switch (action.status) {
          case 'pending':
            state[organisationId] = {
              status: 'pending',
              error: undefined,
              value: state[organisationId]?.value,
            };
            return;

          case 'success':
            delete state[organisationId].value?.[dashboardId];
            state[organisationId].status = 'success';
            state[organisationId].error = undefined;
            return;

          case 'error':
            state[organisationId] = {
              status: 'error',
              error: action.error,
              value: undefined,
            };
            return;

          default:
            return state;
        }
      }

      case ACTION_DASHBOARDS_CREATE_CHART: {
        const { organisationId, dashboardId } = action.params;
        switch (action.status) {
          case 'pending': {
            return state;
          }
          case 'success': {
            const dashboard = state[organisationId].value?.[dashboardId];
            if (dashboard) {
              dashboard.charts.unshift({
                ...action.payload,
                w: action.payload.width,
                h: action.payload.height,
                i: action.payload.id,
              } as DashboardChartCard);
            }
            return state;
          }
          default:
            return state;
        }
      }
      case ACTION_DASHBOARDS_UPDATE_CHART: {
        const { organisationId, dashboardId, chartId, chart } = action.params;
        switch (action.status) {
          case 'pending': {
            const dashboard = state[organisationId].value?.[dashboardId];
            if (dashboard) {
              let chartIndex = dashboard.charts.findIndex(c => c.i === chartId);
              if (chartIndex !== -1) {
                state[organisationId]!.value![dashboardId].charts[chartIndex] = {
                  ...state[organisationId]!.value![dashboardId].charts[chartIndex],
                  ...chart,
                  w: chart.width,
                  h: chart.height,
                  i: chart.id,
                };
              }
            }
            return state;
          }
          case 'success': {
            return state;
          }
          default:
            return state;
        }
      }
      case ACTION_DASHBOARDS_DELETE_CHART: {
        const { organisationId, dashboardId, chartId } = action.params;
        switch (action.status) {
          case 'pending': {
            const dashboard = state[organisationId].value?.[dashboardId];
            if (dashboard) {
              dashboard.charts = dashboard.charts.filter(chart => {
                return chart.i !== chartId;
              });
            }
            return state;
          }
          default:
            return state;
        }
      }
      default:
        return state;
    }
  });
};

export default dashboardsList;
